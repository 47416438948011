<template>
    <div class="h-100 w-100">
        <particles
            :color="theme === 'white' ? '#000' : '#dedede'"
            :lines-color="theme === 'white' ? '#000' : '#dedede'"
            :particle-opacity="0.7"
            :particles-number="80"
            shape-type="circle"
            :particle-size="4"
            :lines-width="1"
            :line-linked="true"
            :line-opacity="0.4"
            :lines-distance="150"
            :move-speed="3"
            :hover-effect="true"
            hover-mode="grab"
            :click-effect="true"
            click-mode="push"
            class="particles"
        ></particles>

        <div
            class="position-absolute w-100 h-100 d-flex justify-content-center"
        >
            <div
                v-if="
                    $store.state.cupboardsGameTaxonomy.step === STEPS.CONCLUSION
                "
                class="d-flex w-75 m-0 flex-wrap animation justify-content-center align-content-center"
            >
                <div class="text">
                    {{ $t(`cupboards_taxonomy_outro`) }}
                </div>
            </div>
            <div
                v-if="$store.state.cupboardsGameTaxonomy.step === STEPS.SAVED"
                class="d-flex w-75 m-0 flex-wrap animation justify-content-center align-content-center"
            >
                <div
                    v-for="(letter, i) in splitNameIntoWordArray"
                    :key="'letter_' + i"
                    class="d-inline display-1"
                    :class="['letter-' + i, letter === '-' ? '' : 'mx-3']"
                >
                    {{ letter }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Particles from '../idle/Particles.vue'
import { stationsData } from '@/stationsData'
import { STEPS } from '@/store/stations/3.001_cupboards/gameTaxonomy'

const cupboardsStationData = stationsData.find(
    (item) => item.key === 'cupboards',
)
export default {
    name: 'CupboardsGameTaxonomySuccess',
    components: { Particles },
    data() {
        return {
            theme: cupboardsStationData.theme,
            STEPS,
        }
    },
    computed: {
        splitNameIntoCharArray() {
            return this.$store.state.cupboardsGameTaxonomy.name.split('')
        },
        splitNameIntoWordArray() {
            let words = this.$store.state.cupboardsGameTaxonomy.name
                .split('-')
                .join(' - ')
                .split(' ')

            return words
        },
    },
}
</script>

<style scoped lang="scss">
@function randomNum($min, $max) {
    $rand: random();
    $randomNum: $min + floor($rand * (($max - $min) + 1));

    @return $randomNum;
}

@for $i from 0 through 90 {
    .letter-#{$i} {
        animation: randomLetterMovement-#{$i} ease-in-out 20s;
    }
    @keyframes randomLetterMovement-#{$i} {
        0% {
            transform: translate(
                randomNum(-4000, 4000) + px,
                randomNum(-4000, 4000) + px
            );
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        25% {
            transform: translate(0, 0);
        }
        50% {
            transform: translate(0, 0);
        }
        75% {
            opacity: 1;
        }
        100% {
            transform: translate(
                randomNum(-4000, 4000) + px,
                randomNum(-4000, 4000) + px
            );
            opacity: 0;
        }
    }
}

.particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.text {
    font-size: 48px;
}
</style>
