<template>
    <div id="knowledgeNetwork" class="vh-100" @click="stopRotation">
        <!--        <knowledge-networks-idle-->
        <!--            v-if="!$store.getters.isAUserLoggedIn"-->
        <!--        ></knowledge-networks-idle>-->

        <div class="vh-100 position-relative">
            <!--            {{collectedItems}}-->
            <div
                v-for="i in usersCounter"
                :key="'user-login_' + i"
                :class="['knowledge-networks-user' + i]"
                class="user absolute top-0"
            >
                <UserLogin
                    v-if="!connectedUsers.includes('knowledgeNetworks_' + i)"
                    :id="i"
                    :connected-users="connectedUsers"
                    @without-mobile="setPlayingNow"
                ></UserLogin>
            </div>
            <div v-if="$store.getters.isAUserLoggedIn">
                <template v-for="i in usersCounter">
                    <User
                        v-if="connectedUsers.includes('knowledgeNetworks_' + i)"
                        :id="i"
                        :key="'user_' + i"
                        :class="'vh-50 knowledge-networks-user' + i"
                        :active-users="activeUsers"
                        :connected-users="connectedUsers"
                        :playing-user="playingNow[i]"
                        @activate-user="activateUser"
                        @location="setLocation"
                        @select-item="setItem"
                        @close-info="closeInfo"
                    ></User>
                </template>
            </div>
            <Map
                ref="map"
                :class="{ idlemode: !$store.getters.isAUserLoggedIn }"
                :active-users="activeUsers"
                :locations="locations"
            ></Map>
        </div>
    </div>
</template>

<script>
// import StationsTitle from '@/components/StationsTitle'
// import Map from '@/components/stations/2.002_knowledgeNetworks/Map'
import User from '@/components/stations/2.002_knowledgeNetworks/User'
import UserLogin from '@/components/stations/2.002_knowledgeNetworks/UserLogin'
import Map from '@/components/stations/2.002_knowledgeNetworks/MapRoutesClear'
import KnowledgeNetworksIdle from './KnowledgeNetworksIdle.vue'
import Vue from 'vue'

export default {
    name: 'KnowledgeNetworks',
    components: {
        // StationsTitle,
        Map,
        User,
        UserLogin,
        KnowledgeNetworksIdle,
    },
    data() {
        return {
            activeUsers: [],
            locations: [],
            connectedUsers: [],
            // userData: [],
            usersCounter: 6,
            playingNow: [],
        }
    },
    watch: {
        '$store.state.users': {
            handler(users) {
                this.connectedUsers = Object.keys(users)
                console.log(this.connectedUsers)
                if (!this.connectedUsers.length) {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                }
                // this.$refs.map.stopRotation()
            },
            deep: true,
        },
    },

    async mounted() {
        await this.$store.dispatch('knowledgeNetworks/resetState')

        this.connectedUsers = await Object.keys(this.$store.state.users)

        // console.log(this.connectedUsers)
        // this.usersCounter = this.connectedUsers.length
        // this.userData = this.collectedItems(this.connectedUsers[0])
    },
    methods: {
        closeInfo(id) {
            this.$refs.map.removeLayer(id)
            this.$refs.map.clusterMarkers.refreshClusters()
        },
        stopRotation() {
            // this.$refs.map.stopRotation()
        },
        collectedItems(userIndex) {
            const items = this.$store.getters
                .getUserAtUnit(userIndex)
                ?.collection.filter((x) => x.nfcItem)
            return items
        },

        setItem(locationData) {
            // console.log(locationData)
            this.$refs.map.setItemStartPosition(locationData)
        },
        setLocation(locationData) {
            // this.$refs.map.goToNextLocation(locationData)
            if (locationData.direction === 'next') {
                // this.$refs['map'].goToNextLocation()
                this.$refs.map.goToNextLocation(locationData)
            } else {
                // this.$refs['map'].goToPrevLocation()
                this.$refs.map.goToPrevLocation(locationData)
            }
        },
        setLocation1(location) {
            let updateElement = this.locations.find(
                (x) => x.user === location.user,
            )
            if (updateElement) {
                let updateIndex = this.locations.indexOf(updateElement)

                Vue.set(this.locations, updateIndex, location)
            } else {
                this.locations.push(location)
            }
            // console.log(this.locations)
        },
        activateUser(index) {
            if (this.activeUsers.indexOf(index) === -1) {
                this.activeUsers.push(index)
            } else {
                // let id = this.activeUsers.indexOf(index)
                // this.activeUsers.splice(id, 1)
            }
        },
        setPlayingNow(id) {
            this.playingNow[id] = true

            console.log(this.connectedUsers)
        },
    },
}
</script>

<style lang="scss">
#knowledgeNetwork {
    height: 100vh !important;

    .vh-100 {
        height: 100vh !important;
    }
}

.vh-100 {
    height: 100vh !important;
}

.knowledge-networks-user0,
.knowledge-networks-user6 {
    top: 1vh;
    left: 0;

    &.user {
        align-items: flex-start;
    }

    .carousel-cell {
        //transform: rotate(180deg);
    }
}

.knowledge-networks-user5 {
    top: 1vh;
    left: 50%;
    transform: translate(-50%);

    &.user {
        align-items: flex-start;
    }

    .carousel-cell {
        //transform: rotate(180deg);
    }
}

.knowledge-networks-user4 {
    top: 1vh;
    right: 0;

    &.user {
        align-items: flex-start;
    }

    .carousel-cell {
        //transform: rotate(180deg);
    }
}

.knowledge-networks-user3 {
    bottom: 1vh;
    left: 0;
}

.knowledge-networks-user2 {
    bottom: 1vh;
    left: 50%;
    transform: translate(-50%);
}

.knowledge-networks-user1 {
    bottom: 1vh;
    right: 0;
}

.leaflet-tile-container img {
    filter: grayscale(100%);
}

[data-resolution='3x4k'] {
    .vh-100 {
        height: 100vh;
    }
}
</style>
