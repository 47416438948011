<template>
    <div class="simulation h-100 w-100">
        <div ref="video" class="video-container" />
        <div>
            <div class="control-buttons position-absolute d-flex flex-column">
                <div
                    v-for="btnRow in btnArray"
                    :key="'btn_row_' + btnRow"
                    class="d-flex"
                >
                    <div
                        v-for="(btn, index) in btnRow"
                        :key="'btn_row_' + btnRow + index"
                    >
                        <div
                            v-if="getThumbImage(btn)"
                            :class="btn === nowActive ? 'active' : ''"
                            class="fowi-btn fowi-btn-primary fowi-btn-fixed-size m-1"
                            @click="setVideo(btn)"
                        >
                            <img :src="getThumbImage(btn)" />
                        </div>
                        <div v-else class="blank-position m-1"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let w, h, ratio

export default {
    name: 'Simulation',
    data() {
        return {
            isReady: false,
            videoContainer: null,
            play: true,
            nowActive: null,
            btnArray: [
                ['', 'back90ccw', 'front90ccw', ''],
                ['top', 'back', 'front', 'bottom'],
                ['', 'back90cw', 'front90cw', ''],
            ],
        }
    },
    computed: {
        getContent() {
            return this.$store.getters.getContent?.filter(
                (x) => x.templateId === 32,
            )
        },
        getVideos() {
            return this.$store.getters.getContent?.filter(
                (x) => x.parentId === this.getStructure.id,
            )
        },
        getStructure() {
            return this.getContent.find(
                (x) =>
                    x.content?.object_pi?.global ===
                    this.$store.getters['atelier/getItem'].nfcItem.structureId,
            )
        },
    },
    mounted() {
        this.videoContainer = this.$refs.video
        this.switchViewTo(this.getVideos[0])
    },
    methods: {
        getThumbImage(btn) {
            const video = this.getVideos.find(
                (video) => video.content.positions.global === btn,
            )
            return video?.content?.image?.global
                ? video.content.image.global.imageAsset.urls.thumb.url
                : null
        },
        setVideo(btn) {
            const video = this.getVideos.find(
                (video) => video.content.positions.global === btn,
            )
            if (video) {
                this.switchViewTo(video)
            }
        },
        switchViewTo(video) {
            if (video.content.positions.global === this.nowActive) return
            this.nowActive = video.content.positions.global
            const url = video.content.video.global.videoAsset.urls.full_hd.url
            const v = document.createElement('video')
            v.src = url
            v.setAttribute('loop', 'loop')
            v.setAttribute('type', 'video/mp4')
            v.classList.add('w-100')
            v.classList.add('h-100')
            v.oncanplay = () => {
                if (!this.videoContainer.children[0]) {
                    this.videoContainer.appendChild(v)
                } else {
                    this.videoContainer.children[0].replaceWith(v)
                }
                this.videoContainer.children[0].play()
            }
        },
    },
}
</script>

<style scoped lang="scss">
.simulation {
    overflow: hidden;

    .control-buttons {
        z-index: 100;
        left: calc(50vw * var(--pxToVw));
        bottom: calc(25vw * var(--pxToVw));

        img {
            transition: opacity 0.5s ease-in-out;
            height: 100%;
            opacity: 1;
        }

        .active {
            img {
                opacity: 0.25;
            }
        }

        .blank-position {
            width: calc(90vw * var(--pxToVw));
            height: calc(90vw * var(--pxToVw));
        }
    }
}
</style>

<style lang="scss">
.video-container {
    top: 0;

    video {
        object-fit: fill;
    }
}
</style>
