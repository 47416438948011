import { getDistance } from './math'

const isTouchEvent = (e) => e.touches != null
const findTouch = (element, { changedTouches }) => {
    const touches = changedTouches
    const rect = element.getBoundingClientRect()
    const distances = []
    for (var i = 0; i < touches.length; i++) {
        distances.push(
            getDistance(
                { x: rect.x + rect.width / 2, y: rect.y + rect.y / 2 },
                { x: touches[i].clientX, y: touches[i].clientY },
            ),
        )
        if (
            rect.x < touches[i].clientX &&
            touches[i].clientX < rect.x + rect.width &&
            rect.y < touches[i].clientY &&
            touches[i].clientY < rect.y + rect.height
        ) {
            return touches[i]
        }
        const min = Math.min(...distances)
        const index = distances.indexOf(min)
        return touches[index]
    }
}

const findTouchId = (element, { touches }) => {
    let touchId = null
    const rect = element.getBoundingClientRect()
    Object.keys(touches).forEach((key) => {
        if (
            rect.x < touches[key].clientX &&
            touches[key].clientX < rect.x + rect.width &&
            rect.y < touches[key].clientY &&
            touches[key].clientY < rect.y + rect.height
        ) {
            touchId = touches[key].identifier
        }
    })
    return touchId
}

const getWrapperElementByClass = (className, { changedTouches, path }) => {
    let value = null
    console.log(changedTouches, path)
    if (changedTouches) {
        path?.forEach((element) => {
            const classes = element.classList?.value?.split(' ')
            if (classes?.includes(className)) {
                value = element
            }
        })
    }
    return value
}

const getDistanceFromCenter = (rect, touch) => {
    return Math.sqrt(
        Math.pow(rect.x + rect.width / 2 - touch.clientX, 2) +
            Math.pow(rect.y + rect.height / 2 - touch.clientY, 2),
    )
}
const getDistanceY = (rect, touch) => {
    if (touch.clientY < rect.y) {
        return rect.y - touch.clientY
    }
    if (touch.clientY > rect.y + rect.height) {
        return touch.clientY - rect.y - rect.height
    }
    return 0
}
const isInRect = (rect, touch) => {
    return (
        rect.x <= touch.clientX &&
        touch.clientX <= rect.x + rect.width &&
        rect.y <= touch.clientY &&
        touch.clientY <= rect.y + rect.height
    )
}

export {
    findTouch,
    findTouchId,
    getDistanceFromCenter,
    getDistanceY,
    getWrapperElementByClass,
    isTouchEvent,
    isInRect,
}
