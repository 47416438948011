var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"cupboards",staticClass:"h-100 w-100 game overflow-hidden"},[_c('login-timer',{attrs:{"unit":_vm.$route.name,"user-uuid":_vm.$store.getters.getUserAtUnit(_vm.$route.name).uuid}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.cupboards.game === _vm.GAMES.NONE),expression:"$store.state.cupboards.game === GAMES.NONE"}],staticClass:"h-100 w-100"},[_c('h1',{staticClass:"station-title"},[_vm._v(" "+_vm._s(_vm.$t('cupboards_station_title'))+" ")]),(_vm.randomUser)?_c('div',{staticClass:"logout-btn m-2",on:{"click":function($event){return _vm.logoutFromUnit('cupboards')}}},[_c('i',{staticClass:"fa fa-sign-out"})]):_vm._e(),_c('fowi-drag-and-drop-zone',{attrs:{"items":_vm.$store.getters.getUserAtUnit(_vm.$route.name).collection,"zone-one-items":_vm.$store.getters['cupboards/getTaxonomyItemPIs'],"zone-two-items":_vm.getHaystackItemsPIs().filter((PI) =>
                    _vm.$store.getters
                        .getUserAtUnit(_vm.$route.name)
                        .collection.find(
                            (collectedItem) => collectedItem.id === PI,
                        ),
                )},on:{"dropZone":_vm.onGameSelect}},[_c('div',{attrs:{"slot":"dropZone1"},slot:"dropZone1"},[_c('h2',[_vm._v(_vm._s(_vm.$t('cupboards_taxonomy_title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('cupboards_taxonomy_description')))])]),_c('div',{attrs:{"slot":"dropZone2"},slot:"dropZone2"},[_c('h2',[_vm._v(_vm._s(_vm.$t('cupboards_haystack_title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('cupboards_haystack_description')))])])]),(_vm.$store.state.cupboards.game === _vm.GAMES.NONE)?_c('p',{staticClass:"instructions"},[_vm._v(" "+_vm._s(_vm.$t('cupboards_instructions'))+" ")]):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.cupboards.game !== _vm.GAMES.NONE),expression:"$store.state.cupboards.game !== GAMES.NONE"}],staticClass:"fill-parent"},[(_vm.$store.state.cupboards.game === _vm.GAMES.GENERATOR)?_c('cupboards-game-generator'):_vm._e(),(_vm.$store.state.cupboards.game === _vm.GAMES.HAYSTACK)?_c('cupboards-game-haystack'):_vm._e(),(_vm.$store.state.cupboards.game === _vm.GAMES.TAXONOMY)?_c('cupboards-game-taxonomy'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }