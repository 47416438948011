var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 w-100"},[_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[_c('p',{key:_vm.getFeedback,staticClass:"station-feedback",domProps:{"innerHTML":_vm._s(_vm.$t(_vm.getFeedback))}})]),_c('div',{staticClass:"micro-wrapper"},[_c('div',{ref:"neededCircleWidthHeight",staticClass:"overlay-grid",style:({
                width:
                    _vm.showAnimation || _vm.showStates
                        ? _vm.imageSizes.width + 'px'
                        : '40vw',
                height:
                    _vm.showAnimation || _vm.showStates
                        ? _vm.imageSizes.height + 'px'
                        : '40vw',
            })},[_c('div',{ref:"actualImageToCalc",staticClass:"actual-image",style:({
                    opacity: 0,
                    zIndex: -1,
                    width:
                        _vm.showAnimation || _vm.showStates
                            ? _vm.imageSizes.width + 'px'
                            : '40vw',
                    height:
                        _vm.showAnimation || _vm.showStates
                            ? _vm.imageSizes.height + 'px'
                            : '40vw',
                })},[_c('svg',{attrs:{"width":_vm.imageSizes.width * _vm.zoomFactor + 'px',"height":_vm.imageSizes.height * _vm.zoomFactor + 'px'}})]),_c('div',{ref:"actualImage",staticClass:"actual-image",style:({
                    zIndex: 10,
                    width:
                        _vm.showAnimation || _vm.showStates
                            ? _vm.imageSizes.width + 'px'
                            : '40vw',
                    height:
                        _vm.showAnimation || _vm.showStates
                            ? _vm.imageSizes.height + 'px'
                            : '40vw',
                })},[_c('svg',{ref:"imageRef",attrs:{"width":_vm.imageSizes.width * _vm.zoomFactor + 'px',"height":_vm.imageSizes.height * _vm.zoomFactor + 'px'},on:{"touchmove":_vm.touchMove}},[_c('rect',{attrs:{"x":"0","y":"0","width":"100%","height":"100%","fill":"#ffffff","fill-opacity":"1"}}),_c('image',{ref:"imageInSvg",attrs:{"x":"0","y":"0","height":"100%","width":"100%","mask":_vm.showStates && 'url(#savedCircles)',"xlink:href":_vm.imageUrl,"opacity":"1"}}),_c('mask',{attrs:{"id":"savedCircles","width":"100%","height":"100%","x":"0","y":"0"}},[_c('rect',{ref:"animateWhiteOverlayOut",attrs:{"width":"100%","height":"100%","x":"0","y":"0","fill":"#fff","fill-opacity":"1"}}),_vm._l((_vm.savedZoomState),function(circle,index){return _c('circle',{key:`circle_${index}`,attrs:{"cx":circle.pos.x * _vm.imageSizes.width,"cy":circle.pos.y * _vm.imageSizes.height,"r":circle.size * 500,"stroke-width":"300","opacity":"1","fill":"#fff"}})})],2),(
                            _vm.savedZoomState && (_vm.showStates || _vm.showAnimation)
                        )?_c('circle',{ref:"lastCircle",attrs:{"transform":`translate(${
                            _vm.savedZoomState[_vm.savedZoomState.length - 1].pos
                                .x *
                            _vm.imageSizes.width *
                            _vm.zoomFactor
                        } ${
                            _vm.savedZoomState[_vm.savedZoomState.length - 1].pos
                                .y *
                            _vm.imageSizes.height *
                            _vm.zoomFactor
                        })`,"cx":"0","cy":"0","r":_vm.savedZoomState[_vm.savedZoomState.length - 1].size *
                            500 *
                            _vm.zoomFactor,"fill":"0","fill-opacity":"0","stroke-width":"5","stroke":"yellow"}},[_c('animateTransform',{ref:"startCircleAnimation",attrs:{"attributeName":"transform","attributeType":"XML","type":"translate","from":`${
                                _vm.savedZoomState[_vm.savedZoomState.length - 1]
                                    .pos.x *
                                _vm.imageSizes.width *
                                _vm.zoomFactor
                            } ${
                                _vm.savedZoomState[_vm.savedZoomState.length - 1]
                                    .pos.y *
                                _vm.imageSizes.height *
                                _vm.zoomFactor
                            }`,"to":`${
                                _vm.savedZoomState[_vm.savedZoomState.length - 1]
                                    .pos.x * _vm.imageSizes.width
                            } ${
                                _vm.savedZoomState[_vm.savedZoomState.length - 1]
                                    .pos.y * _vm.imageSizes.height
                            }`,"dur":"1.5s","fill":"freeze","begin":"indefinitely"}})],1):_vm._e()])])]),(!_vm.showStates)?_c('div',{staticClass:"slider-save"},[_c('div',{staticStyle:{"width":"100%"}},[_c('slider',{attrs:{"min":_vm.startZoomFactor,"max":_vm.maxZoomFactor,"step":0.01,"show-dragged-amount":"","hide-label":""},model:{value:(_vm.zoomFactor),callback:function ($$v) {_vm.zoomFactor=_vm._n($$v)},expression:"zoomFactor"}})],1),_c('div',{staticClass:"action-buttons-container mx-1 d-flex"},[(!_vm.showStates)?_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48",class:!_vm.canBeSaved ? 'disabled' : '',on:{"click":_vm.save}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'cloud-upload'],"size":"lg"}})],1):_vm._e()])]):_c('div',{staticClass:"balance-height-on-show-results"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }