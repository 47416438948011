<template>
    <div ref="container" class="gameTaxonomy fill-parent">
        <cupboards-game-header
            :game-title="$t('cupboards_taxonomy_title')"
        ></cupboards-game-header>
        <div class="content">
            <success-animation
                v-if="
                    $store.state.cupboardsGameTaxonomy.step ===
                        STEPS.CONCLUSION ||
                    $store.state.cupboardsGameTaxonomy.step === STEPS.SAVED
                "
                ref="container"
                class="fill-parent"
            ></success-animation>
            <texts v-else></texts>
            <template
                v-if="$store.state.cupboardsGameTaxonomy.step === STEPS.SELECT"
            >
                <div class="slots">
                    <img src="@/assets/export/case-leer.png" alt="" />
                    <div
                        v-for="(item, index) in $store.state
                            .cupboardsGameTaxonomy.selectedItems"
                        :key="'selected_item_slot_' + index"
                        :ref="`selectedItemSlot_${index}`"
                        :class="`selectedItemSlot selectedItemSlot_${index}`"
                    >
                        <div class="handle"></div>
                        <!-- {{ index }} -->
                    </div>
                </div>

                <div
                    class="items"
                    @touchmove="dragUpdate"
                    @touchend="dragEnd"
                    @mouseleave="dragEnd"
                >
                    <div
                        v-for="(item, index) in $store.state
                            .cupboardsGameTaxonomy.items"
                        :id="item.goettItem.PI"
                        :key="item.goettItem.PI + index"
                        :style="getItemStyle(item)"
                        class="item rounded-circle"
                        @touchstart.prevent.stop="dragStart(item, $event)"
                    >
                        <img
                            class="pointer-events-none"
                            :src="
                                api.goettingen.getThumbnailUrl(item.goettItem)
                            "
                            width="100px"
                            height="100px"
                        />
                    </div>
                </div>
                <div
                    class="name d-flex justify-content-center align-items-center"
                >
                    <div>
                        <p class="m-0 mb-1">
                            {{ $t('cupboards_taxonomy_name_label') }}
                        </p>
                        <h3 class="value m-0">
                            {{
                                $store.state.cupboardsGameTaxonomy.name !== ''
                                    ? $store.state.cupboardsGameTaxonomy.name
                                    : $t('cupboards_taxonomy_defaultname')
                            }}
                        </h3>
                    </div>
                    <div
                        class="ml-3 fowi-btn fowi-btn-primary fowi-btn-sq48"
                        :class="{ disabled: !isEnabled() }"
                        @click="saveCollection"
                    >
                        <font-awesome-icon
                            :icon="['far', 'cloud-upload']"
                            size="lg"
                        />
                    </div>
                </div>
            </template>
        </div>
        <template v-if="debug">
            <div class="possible-positions pointer-events-none" />
            <div
                v-for="i in 3"
                :key="'excluded_' + i"
                class="exclude pointer-events-none"
                :class="'exclude-' + i"
            />
        </template>
        <cupboards-game-footer
            v-if="$store.state.cupboardsGameTaxonomy.step === STEPS.SELECT"
        ></cupboards-game-footer>
    </div>
</template>
<script>
import api from '@/mixins/api'
import { getRandomPositions } from '@/utils/math'
import { isInRect } from '@/utils/touch'
import { isEqual } from '@/utils/items'
import { stationsData } from '@/stationsData'
import SuccessAnimation from './SuccessAnimation.vue'
import { STEPS } from '@/store/stations/3.001_cupboards/gameTaxonomy'
import CupboardsGameHeader from '../CupboardsGameHeader.vue'
import CupboardsGameFooter from '../CupboardsGameFooter.vue'
import Texts from './Texts.vue'
import { GAMES } from '@/store/stations/3.001_cupboards/cupboards'

const stationData = stationsData.find((station) => station.key === 'cupboards')

export default {
    name: 'CupboardsGameTaxonomy',
    components: {
        Texts,
        SuccessAnimation,
        CupboardsGameHeader,
        CupboardsGameFooter,
    },
    data() {
        return {
            UNIT: 'cupboards',
            api,
            draggedItem: null,
            draggedItems: {},
            positions: {},
            transitions: {},
            STEPS,
            debug: false,
        }
    },
    computed: {
        currentUser() {
            return this.$store.getters.getUserAtUnit(this.$route.name)
        },
    },
    async mounted() {
        const positions = {}
        const items = this.$store.state.cupboardsGameTaxonomy.items

        const randomPositions = getRandomPositions(
            100,
            stationData.width - 100,
            100,
            stationData.height - 100,
            75,
            items.length,
            [
                // buttons
                { x: 1750, y: 900, width: 70, height: 80 },
                // texts
                { x: 0, y: 0, width: 800, height: 500 },
                // shelf
                { x: 385, y: 420, width: 1100, height: 520 },
            ],
        )
        items.forEach((item, i) => {
            positions[item.goettItem.PI] = randomPositions[i]
        })

        this.positions = positions
        // start animation of selected item
        setTimeout(() => {
            const rect =
                this.$refs.selectedItemSlot_3[0].getBoundingClientRect()
            if (this.$store.state.cupboardsGameTaxonomy.item) {
                this.animateItemToPosition(
                    this.$store.state.cupboardsGameTaxonomy.item,
                    { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 },
                    1,
                    () => {
                        this.$store.dispatch(
                            'cupboardsGameTaxonomy/setSelectedItem',
                            {
                                item: {
                                    ...this.$store.state.cupboardsGameTaxonomy
                                        .item,
                                },
                                index: 3,
                            },
                        )
                    },
                )
            }
        }, 500)
    },
    methods: {
        dragStart(item, event) {
            const touches = event.changedTouches
            for (let i = 0; i < touches.length; i++) {
                if (touches[i].target.id) {
                    const rect = touches[i].target.getBoundingClientRect()
                    this.draggedItems['id_' + touches[i].identifier] = {
                        item: item,
                        PI: touches[i].target.id,
                        dragOffsetX:
                            touches[i].clientX - (rect.x + rect.width / 2),
                        dragOffsetY:
                            touches[i].clientY - (rect.y + rect.height / 2),
                        startX: rect.x,
                        startY: rect.y,
                    }
                }
            }
        },
        dragUpdate(event) {
            try {
                const touches = event.changedTouches
                for (let i = 0; i < touches.length; i++) {
                    const tp = this.draggedItems['id_' + touches[i].identifier]
                    if (tp) {
                        const position = {
                            x: touches[i].clientX - tp.dragOffsetX,
                            y: touches[i].clientY - tp.dragOffsetY,
                        }
                        const positions = { ...this.positions }
                        positions[tp.PI] = position
                        this.positions = positions
                    }
                }
            } catch (e) {
                console.log('')
            }
        },
        dragEnd(event) {
            const touches = event.changedTouches
            const positions = { ...this.positions }
            for (let i = 0; i < touches.length; i++) {
                const tp = this.draggedItems['id_' + touches[i].identifier]
                if (tp) {
                    console.log(tp)
                    let isInAnyRect = false
                    let oldItem = null
                    Object.entries(this.$refs).forEach((entry) => {
                        const key = entry[0]
                        const value = entry[1]
                        const slot = value[0]
                        if (key === 'container') return
                        const rect = slot.getBoundingClientRect()
                        if (isInRect(rect, touches[i])) {
                            isInAnyRect = true
                            const index = key.split('_').pop()
                            oldItem =
                                this.$store.state.cupboardsGameTaxonomy
                                    .selectedItems[index]
                            this.$store.dispatch(
                                'cupboardsGameTaxonomy/setSelectedItem',
                                {
                                    item: tp.item,
                                    index,
                                },
                            )
                        }
                    })
                    // TODO: only fire if is selected
                    if (!isInAnyRect) {
                        this.$store.dispatch(
                            'cupboardsGameTaxonomy/unsetSelectedItem',
                            { ...tp.item },
                        )
                    }
                    this.positions = positions
                    if (oldItem && oldItem.goettItem.PI !== tp.PI) {
                        this.removeItem(oldItem)
                    }
                    delete this.draggedItems['id_' + touches[i].identifier]
                }
            }
        },

        saveCollection() {
            this.$store.dispatch('cupboardsGameTaxonomy/saveCollection')

            this.$userLog.saveUserLog(
                'cupboard_save_collection',
                this.currentUser.uuid,
                {
                    structureIds:
                        this.$store.state.cupboardsGameTaxonomy.selectedItems.map(
                            (i) => i?.cupboardsTaxonomyItem.id,
                        ),
                },
            )
        },
        close() {
            this.$store.dispatch('cupboards/setGame', GAMES.NONE)
        },
        isEnabled() {
            return this.$store.state.cupboardsGameTaxonomy.selectedItems.reduce(
                (a, b) => a && b,
            )
        },
        getSlotRect(index) {
            const element = this.$refs[`selectedItemSlot_${index}`]
            return element[0]?.getBoundingClientRect()
        },
        getItemStyle(item) {
            const style = {
                position: 'absolute',
                transition: this.transitions[item.goettItem.PI],
            }
            if (!this.positions[item.goettItem.PI]) {
                //console.log('could not find style for', item)
                return {
                    ...style,
                    left: `0px`,
                    top: `0px`,
                }
            }
            // if item is currently dragged
            if (isEqual(this.draggedItem, item)) {
                return {
                    ...style,
                    left: `${this.positions[item.goettItem.PI].x}px`,
                    top: `${this.positions[item.goettItem.PI].y}px`,
                    zIndex: 1,
                }
            }
            // if item is selected then return position of slot
            if (this.$store.getters['cupboardsGameTaxonomy/isSelected'](item)) {
                const index =
                    this.$store.getters['cupboardsGameTaxonomy/getIndex'](item)
                const rect = this.getSlotRect(index)

                this.positions[item.goettItem.PI].x = rect.x + rect.width / 2
                this.positions[item.goettItem.PI].y = rect.y + rect.height / 2
                return {
                    ...style,
                    left: `${this.positions[item.goettItem.PI].x}px`,
                    top: `${this.positions[item.goettItem.PI].y}px`,
                }
            }
            return {
                ...style,
                left: `${this.positions[item.goettItem.PI].x}px`,
                top: `${this.positions[item.goettItem.PI].y}px`,
            }
        },
        isItemSelected(item) {
            return (
                this.$store.state.cupboardsGameTaxonomy.selectedItems.find(
                    (i) => i?.goettItem?.PI === item.goettItem.PI,
                ) != null
            )
        },
        removeItem(item) {
            this.$store.dispatch('cupboardsGameTaxonomy/unsetSelectedItem', {
                ...item,
            })
            console.log(
                'remove item',
                item,
                this.$store.state.cupboardsGameTaxonomy.selectedItems,
            )

            const positions = getRandomPositions(
                100,
                stationData.width - 100,
                stationData.height / 2,
                stationData.height - 100,
                200,
                10,
                [{ x: 400, y: 400, width: 1200, height: 500 }],
            )

            this.animateItemToPosition(item, positions[0], 1)
        },
        animateItemToPosition(item, position, duration, callback) {
            const transitions = { ...this.transitions }
            const positions = { ...this.positions }
            positions[item.goettItem.PI] = position
            transitions[item.goettItem.PI] = `linear ${duration}s`
            this.positions = positions
            this.transitions = transitions

            setTimeout(() => {
                const transitions = { ...this.transitions }
                delete transitions[item.goettItem.PI]
                this.transitions = transitions
                if (callback) {
                    callback()
                }
            }, duration * 1000)
        },
    },
}
</script>
<style lang="scss" scoped>
$itemLength: 100px;

.gameTaxonomy {
    display: flex;
    flex-direction: column;
    color: var(--secondary);

    .content {
        flex-grow: 1;

        .items {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;

            .item {
                transform: translate(-50%, -50%);
                overflow: hidden;

                & img {
                    transform: scale(1.2);
                    object-fit: cover;
                }

                .removeButton {
                    width: 24px;
                    height: 24px;
                }
            }

            background-color: lightgreen;
        }

        .slots {
            position: absolute;
            top: 60%;
            transform: translateY(-50%);
            width: 100%;
            text-align: center;

            img {
                width: calc(1100vw * var(--pxToVw));
            }

            .selectedItemSlot {
                position: absolute;
                top: calc(150vw * var(--pxToVw));
                //* border: 1px dashed black; */
                width: calc(#{$itemLength} * 2);
                height: calc(#{$itemLength} * 2);
                display: flex;
                align-items: center;
                justify-content: center;

                .handle {
                    border: 1px solid black;
                    /* background-color: white; */
                    width: 12.5%;
                    height: 12.5%;
                }

                &.selectedItemSlot_0 {
                    left: calc(500vw * var(--pxToVw));
                }

                &.selectedItemSlot_1 {
                    left: calc(750vw * var(--pxToVw));
                }

                &.selectedItemSlot_2 {
                    left: calc(990vw * var(--pxToVw));
                }

                &.selectedItemSlot_3 {
                    left: calc(1210vw * var(--pxToVw));
                }
            }
        }

        .name {
            position: absolute;
            z-index: 0;
            text-align: center;
            width: 100%;
            top: 75%;

            .value {
                &::first-letter {
                    text-transform: capitalize;
                }

                quotes: '\201c''\201d';

                &:before {
                    content: open-quote;
                }

                &:after {
                    content: close-quote;
                }
            }
        }
    }
}

.possible-positions {
    position: absolute;
    top: 100px;
    bottom: 100px;
    left: 100px;
    right: 100px;
    background-color: rgba(0, 200, 200, 0.2);
}

/* VISUALLISIERUBNG DER SCHUTZZONEN */
.exclude {
    position: absolute;
    background-color: rgba(200, 0, 0, 0.5);

    /* BEDIENELEMENETE */
    &-1 {
        left: 1750px;
        top: 900px;
        width: 70px;
        height: 80px;
    }

    /* ERKLÄRUNGSTEXTE */
    &-2 {
        left: 0px;
        top: 0px;
        width: 800px;
        height: 500px;
    }

    /* REGAL */
    &-3 {
        left: 385px;
        top: 420px;
        width: 1150px;
        height: 520px;
    }
}
</style>
