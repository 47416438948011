<template>
    <div class="home vw-100 vh-100 p-5 overflow-hidden">
        <h1>Station auswählen</h1>
        <div class="stations-container d-flex flex-wrap">
            <div
                v-for="(station, index) in stationsData"
                :key="'station' + index"
                class="station p-5 m-2 bg-light"
                @click="selectStation(station)"
            >
                <h2 class="text-secondary">{{ station.name }}</h2>
            </div>
        </div>
        <h2>Utils</h2>
        <a
            :href="`https://cms.forum-wissen.de/twoavy-sockets`"
            target="_blank"
            rel="noopener noreferrer"
        >
            pusher sockets monitor
        </a>
    </div>
</template>
<script>
import { stationsData } from '@/stationsData'

export default {
    name: 'Home',
    data() {
        return {
            stationsData: stationsData,
        }
    },
    mounted() {
        if (window.electronApi) {
            window.electronApi.send('fowiConfig', 'getConfig')
            window.electronApi.receive('fowiConfig', (settings) => {
                this.$store.dispatch('setUnitConfig', settings)
                if (settings.station) {
                    this.$store.dispatch('setUnit', settings.station)
                    this.setResolution(settings.resolution)
                    this.setTheme(settings.theme)
                    this.$router.push({ name: settings.station })
                } else {
                    this.$router.push({ name: 'settings' })
                }
            })
        }
    },
    beforeDestroy() {
        if (window.electronApi) {
            window.electronApi.off('fowiConfig')
        }
    },
    methods: {
        selectStation(station) {
            this.$store.dispatch('setUnitConfig', station)
            this.setResolution(station.resolution)
            this.setTheme(station.theme)
            this.$router.push(station.key)
        },
        setResolution(res) {
            this.bodyElement = document.querySelector('body')
            this.bodyElement.setAttribute('data-resolution', res)
        },
        setTheme(theme) {
            this.bodyElement = document.querySelector('body')
            this.bodyElement.setAttribute('data-theme', theme)
        },
    },
}
</script>
<style lang="scss" scoped></style>
