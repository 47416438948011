<script>
import api from '../../../mixins/api'

export default {
    name: 'UserLogin',
    props: {
        connectedUsers: {
            type: Array,
            default: null,
        },
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            openObj: [],
            selectedObj: [],
            timeoutObj: [],
            idle: 3000,
            idle2: 10000,
            idle3: 20000,
            pulse: true,
            touchIcon: false,
            playNowObj: [],
            transformSwipe: 33.3,
            qrcodeIdle: null,
            qrcodeShowCountdown: null,
            qrcodeCountdown: 20,
        }
    },
    mounted() {
        setTimeout(() => {
            this.touchIcon = true
        }, this.idle)
    },
    methods: {
        swipe(direction, id) {
            if (direction === 'left') {
                this.transformSwipe += 33.3
            } else if (direction === 'right') {
                this.transformSwipe -= 33.3
            }
            clearTimeout(this.timeoutObj[id])
            // console.log(id)

            this.qrcodeIdle = setTimeout(() => {
                this.closeQR()
            }, this.idle3)

            this.qrcodeShowCountdown = setInterval(() => {
                this.qrcodeCountdown--
            }, 1000)
        },
        closeQR(i) {
            this.transformSwipe = 33.3
            clearTimeout(this.qrcodeIdle)
            clearTimeout(this.qrcodeShowCountdown)
            this.qrcodeCountdown = 20
            this.timeoutObj[i] = setTimeout(() => {
                this.open(i)
            }, this.idle2)
        },
        open(i) {
            // console.log(i)
            if (this.openObj.includes(i)) {
                let index = this.openObj.indexOf(i)

                this.openObj.splice(index, 1)

                clearTimeout(this.timeoutObj[i])
            } else {
                this.openObj.push(i)
                this.timeoutObj[i] = setTimeout(() => {
                    this.open(i)
                }, this.idle2)
                this.transformSwipe = 33.3
            }
            // console.log(this.openObj)
        },

        loginWithoutMobile(id, language) {
            const unit = 'knowledgeNetworks_' + id
            const createCollectionFor = [
                {
                    unit: 'knowledgeNetworks',
                    count: 3,
                },
            ]
            this.open(id)
            this.playNowObj.push(id)

            this.$emit('without-mobile', id)
            api.twoavy.loginUserWithoutMobile(
                unit,
                createCollectionFor,
                language,
            )
        },
    },
}
</script>
<template>
    <div class="w-100 h-100 relative">
        <div
            v-if="touchIcon"
            class="absolute touchIcon"
            :class="
                openObj.includes(id) || playNowObj.includes(id) ? 'hide' : ''
            "
        >
            <img
                class="btn"
                :class="
                    openObj.includes(id) || playNowObj.includes(id)
                        ? 'hide'
                        : ''
                "
                :src="require('@/assets/img/touch-id.svg')"
                alt=""
                @click="open(id)"
            />
        </div>
        <div
            class="bgbox"
            :class="['bgbox-' + id, openObj.includes(id) ? 'open' : '']"
        >
            <!--          :class="pulse ? 'visible' : ''"-->
            <div class="toggle">
                <img
                    v-show="openObj.includes(id)"
                    class="btn-close"
                    :src="require('@/assets/img/close.svg')"
                    alt=""
                    @click.prevent.stop="open(id)"
                />
            </div>
            <div v-if="openObj.includes(id)" class="box-content flex flex-col">
                <div class="language-buttons-wrapper w-100">
                    <div class="w-50 flex leftBox">
                        <h2
                            class="loginText de"
                            @click="loginWithoutMobile(id, 'de')"
                        >
                            probieren ohne App
                        </h2>
                        <h2
                            class="loginText en"
                            @click="loginWithoutMobile(id, 'en')"
                        >
                            try without App
                        </h2>
                    </div>
                    <div class="swipe-wrap w-150 h-full">
                        <div
                            class="swipe-wrap-inner flex flex-row relative"
                            :style="
                                'transform: translateX(-' +
                                transformSwipe +
                                '%);'
                            "
                        >
                            <div class="flex flex-column w-full slide">
                                <button
                                    class="right-0 absolute close-qr right-arrow"
                                    @click.prevent.stop="closeQR(id)"
                                >
                                    <font-awesome-icon
                                        :icon="['far', 'angle-right']"
                                        size="xl"
                                    />
                                </button>
                                <div
                                    :key="qrcodeShowCountdown"
                                    class="countdown"
                                >
                                    <div class="countdown-number">
                                        {{ qrcodeCountdown }}
                                    </div>
                                    <svg>
                                        <circle r="18" cx="20" cy="20"></circle>
                                    </svg>
                                </div>
                                <h2>Android</h2>
                                <img
                                    class="qr-code"
                                    :src="
                                        require('@/assets/img/qr_code_android.png')
                                    "
                                    alt=""
                                />
                            </div>
                            <div class="slide relative">
                                <button
                                    class="left-0 ml-1 close-qr absolute left-arrow"
                                    @click="swipe('right', id)"
                                >
                                    <font-awesome-icon
                                        :icon="['far', 'angle-left']"
                                        size="xl"
                                    />
                                </button>
                                <div class="flex w-full middleBox">
                                    <h2>lade die App</h2>
                                    <div class="logos">
                                        <img
                                            class="store-logo"
                                            :src="
                                                require('@/assets/img/android-logo.png')
                                            "
                                            alt=""
                                            @click="swipe('right', id)"
                                        />
                                        <img
                                            class="store-logo"
                                            :src="
                                                require('@/assets/img/apple-logo.png')
                                            "
                                            alt=""
                                            @click="swipe('left', id)"
                                        />
                                    </div>
                                    <h2>load the App</h2>
                                </div>
                                <button
                                    class="right-0 mr-1 absolute close-qr right-arrow"
                                    @click="swipe('left', id)"
                                >
                                    <font-awesome-icon
                                        :icon="['far', 'angle-right']"
                                        size="xl"
                                    />
                                </button>
                            </div>
                            <div
                                class="flex flex-column absolute w-full slide ios"
                            >
                                <button
                                    class="left-0 close-qr absolute left-arrow"
                                    @click.prevent.stop="closeQR(id)"
                                >
                                    <font-awesome-icon
                                        :icon="['far', 'angle-left']"
                                        size="xl"
                                    />
                                </button>
                                <div
                                    :key="qrcodeShowCountdown"
                                    class="countdown"
                                >
                                    <div class="countdown-number">
                                        {{ qrcodeCountdown }}
                                    </div>
                                    <svg>
                                        <circle r="18" cx="20" cy="20"></circle>
                                    </svg>
                                </div>
                                <h2>iOS</h2>
                                <img
                                    class="qr-code"
                                    :src="
                                        require('@/assets/img/qr_code_ios.png')
                                    "
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.box-header {
    width: 60%;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
        padding-bottom: 0;
        padding-left: calc(30vw * var(--pxToVw));
        padding-right: 0;
    }

    h1 {
        color: white;
        padding-bottom: calc(5vw * var(--pxToVw));
        padding-top: calc(5vw * var(--pxToVw));
    }
}

h2 {
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.btn-close {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin: 10px 20px;
    position: absolute;
    z-index: 100;
    cursor: pointer;
}

.bgbox {
    height: 10vh;
    width: 25vh;
    display: flex;
    align-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;

    &.open {
        box-shadow: rgb(0, 0, 0) 0px 0px 25px;
    }
}
.box-content {
    height: 100%;
}
.box-content h2,
.box-content p {
    color: black;
}

$user-1-color: #004f8d; //#188ad9; rgb(0,79,141)
$user-2-color: #910114; // rgb(145,1,20)
$user-3-color: #0a7502; // rgb(10,117,2)
$user-4-color: #df6800; // rgb(223,104,0)
$user-5-color: #f00187; // rgb(240,1,135)
$user-6-color: #01213f; // rgb(1,33,63)

.bgbox-1,
.bgbox-2,
.bgbox-3 {
    transform: translateX(5%) translateY(12vh);
    position: absolute;
    transition: all 0.3s linear;
    left: 0px;
    padding-top: 8vh;
    bottom: 0;
    &.open {
        transform: translateX(1vh) translateY(0vh);
        //border: 2px solid;
        padding-top: 0vh;
        background-color: white;
    }

    img.btn-close,
    img.btn {
        position: absolute;
        top: 10px;
        right: 0px;
    }

    .touchIcon {
        position: absolute;
        top: 20px;
        left: 20px;
    }
}

.bgbox-4,
.bgbox-5,
.bgbox-6 {
    transform: translateX(-1vh) translateY(-11vh);
    position: absolute;
    transition: all 0.3s linear;
    padding-bottom: 8vh;
    right: 0px;
    border-top: 10px solid;

    &.open {
        transform: translateX(-4%) translateY(0vh);
        //border: 2px solid;
        padding-bottom: 1vh;
        background-color: rgba(0, 0, 0, 0.5);
        background-color: white;
    }
    //h2 {
    //    padding-bottom: 0;
    //    padding-left: 10px;
    //    padding-right: 10px;
    //}
    img.btn-close,
    .touchIcon img.btn {
        position: absolute;
        bottom: 10px;
        transform: rotate(180deg) translateX(-50%);
        left: 0px;
        transform-origin: center center;
    }

    .box-content {
        transform: rotate(180deg) translateX(0%) translateY(-9%);
        left: 0;
    }
}
.knowledge-networks-user1,
.knowledge-networks-user2,
.knowledge-networks-user3 {
    .touchIcon {
        position: absolute;
        bottom: 0px;
        left: 1vh;
    }
}

.knowledge-networks-user4,
.knowledge-networks-user5,
.knowledge-networks-user6 {
    .touchIcon {
        position: absolute;
        top: 0px;
        right: 1vh;
    }
    .touchIcon img.btn {
        transform: rotate(180deg);
    }
}

//.touchIcon,
//.toggle .btn {
//    opacity: 1;
//}

.language-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.language-buttons-wrapper .store {
    display: flex;
    flex-direction: column;
    align-items: center;

    border-right: 3px solid black;
}

.knowledge-networks-user1 {
    .bgbox.open {
        border-top: 20px solid $user-1-color;
    }
}
.knowledge-networks-user2 {
    .bgbox.open {
        border-top: 20px solid $user-2-color;
    }
}
.knowledge-networks-user3 {
    .bgbox.open {
        border-top: 20px solid $user-3-color;
    }
}
.knowledge-networks-user4 {
    .bgbox.open {
        border-bottom: 20px solid $user-4-color;
    }
}
.knowledge-networks-user5 {
    .bgbox.open {
        border-bottom: 20px solid $user-5-color;
    }
}
.knowledge-networks-user6 {
    .bgbox.open {
        border-bottom: 20px solid $user-6-color;
    }
}

@keyframes fade-in-out {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

img.btn {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(140vw * var(--pxToVw));
    height: calc(140vw * var(--pxToVw));
    background-color: #e01d34;
    border-radius: 10px;
    padding: 20px;
}

.knowledge-networks-user4,
.knowledge-networks-user5,
.knowledge-networks-user6 {
    .touchIcon img.btn {
        top: 0px;
        right: 0px;
        left: unset;
    }
}

.swipe-wrap {
    width: 50%;
    overflow-y: hidden;
}

.swipe-wrap-inner {
    width: 300%;
    display: flex;
    flex-direction: row;
    transition: all 0.3s linear;
    .slide {
        justify-content: start;
        display: flex;
        width: 50%;
        align-items: center;
        position: relative;
    }
}

.close-qr {
    position: absolute;
    border: none;
    height: 40px;
    width: 30px;
    top: 0px;
    padding: 10px;
    background: none;

    svg {
        width: 30px !important;
        height: 50px !important;
    }
}

.right-arrow.close-qr {
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.slide .right-arrow.close-qr {
    right: 20px;
}

.left-arrow.close-qr {
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.slide .left-arrow.close-qr {
    left: 10px;
}

.qr-code {
    width: 40%;
    //padding-top: 2%;
    svg {
        top: 0px;
        left: 0px;
    }
}
img.store-logo {
    width: 40%;
    padding: 0 20px;
}

//########

.leftBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
    border-right: 3px solid black;
}

.language-buttons-wrapper {
    margin-top: 80px;
}

.middleBox {
    width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .logos {
        padding: 10px 0px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
</style>

<style lang="scss">
.left-arrow ~ .countdown {
    right: 20px;
}

.right-arrow ~ .countdown {
    left: 20px;
}

.countdown {
    position: absolute;
    margin: auto;
    height: 40px;
    width: 40px;
    text-align: center;
    bottom: 0px;

    .countdown-number {
        color: black;
        display: inline-block;
        line-height: 40px;
    }

    svg {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        transform: rotateY(-180deg) rotateZ(-90deg);
    }

    svg circle {
        stroke-dasharray: 140px;
        stroke-dashoffset: 0px;
        stroke-linecap: round;
        stroke-width: 3px;
        stroke: black;
        fill: none;
        animation: countdown 20s linear infinite forwards;
    }

    @keyframes countdown {
        from {
            stroke-dashoffset: 0px;
        }
        to {
            stroke-dashoffset: 140px;
        }
    }
}

.loginText {
    background-color: #e01d34;
    padding: 10px 15px;
    color: white !important;

    &.de {
        margin-bottom: 50px;
    }
}
</style>
