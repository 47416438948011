<template><span></span></template>

<script>
import { debounce } from 'lodash'
import api from '@/mixins/api'

export default {
    name: 'LoginTimer',
    props: {
        userUuid: {
            type: String,
            required: true,
        },
        unit: {
            type: String,
            required: true,
        },
        activeArea: {
            type: [HTMLDocument, HTMLHtmlElement, HTMLDivElement],
            default: () => document,
        },
        trigger: {
            type: Array,
            default: () => ['mousedown', 'touchstart', 'touchmove'],
        },
        debounceTimeInSec: {
            type: Number,
            default: () => 5,
        },
        maxWaitInSec: {
            type: Number,
            default: () => 10,
        },
    },
    data() {
        return {
            debounceFunction: null,
        }
    },
    beforeDestroy() {
        this.debounceFunction.cancel()
        this.trigger.forEach((x) =>
            this.activeArea.removeEventListener(x, this.debounceFunction),
        )
    },
    mounted() {
        this.debounceFunction = debounce(
            this.stayAtUnit,
            this.debounceTimeInSec * 1000,
            {
                maxWait: this.maxWaitInSec * 1000,
                leading: true,
            },
        )
        this.trigger.forEach((x) =>
            this.activeArea.addEventListener(x, this.debounceFunction),
        )
    },
    methods: {
        stayAtUnit() {
            if (this.userUuid && this.unit) {
                api.twoavy.stayAtUnit(this.userUuid, this.unit)
            }
        },
    },
}
</script>

<style scoped></style>
