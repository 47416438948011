<template>
    <div
        id="app"
        class="vh-100 vw-100 radial-gradient text-white overflow-hidden"
    >
        <transition name="fade" mode="out-in">
            <router-view />
        </transition>

        <transition name="fade" mode="out-in">
            <confirm-popup v-if="$store.getters.getConfirmPopup" />
        </transition>
    </div>
</template>

<script>
import { stationsData } from '@/stationsData'
import api from '@/mixins/api'
import { setLocale } from './i18n'

// pusher websockets
import Echo from 'laravel-echo'
import pusherJS from 'pusher-js'
import ConfirmPopup from '@/components/global/ConfirmPopup'
import emitter from '@/services/emitter.js'

export default {
    name: 'Home',
    components: { ConfirmPopup },
    computed: {
        stationConfig() {
            return this.$store.getters.getUnitConfig
        },
    },
    watch: {
        stationConfig: {
            async handler(val) {
                if (val) {
                    await this.$store.dispatch('getContent', val.sectionId)
                    this.pollUsers()
                }
            },
            deep: true,
        },
    },
    async mounted() {
        await this.$store.dispatch('getSettings')
        setTimeout(async () => {
            api.twoavy.createInterceptor()
            await this.$store.dispatch('items/init')
            await this.$store.dispatch('getAndSetNfcTags')
            await this.$store.dispatch('getApp').then(() => {
                this.initPusher()
            })
            await this.$store.dispatch('interfaceTexts/setInterfaceTexts')
            await this.$store.dispatch('setLanguages')
            const station = stationsData.find((x) => x.key === this.$route.name)
            if (station) {
                await this.$store.dispatch('setUnitConfig', station)
                this.setResolution(station.resolution)
                this.setTheme(station.theme)
            }

            if (window.electronApi) {
                window.electronApi.receive('setRoute', (res) => {
                    if (this.$route.name !== res) {
                        this.$router.push({ name: res })
                    }
                })
            }
            // set unit name according to route name
            if (this.$route?.name) {
                await this.$store.dispatch('setUnitName', this.$route.name)
            }
            console.log(this.$route?.name)
            this.$userLog.saveUserLog('app_init')

            emitter.on('logout-user', this.logoutUser)
        }, 2500)
    },
    beforeDestroy() {
        // clearInterval(this.pollIntervalId)
    },
    methods: {
        setResolution(res) {
            this.bodyElement = document.querySelector('body')
            this.bodyElement.setAttribute('data-resolution', res)
        },
        setTheme(theme) {
            this.bodyElement = document.querySelector('body')
            this.bodyElement.setAttribute('data-theme', theme)
        },
        initPusher() {
            window.Pusher = pusherJS

            const pusherOptions = {
                broadcaster: 'pusher',
                key: this.$store.state.app.pusherKey,
                forceTLS: this.$store.state.app.pusherForceTls,
                disableStats: true,
            }

            if (
                !this.$store.state.app.pusherPort &&
                !this.$store.state.app.pusherHost
            ) {
                pusherOptions.cluster = this.$store.state.app.pusherCluster
            } else {
                pusherOptions.wsHost = this.$store.state.app.pusherHost
                pusherOptions.wsPort =
                    this.$store.state.app.pusherPort !== ''
                        ? this.$store.state.app.pusherPort
                        : 80
                pusherOptions.wssPort =
                    this.$store.state.app.pusherPort !== ''
                        ? this.$store.state.app.pusherPort
                        : 443
                pusherOptions.enabledTransports = ['ws', 'wss']
            }

            const EchoInstance = new Echo(pusherOptions)
            const channel = EchoInstance.channel('fowi')
            channel.listen('.login', (data) => {
                console.log('user logged in', data)
                setLocale(data.settings.language)
                const { unit } = data

                if (
                    unit === this.$route.name ||
                    unit.startsWith(this.$route.name)
                ) {
                    this.$store.dispatch('setUser', data)
                }
            })
            channel.listen('.logout', (data) => {
                console.log('user logged out', data)
                if (data.unit.startsWith(this.$route.name)) {
                    this.$store.dispatch('unsetUser', data.unit)
                }
            })
        },
        pollUsers() {
            const unit = this.$route.name
            if (unit === 'knowledgeNetworks' || unit === 'market') {
                const numSubUnits = unit === 'knowledgeNetworks' ? 6 : 4
                for (let i = 0; i < numSubUnits; i++) {
                    api.twoavy
                        .checkUnitOccupied(`${unit}_${i}`)
                        .then((response) => {
                            const aUserIsLoggedIn = response.data.is_occupied
                            if (aUserIsLoggedIn) {
                                const user = {
                                    uuid: response.data.payload.uuid,
                                    unit: response.data.payload.unit,
                                    settings: response.data.settings,
                                    collection: response.data.collection,
                                }
                                this.$store.dispatch('setUser', user)
                            } else {
                                this.$store.dispatch(
                                    'unsetUser',
                                    `${unit}_${i}`,
                                )
                            }
                        })
                }
            } else {
                api.twoavy.checkUnitOccupied(unit).then((response) => {
                    const aUserIsLoggedIn = response.data.is_occupied
                    if (aUserIsLoggedIn) {
                        const user = {
                            uuid: response.data.payload.uuid,
                            unit: response.data.payload.unit,
                            settings: response.data.settings,
                            collection: response.data.collection,
                        }
                        this.$store.dispatch('setUser', user)
                    } else {
                        this.$store.dispatch('unsetUser', unit)
                    }
                })
            }
        },
        logoutUser(data) {
            const unit = this.$route.name
            const user = data.split(' ').find((x) => x.startsWith(unit))
            this.$store.dispatch('unsetUser', user)
        },
    },
}
</script>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/bootstrap';
@import 'assets/scss/fonts';
@import 'assets/scss/layout';
@import 'assets/scss/global';

.front {
    z-index: 999;
}

.interface {
    position: absolute;
    top: 0;
    left: 0;
}
</style>
