<template>
    <div class="drawing h-100 w-100">
        <div class="toolbar d-flex flex-column">
            <div class="d-flex">
                <div class="d-flex flex-column align-items-center">
                    <draw-line-button
                        v-for="stroke in STROKEWIDTHS"
                        :key="'stroke-btn-' + stroke"
                        stroke="white"
                        :stroke-width="stroke"
                        :selected="
                            selectedMode === MODES.DRAW &&
                            selectedStrokeWidth === stroke
                        "
                        @click="setStrokeWidth(stroke)"
                    />
                </div>
                <div class="d-flex flex-column align-items-center ml-3">
                    <draw-hatching-button
                        v-for="fill in Object.keys(BTNFILLS).filter(
                            (key) => BTNFILLS[key] !== BTNFILLS.NONE,
                        )"
                        :key="'fill-btn-' + BTNFILLS[fill]"
                        :pattern="BTNFILLS[fill]"
                        :selected="
                            selectedMode === MODES.DRAWFILL &&
                            selectedFill === FILLS[fill]
                        "
                        @click="setFill(FILLS[fill])"
                    />
                </div>
            </div>
        </div>
        <div class="toolbar-bottom d-flex mt-3">
            <div
                v-if="manifest"
                :class="
                    manifest.sequences[0].canvases.length <= 1 ||
                    paths.length !== 0
                        ? 'disabled'
                        : ''
                "
                class="fowi-btn fowi-btn-primary fowi-btn-sq48"
                @click="toggleImageGallery"
            >
                <font-awesome-icon :icon="['far', 'images']" size="lg" />
            </div>
            <div
                :class="paths.length === 0 ? 'disabled' : ''"
                class="fowi-btn fowi-btn-primary fowi-btn-sq48 mx-3"
                @click="toggleVisibility"
            >
                <font-awesome-icon
                    :icon="['far', visible ? 'eye' : 'eye-slash']"
                    size="lg"
                />
            </div>
            <div
                :class="paths.length === 0 ? 'disabled' : ''"
                class="fowi-btn fowi-btn-primary fowi-btn-sq48 mr-3"
                @click="undo"
            >
                <font-awesome-icon :icon="['far', 'undo']" size="lg" />
            </div>
            <div
                :class="paths.length === 0 ? 'disabled' : ''"
                class="fowi-btn fowi-btn-primary fowi-btn-sq48 mr-3"
                @click="clearConfirm"
            >
                <font-awesome-icon :icon="['far', 'trash']" size="lg" />
            </div>
            <div
                :class="paths.length === 0 ? 'disabled' : ''"
                class="fowi-btn fowi-btn-primary fowi-btn-sq48 mr-3"
                @click="saveConfirm"
            >
                <font-awesome-icon :icon="['far', 'cloud-upload']" size="lg" />
            </div>
        </div>
        <div class="slider-container">
            <div class="outer-zoom">
                <div class="inner-zoom">
                    <slider
                        v-model.number="zoomFactor"
                        :min="1"
                        :max="2"
                        :step="0.01"
                        show-dragged-amount
                        hide-label
                    />
                </div>
            </div>
        </div>
        <div class="drawing-container d-flex overflow-hidden">
            <div id="svg-canvas" class="drawing-container-canvas">
                <div
                    ref="canvas"
                    :style="{
                        width: imageWidth ? imageWidth + 'px' : '100%',
                        height: imageHeight ? imageHeight + 'px' : '100%',
                    }"
                >
                    <img
                        ref="img"
                        :style="getImgStyle"
                        :src="imageSrc"
                        @load="onImageLoad"
                    />
                    <svg
                        ref="svg"
                        :style="{
                            width: imageWidth
                                ? imageWidth * zoomFactor + 'px'
                                : '100%',
                            height: imageHeight
                                ? imageHeight * zoomFactor + 'px'
                                : '100%',
                            background: visible ? 'none' : 'white',
                        }"
                        @mousedown="onStart"
                        @mousemove="onDraw"
                        @mouseup="onEnd"
                        @touchstart="onStart"
                        @touchmove="onDraw"
                        @touchend="onEnd"
                    >
                        <hatching-pattern-defs stroke="black" />
                        <path
                            v-for="(path, index) in paths.filter(
                                (path) => path.fill === FILLS.NONE,
                            )"
                            :key="'path_' + index"
                            :d="path.dataString"
                            :transform="`scale(${zoomFactor} ${zoomFactor})`"
                            fill="none"
                            :stroke="path.strokeColor"
                            :stroke-width="path.strokeWidth"
                        />

                        <path
                            v-for="(path, index) in paths.filter(
                                (path) => path.fill !== FILLS.NONE,
                            )"
                            :key="'hull_' + index"
                            :d="path.hullDataString"
                            :transform="`scale(${zoomFactor} ${zoomFactor})`"
                            :fill="path.fill"
                            :stroke="path.selected ? 'red' : 'none'"
                            :stroke-width="path.selected ? 2 : 0"
                            @touchstart="pickPath(path)"
                        />
                    </svg>
                </div>
            </div>
        </div>
        <image-gallery
            :open-modal="imageGallery"
            :manifest="manifest"
            @close="imageGallery = false"
            @selectImage="changeImageSrc"
        />
    </div>
</template>

<script>
import api from '@/mixins/api'
import hull from '../../../../../node_modules/hull.js/src/hull'
import html2canvas from 'html2canvas'
import HatchingPatternDefs from '@/components/stations/3.005_atelier/handdrawing/HatchingPatternDefs'
import DrawHatchingButton from '@/components/stations/3.005_atelier/handdrawing/DrawHatchingButton'
import DrawLineButton from '@/components/stations/3.005_atelier/handdrawing/DrawLineButton'
import Slider from '@/components/global/Slider.vue'
import ImageGallery from '@/components/stations/3.005_atelier/handdrawing/ImageGallery'
import { nextTick } from 'vue'

const MODES = {
    DRAW: 'DRAW',
    DRAWFILL: 'DRAWFILL',
    FILL: 'FILL',
}
const BTNFILLS = {
    NONE: 'none',
    PATTERNA: 'url(#btnPatternA)',
    PATTERNB: 'url(#btnPatternB)',
    PATTERNC: 'url(#btnPatternC)',
    PATTERND: 'url(#btnPatternD)',
    PATTERNE: 'url(#btnPatternE)',
}
const FILLS = {
    NONE: 'none',
    PATTERNA: 'url(#patternA)',
    PATTERNB: 'url(#patternB)',
    PATTERNC: 'url(#patternC)',
    PATTERND: 'url(#patternD)',
    PATTERNE: 'url(#patternE)',
}
const STROKEWIDTHS = {
    VERYSMALL: 1,
    SMALL: 2,
    MEDIUM: 3,
    BIG: 4,
    VERYBIG: 5,
}
export default {
    name: 'Handdrawing',
    components: {
        ImageGallery,
        DrawLineButton,
        DrawHatchingButton,
        HatchingPatternDefs,
        Slider,
    },
    data() {
        return {
            selectedStrokeWidth: 1,
            selectedStrokeColor: 'black',
            userDrawing: null,
            imageSrc: null,
            imageGallery: false,
            imageLoaded: false,
            imageWidth: 0,
            imageHeight: 0,
            tempNewPath: null,
            paths: [],
            MODES,
            BTNFILLS,
            FILLS,
            STROKEWIDTHS,
            selectedMode: MODES.DRAW,
            selectedFill: FILLS.PATTERNA,
            visible: true,
            isDrawing: false,
            zoomFactor: 1,
            manifest: null,
        }
    },
    computed: {
        getImgStyle() {
            return {
                width: this.imageWidth
                    ? this.imageWidth * this.zoomFactor + 'px'
                    : '100%',
                height: this.imageHeight
                    ? this.imageHeight * this.zoomFactor + 'px'
                    : '100%',
                opacity: this.visible && this.imageLoaded ? 1 : 0,
            }
        },
        currentUser() {
            return this.$store.getters.getUserAtUnit(this.$route.name)
        },
    },
    watch: {
        zoomFactor: function (newValue, oldValue) {
            if (newValue && oldValue && this.$refs?.canvas) {
                const newWidth = this.imageWidth * newValue
                let offsetLeft
                const scrollLeft = this.$refs.canvas.scrollLeft
                const scrollRight =
                    this.imageWidth * oldValue -
                    scrollLeft -
                    this.$refs.canvas.clientWidth
                console.log(scrollLeft, scrollRight)
                if (scrollLeft > 0 && scrollRight > 0) {
                    offsetLeft =
                        (scrollLeft / (scrollLeft + scrollRight)) *
                        (newWidth - this.$refs.canvas.clientWidth)
                } else {
                    offsetLeft = (newWidth - this.$refs.canvas.clientWidth) / 2
                }

                console.log(offsetLeft)
                this.$refs.canvas.scrollLeft = offsetLeft
                console.log(this.$refs.canvas.scrollLeft)

                const newHeight = this.imageHeight * newValue
                let offsetTop
                const scrollTop = this.$refs.canvas.scrollTop
                const scrollBottom =
                    this.imageHeight * oldValue -
                    this.$refs.canvas.scrollTop -
                    this.$refs.canvas.clientHeight
                if (scrollTop > 0 && scrollBottom > 0) {
                    offsetTop =
                        (scrollTop / (scrollTop + scrollBottom)) *
                        (newHeight - this.$refs.canvas.clientHeight)
                } else {
                    offsetTop = (newHeight - this.$refs.canvas.clientHeight) / 2
                }
                this.$refs.canvas.scrollTop = offsetTop
            }
        },
    },
    async mounted() {
        this.imageSrc = this.$store.state.atelier.item.fullsizeUrl
        this.manifest = (
            await api.manifest.getManifest(this.$store.state.atelier.item.id)
        ).data
    },
    methods: {
        changeImageSrc(imageSrc) {
            this.imageLoaded = false
            this.imageHeight = 0
            this.imageWidth = 0
            this.imageSrc = imageSrc
            this.imageGallery = false
        },
        pickPath(path) {
            this.unselectAllPaths()
            const paths = this.paths.filter((path) => path.selected)
            if (paths.length === 1 && paths[0].dataString === path.dataString) {
                paths[0].selected = false
            } else {
                path.selected = true
            }
        },
        unselectAllPaths() {
            const paths = this.paths.filter((path) => path.selected)
            if (paths.length > 0) {
                paths.forEach((path) => {
                    path.selected = false
                })
            }
        },
        onStart(event) {
            event.preventDefault()
            event.stopImmediatePropagation()
            this.isDrawing = true
            let point = null
            switch (event.type) {
                case 'touchstart': {
                    const imgRect = this.$refs.img.getBoundingClientRect()
                    point = [
                        (event.touches[0].clientX - imgRect.x) /
                            this.zoomFactor,
                        (event.touches[0].clientY - imgRect.y) /
                            this.zoomFactor,
                    ]
                    break
                }
                case 'mousedown': {
                    console.log(event.offsetX, event.offsetY)
                    point = [event.offsetX, event.offsetY]
                    break
                }
                default: {
                    return
                }
            }

            let newPath = {
                selected: false,
                strokeWidth: this.selectedStrokeWidth,
                strokeColor: this.selectedStrokeColor,
                points: [point],
                hull: [],
                hullDataString: '',
                dataString: `M${point[0]},${point[1]} `,
                closed: false,
            }

            switch (this.selectedMode) {
                case MODES.DRAW: {
                    newPath.fill = 'none'
                    break
                }
                case MODES.DRAWFILL: {
                    newPath.fill = this.selectedFill
                    break
                }
                default: {
                    newPath.fill = 'none'
                    break
                }
            }
            this.tempNewPath = newPath
        },
        onDraw(event) {
            event.preventDefault()
            event.stopImmediatePropagation()
            if (!this.isDrawing) {
                return
            }
            this.unselectAllPaths()

            let point = null
            switch (event.type) {
                case 'touchmove': {
                    const imgRect = this.$refs.img.getBoundingClientRect()
                    point = [
                        (event.touches[0].clientX - imgRect.x) /
                            this.zoomFactor,
                        (event.touches[0].clientY - imgRect.y) /
                            this.zoomFactor,
                    ]
                    break
                }
                case 'mousemove': {
                    point = [event.offsetX, event.offsetY]
                    break
                }
                default: {
                    return
                }
            }

            if (this.tempNewPath) {
                this.paths.push(this.tempNewPath)
                this.tempNewPath = null
            }

            this.paths[this.paths.length - 1].points.push(point)
            switch (this.selectedMode) {
                case MODES.DRAWFILL: {
                    if (
                        this.paths[this.paths.length - 1].points.length % 10 ===
                        0
                    ) {
                        const hullPoints = hull(
                            this.paths[this.paths.length - 1].points,
                            50,
                        )
                        this.paths[this.paths.length - 1].hull = hullPoints
                        let hullDataString = `M${hullPoints[0][0]},${hullPoints[0][1]} `
                        hullPoints.forEach((point, index) => {
                            if (index > 0) {
                                hullDataString += ` L${point[0]},${point[1]} `
                            }
                        })
                        this.paths[this.paths.length - 1].hullDataString =
                            hullDataString
                    }
                    break
                }
            }
            this.paths[
                this.paths.length - 1
            ].dataString += ` L${point[0]}, ${point[1]} `
        },
        onEnd() {
            switch (this.selectedMode) {
                case MODES.DRAW: {
                    const startPoint =
                        this.paths[this.paths.length - 1].points[0]
                    const endPoint =
                        this.paths[this.paths.length - 1].points[
                            this.paths[this.paths.length - 1].points.length - 1
                        ]

                    const distance = Math.sqrt(
                        Math.pow(startPoint[0] - endPoint[0], 2) +
                            Math.pow(startPoint[1] - endPoint[1], 2),
                    )

                    const distanceThreshold = 10
                    if (distance < distanceThreshold) {
                        this.paths[this.paths.length - 1].closed = true
                    }
                    break
                }
            }
            this.isDrawing = false
        },
        clearConfirm() {
            this.$store.dispatch('setConfirmPopup', {
                text: this.$t('atelier-handdrawing-clear-confirm'),
                btns: {
                    0: {
                        icon: ['far', 'check'],
                        value: true,
                    },
                    1: {
                        icon: ['far', 'times'],
                        value: false,
                    },
                },
            })
            this.$root.$on('confirm', this.clear)
        },
        clear(ev) {
            this.$root.$off('confirm', this.clear)
            if (ev) {
                this.visible = true
                this.paths = []

                this.$userLog.saveUserLog(
                    'atelier_handdrawing_clear',
                    this.currentUser.uuid,
                    {
                        pid: this.$store.state.atelier.item.id,
                    },
                )
            }
        },
        undo() {
            this.paths.pop()
        },
        setStrokeWidth(strokeWidth) {
            this.selectedMode = MODES.DRAW
            this.selectedStrokeWidth = strokeWidth
        },
        setFill(fill) {
            if (this.paths.length > 0) {
                const selectedPath = this.paths?.filter((path) => path.selected)
                if (selectedPath.length === 1) {
                    selectedPath[0].fill = fill
                }
            }
            this.selectedMode = MODES.DRAWFILL
            this.selectedFill = fill
        },
        async saveConfirm() {
            this.unselectAllPaths()
            const svg = document.getElementById('svg-canvas')
            await html2canvas(svg).then((canvas) => {
                this.userDrawing = canvas.toDataURL('image/png')
            })

            this.$store.dispatch('setConfirmPopup', {
                text: this.$t('atelier-handdrawing-save-confirm'),
                image: this.userDrawing,
                btns: {
                    0: {
                        icon: ['far', 'cloud-upload'],
                        value: true,
                    },
                    1: {
                        icon: ['far', 'times'],
                        value: false,
                    },
                },
            })
            this.$root.$on('confirm', this.save)
        },
        async save(ev) {
            this.$root.$off('confirm', this.save)
            if (ev) {
                this.feedbackConfirm()
                await api.twoavy.createAtelierImage(
                    'handdrawing',
                    this.$store.getters.getUserAtUnit('atelier').uuid,
                    this.userDrawing,
                )

                this.$userLog.saveUserLog(
                    'atelier_handdrawing_save',
                    this.currentUser.uuid,
                    {
                        pid: this.$store.state.atelier.item.id,
                    },
                )
            } else {
                this.userDrawing = null
            }
        },
        feedbackConfirm() {
            this.$store.dispatch('setConfirmPopup', {
                text: this.$t('atelier-handdrawing-outro'),
                width: 1000,
                btns: {
                    0: {
                        icon: ['far', 'check'],
                        value: true,
                    },
                },
                timer: { time: 45, event: true },
            })
            this.$root.$on('confirm', this.feedback)
        },
        feedback(ev) {
            console.log(ev)
            this.$root.$off('confirm', this.feedback)
            this.$emit('close')
        },
        toggleImageGallery() {
            this.imageGallery = !this.imageGallery
        },
        toggleVisibility() {
            this.visible = !this.visible
        },
        onImageLoad() {
            const rect = this.$refs.canvas.getBoundingClientRect()
            const width = this.$refs.img.naturalWidth
            const height = this.$refs.img.naturalHeight
            if (width / height < rect.width / rect.height) {
                const ratio = rect.height / height
                this.imageHeight = ratio * height
                this.imageWidth = ratio * width
            } else {
                const ratio = rect.width / width
                this.imageHeight = ratio * height
                this.imageWidth = ratio * width
            }
            setTimeout(() => {
                this.imageLoaded = true
                // console.log(this.imageWidth, this.imageHeight)
            }, 500)
        },
    },
}
</script>

<style scoped lang="scss">
.slider-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    top: 0;
    right: 0;
    margin: calc(1010vw * var(--pxToVw)) calc(50vw * var(--pxToVw))
        calc(0vw * var(--pxToVw)) calc(0vw * var(--pxToVw));
    width: calc(1596vw * var(--pxToVw));
    height: calc(48vw * var(--pxToVw));

    .outer-zoom {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40vw;

        .inner-zoom {
            width: 40vw;
            position: absolute;
            margin: auto;
        }
    }
}

.drawing {
    &-container {
        position: absolute;
        top: 0;
        right: 0;
        margin: calc(98vw * var(--pxToVw)) calc(50vw * var(--pxToVw))
            calc(0vw * var(--pxToVw)) calc(0vw * var(--pxToVw));
        width: calc(1596vw * var(--pxToVw));
        height: calc(893vw * var(--pxToVw));

        &-canvas {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;

            div {
                position: absolute;
                white-space: nowrap;
                overflow: auto;

                img {
                    position: absolute;
                    object-fit: cover;
                    object-position: top left;
                    left: 0;
                }

                svg {
                    position: absolute;
                    left: 0;
                }
            }
        }
    }

    .toolbar {
        position: absolute;
        left: calc(50vw * var(--pxToVw));
        top: 50%;
        transform: translateY(-50%);

        &-bottom {
            position: absolute;
            z-index: 10;
            left: calc(50vw * var(--pxToVw));
            bottom: calc(25vw * var(--pxToVw));
        }
    }
}

/*
::-webkit-scrollbar {
    width: calc(32vw * var(--pxToVw));
    height: calc(32vw * var(--pxToVw));
}

::-webkit-scrollbar-track {
    background: var(--white);
}

::-webkit-scrollbar-thumb {
    background: var(--lightgrey);
}*/
</style>

<style lang="scss">
.outer-zoom .inner-zoom [slider] > div > [progressbar] {
    background-color: var(--white);
}
</style>
