import localforage from 'localforage'

const GAMES = {
    NONE: 'NONE',
    HANDDRAWING: 'HANDDRAWING',
    MICROSCOPE: 'MICROSCOPE',
    SIMULATION: 'SIMULATION',
}

const getInitialState = () => {
    return {
        game: GAMES.NONE,
        item: null,
        microscopeViewedPositions: [],
        items: null,
    }
}
export default {
    namespaced: true,
    state: getInitialState(),
    mutations: {
        async microscopeState(state, request) {
            const object = state.microscopeViewedPositions.find(
                (x) => x.id === request.id,
            )
            console.log(object)
            if (object && object.positions) {
                object.positions.push(request.position)
                if (object.positions.length === 101) {
                    object.positions.shift()
                }
            } else {
                console.log(request)
                state.microscopeViewedPositions.push({
                    id: request.id,
                    positions: [request.position],
                })
            }
            await localforage.setItem(
                'microscopeViewedPositions',
                state.microscopeViewedPositions,
            )
        },
        resetState(state) {
            Object.assign(state, getInitialState())
        },
        setGame(state, game) {
            state.game = game
        },
        setItem(state, item) {
            state.item = item
        },
    },
    actions: {
        resetState({ commit }) {
            commit('resetState')
        },
        saveMicroscopeView({ commit }, request) {
            console.log(request)
            commit('microscopeState', request)
        },
        setGameAndItem({ commit }, { game, item }) {
            commit('setGame', game)
            commit('setItem', item)
        },
        async getMVPFromLocal({ state }) {
            console.log('scan local forage')
            await localforage
                .getItem('microscopeViewedPositions')
                .then((value) => {
                    console.log(value)
                    if (value) {
                        state.microscopeViewedPositions = value
                        return value
                    }
                })
                .catch(function (err) {
                    // This code runs if there were any errors
                    console.log(err)
                })
        },
    },
    getters: {
        getItem: (state) => {
            return state.item
        },
        getMicroscopeViewedPositions: (state) => (id) => {
            if (state.microscopeViewedPositions.length === 0) {
                return null
            }
            const viewedPositions = state.microscopeViewedPositions.find(
                (x) => x.id === id,
            )
            return viewedPositions ? viewedPositions : null
        },
    },
}

export { GAMES }
