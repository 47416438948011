const getInitialState = () => {
    return {
        game: null,
        item: null,
        openInfoModalOnSide: null,
        //für s1 und evtl. letzte Seite
        isSinglePage: null,
        svgWidth: null,
        svgHeight: null,
        userToken: null,
    }
}

export default {
    namespaced: true,
    state: getInitialState(),
    mutations: {
        resetState(state) {
            const saveToken = state.userToken
            Object.assign(state, getInitialState())
            state.userToken = saveToken
        },
        setGame(state, game) {
            state.game = game
        },
        setItem(state, item) {
            state.item = item
        },
        setOpenInfoModalOnSide(state, direction) {
            state.openInfoModalOnSide = direction
        },
        setIsSinglePage(state, v) {
            state.isSinglePage = v
        },
        setWidth(state, width) {
            state.svgWidth = width
        },
        setHeight(state, height) {
            state.svgHeight = height
        },
        setUserToken(state, token) {
            state.userToken = token
        },
    },
    actions: {
        resetState({ commit }) {
            commit('resetState')
        },
        setGameAndItem({ commit }, { game, item }) {
            commit('setGame', game)
            commit('setItem', item)
        },
        setOpenInfoModalOnSide({ commit }, direction) {
            commit('setOpenInfoModalOnSide', direction)
        },
        setIsSinglePage({ commit }, v) {
            commit('setIsSinglePage', v)
        },
        setSVGvalues({ commit }, v) {
            commit('setWidth', v.width)
            commit('setHeight', v.height)
        },
        setUserToken({ commit }, token) {
            commit('setUserToken', token)
        },
    },
    getters: {
        getItem: (state) => {
            return state.item
        },
        getGame: (state) => {
            return state.game
        },
        getIsLoggedIn: (state) => {
            return state.userToken !== null
        },
    },
}
