<template>
    <box-wrapper
        id="iiif-metadata"
        class="position-fixed d-flex flex-column"
        :class="openModal ? '' : 'hide'"
        :title="label"
    >
        <template #button>
            <div
                class="fowi-btn fowi-btn-primary fowi-btn-sq48"
                @click="$emit('close')"
            >
                <font-awesome-icon :icon="['far', 'times']" size="2x" />
            </div>
        </template>
        <template #content>
            <div class="d-flex flex-column p-3">
                <div
                    v-for="(metadataPart, index) in metadata"
                    :key="'metadata-' + index"
                >
                    <h5 class="m-0 mb-1 p-0">
                        {{ metadataPart.label }}
                    </h5>
                    <p class="m-0 p-0 pb-3">
                        <span
                            v-if="
                                typeof metadataPart.value === 'string' &&
                                metadataPart.label !== 'PURL'
                            "
                            v-html="metadataPart.value"
                        />
                        <qrcode-vue
                            v-else-if="
                                typeof metadataPart.value === 'string' &&
                                metadataPart.label === 'PURL'
                            "
                            :value="metadataPart.value"
                            :size="150"
                            level="M"
                            background="#818181"
                            foreground="#ffffff"
                        />
                        <template
                            v-else-if="typeof metadataPart.value === 'object'"
                        >
                            <span
                                v-for="part in metadataPart.value"
                                :key="'part-' + part.toString()"
                                v-html="part"
                            >
                                {{ part }}
                            </span>
                        </template>
                    </p>
                </div>
            </div>
        </template>
    </box-wrapper>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import BoxWrapper from '@/components/global/BoxWrapper'

export default {
    name: 'IiifMetadata',
    components: {
        BoxWrapper,
        QrcodeVue,
    },
    props: {
        metadata: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        openModal: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/global';

#iiif-metadata {
    transition: all ease-in-out 250ms;
    overflow: hidden;
    top: calc(81vw * var(--pxToVw));
    right: calc(50vw * var(--pxToVw));
    z-index: 1;
    opacity: 1;
    pointer-events: all;
    max-height: calc(917vw * var(--pxToVw));

    &.hide {
        pointer-events: none;
        opacity: 0;
        right: -25%;
    }
}
</style>

<style lang="scss">
@import '../../../assets/scss/global';

#iiif-metadata {
    a {
        color: var(--secondary);
    }
}
</style>
