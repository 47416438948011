<template>
    <div v-if="collectedItems" class="h-100 w-100">
        <login-timer
            :unit="$route.name"
            :user-uuid="$store.getters.getUserAtUnit($route.name).uuid"
        />
        <transition name="fade" mode="out-in">
            <div
                v-show="$store.state.atelier.game === GAMES.NONE"
                class="h-100 w-100"
            >
                <h1 class="station-title">
                    {{ $t('atelier-station-title') }}
                </h1>
                <p class="station-description">
                    {{ $t('atelier-station-description') }}
                </p>
                <fowi-drag-and-drop-zone
                    :items="collectedItems"
                    :zone-one-items="handdrawingItemsPI"
                    :zone-two-items="microscopeItemsPI"
                    :zone-three-items="simulationItemsPI"
                    @dropZone="droppedItemAndZone"
                >
                    <div slot="dropZone1">
                        <h2>{{ $t('atelier-handdrawing-title') }}</h2>
                        <p>{{ $t('atelier-handdrawing-description') }}</p>
                    </div>
                    <div slot="dropZone2">
                        <h2>{{ $t('atelier-microscope-title') }}</h2>
                        <p>{{ $t('atelier-microscope-description') }}</p>
                    </div>
                    <div slot="dropZone3">
                        <h2>{{ $t('atelier-simulation-title') }}</h2>
                        <p>{{ $t('atelier-simulation-description') }}</p>
                    </div>
                </fowi-drag-and-drop-zone>
            </div>
        </transition>
        <transition name="fade" mode="out-in">
            <div
                v-if="$store.state.atelier.game !== GAMES.NONE"
                class="h-100 w-100"
            >
                <h1 class="station-title">{{ $t(gameTitle) }}</h1>
                <p
                    v-if="gameFeedback"
                    class="station-feedback"
                    v-html="$t(gameFeedback)"
                />
                <div
                    class="fowi-btn fowi-btn-primary fowi-btn-sq48 fowi-btn-close"
                    @click="close"
                >
                    <font-awesome-icon :icon="['far', 'times']" size="3x" />
                </div>
                <handdrawing
                    v-if="$store.state.atelier.game === GAMES.HANDDRAWING"
                    @close="close"
                />
                <microscope
                    v-else-if="$store.state.atelier.game === GAMES.MICROSCOPE"
                />
                <simulation
                    v-else-if="$store.state.atelier.game === GAMES.SIMULATION"
                />
            </div>
        </transition>
        <div
            v-if="randomUser && $store.state.atelier.game === GAMES.NONE"
            class="logout-btn m-2"
            @click="logoutFromUnit('atelier')"
        >
            <i class="fa fa-sign-out"></i>
        </div>
    </div>
</template>

<script>
import { GAMES } from '@/store/stations/3.005_atelier/atelier'
import Handdrawing from './handdrawing/Handdrawing'
import Microscope from './microscope/Microscope'
import Simulation from './Simulation'
import api from '../../../mixins/api'
import FowiDragAndDropZone from '@/components/global/FowiDragAndDropZone'
import LoginTimer from '@/components/global/LoginTimer'

export default {
    name: 'AtelierGame',
    components: {
        LoginTimer,
        FowiDragAndDropZone,
        Simulation,
        Microscope,
        Handdrawing,
    },
    data() {
        return {
            game: null,
            GAMES,
            heartbeatIntervalId: null,
            draggedItem: null,
            positions: {},
        }
    },
    computed: {
        getContent() {
            return {}
        },
        gameTitle() {
            if (this.$store.state.atelier.game !== GAMES.NONE) {
                return (
                    'atelier-' +
                    this.$store.state.atelier.game.toLowerCase() +
                    '-title'
                )
            }
            return 'none'
        },
        gameFeedback() {
            if (this.$store.state.atelier.game === GAMES.SIMULATION) {
                return 'atelier-simulation-feedback'
            }
            return null
        },
        collectedItems() {
            const items = this.$store.getters.getUserAtUnit(
                this.$route.name,
            ).collection
            return items ? items : null
        },
        collectedItemsPI() {
            const items = this.collectedItems
            return items ? items.flatMap((x) => [x.id]) : null
        },
        simulationItemsPI() {
            const simulationItems = this.collectedItems?.filter((x) => {
                if (x.nfcItem?.units) {
                    const units = JSON.parse(x.nfcItem.units)
                    let found = false
                    if (units.includes('atelierFlowpattern')) {
                        found = true
                    }
                    if (!x.thumbnailUrl) {
                        found = false
                    }
                    return found
                }
            })
            return simulationItems.flatMap((x) => [x.id])
        },
        microscopeItemsPI() {
            const microscopeItems = this.collectedItems?.filter((x) => {
                if (x.nfcItem?.units) {
                    const units = JSON.parse(x.nfcItem.units)
                    let found = false
                    if (units.includes('atelierMicroscope')) {
                        found = true
                    }
                    if (!x.thumbnailUrl) {
                        found = false
                    }
                    return found
                }
            })
            return microscopeItems.flatMap((x) => [x.id])
        },
        handdrawingItemsPI() {
            const handdrawingItems = this.collectedItems?.filter((x) => {
                if (x.nfcItem?.units) {
                    const units = JSON.parse(x.nfcItem.units)
                    let found = false
                    if (units.includes('atelierHanddrawing')) {
                        found = true
                    }
                    if (!x.thumbnailUrl) {
                        found = false
                    }
                    return found
                }
            })
            return handdrawingItems.flatMap((x) => [x.id])
        },
        currentUser() {
            return this.$store.getters.getUserAtUnit(this.$route.name)
        },
        randomUser() {
            return (
                this.$store.getters.getUserAtUnit(this.$route.name).settings
                    ?.no_app === true
            )
        },
    },
    mounted() {
        this.$store.dispatch('atelier/resetState')
        /*
    this.heartbeatIntervalId = setInterval(() => {
        const user = this.$store.getters.getUserAtUnit(this.$route.name)
        api.twoavy.stayAtUnit(user.uuid, user.unit)
    }, 20 * 1000)
    */
        let userLanguage = this.currentUser.settings.language
        if (!this.randomUser) {
            this.$userLog.saveUserLog('play_with_app ', this.currentUser.uuid, {
                language: userLanguage,
            })
        } else {
            this.$userLog.saveUserLog(
                'play_without_app ',
                this.currentUser.uuid,
                {
                    language: userLanguage,
                },
            )
        }
    },
    beforeDestroy() {
        // clearInterval(this.heartbeatIntervalId)
    },
    methods: {
        logoutFromUnit(userId) {
            api.twoavy.logoutFromUnit(userId)
        },
        close() {
            console.log(this.$store.state.atelier)
            this.$userLog.saveUserLog(
                'atelier_close_game',
                this.currentUser.uuid,
                {
                    game: this.$store.state.atelier.game.toLowerCase(),
                },
            )
            this.$store.dispatch('atelier/resetState')
        },
        droppedItemAndZone(event) {
            let game = null
            switch (event.zone) {
                case 'drop-zone-1':
                    game = GAMES.HANDDRAWING
                    break
                case 'drop-zone-2':
                    game = GAMES.MICROSCOPE
                    break
                case 'drop-zone-3':
                    game = GAMES.SIMULATION
                    break
            }
            if (game) {
                const item = event.item
                this.$store.dispatch('atelier/setGameAndItem', { game, item })

                this.$userLog.saveUserLog(
                    'atelier_start_game',
                    this.currentUser.uuid,
                    {
                        game: game.toLowerCase(),
                    },
                )
            }
        },
    },
}
</script>

<style scoped lang="scss">
.close-button {
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
}

.logout-btn {
    position: absolute;

    z-index: 9999;

    background-color: #e01d34;
    color: white !important;

    bottom: 10px;
    left: 10px;
    width: calc(70vw * var(--pxToVw));
    height: calc(70vw * var(--pxToVw));
    border-radius: 10px;
    border: none;
    padding: 12px;
    margin: 20px;
    font-size: 32px;
    text-align: center;
    transform: rotate(90deg);

    //svg {
    //    width: calc(32vw * var(--pxToVw)) !important;
    //    height: calc(32vw * var(--pxToVw)) !important;
    //}
}
</style>
