<template>
    <div ref="matter" class="items">
        <div
            v-for="item in $store.getters
                .getUserAtUnit(getUnitKey(index))
                .collection.filter((item) => item.goettItem)
                // don't show selected items, they are visualized as cards
                .filter((item) => !isItemSelected(item))
                .filter((item) => itemHasNoDataForGame(item))"
            :key="item.goettItem.PI"
            class="item circle-image"
            :style="getItemStyle(item)"
        >
            <img
                :class="{
                    selected: isItemSelected(item),
                }"
                :src="item.thumbnailUrl"
                @click="toggleSelectedItem(item)"
            />
            <font-awesome-icon
                v-if="isItemSelected(item)"
                :icon="['far', 'times']"
                size="3x"
                class="clickable"
                @click="deselectItem(item)"
            />
        </div>
    </div>
</template>

<script>
import api, { FIELDS } from '../../../mixins/api'
import { CATEGORIES, STEPS } from '../../../store/stations/3.009_market/market'
import UnloadAnimation from '../../../utils/UnloadAnimation'
import { randomNumber } from '../../../utils/math'
import { getMaxLengthInMm } from '@/utils/items.js'

export default {
    name: 'Items',
    props: {
        index: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return { api, STEPS, positions: {} }
    },
    computed: {
        selectedItems() {
            return this.$store.getters['market/getSelectedItems'](this.index)
        },
    },
    mounted() {
        this.unloadAnimation = new UnloadAnimation(
            this.$refs.matter,
            (positions) => (this.positions = positions),
            { spread: 0.2 },
        )
        const element = this.$refs.matter
        const rect = element.getBoundingClientRect()

        // const startPos = null
        let force = { x: 0.000001, y: 0.000001 }
        this.$store.getters
            .getUserAtUnit(this.getUnitKey(this.index))
            .collection.filter((item) => item.goettItem)
            .forEach((item) => {
                let startPos = {
                    x: rect.width / 2 + randomNumber(-100, 100),
                    y: rect.height / 2 + randomNumber(-100, 100),
                }
                switch (this.index) {
                    case 0: {
                        startPos = {
                            x: (rect.width / 4) * 3 + randomNumber(-100, 100),
                            y: (rect.height / 4) * 3 + randomNumber(-100, 100),
                        }
                        force = { ...force, x: -force.x, y: force.y }
                        break
                    }
                    case 1: {
                        startPos = {
                            x: rect.width / 4 + randomNumber(-100, 100),
                            y: (rect.height / 4) * 3 + randomNumber(-100, 100),
                        }
                        force = { ...force, x: -force.x, y: force.y }
                        break
                    }
                    case 2: {
                        startPos = {
                            x: rect.width / 4 + randomNumber(-100, 100),
                            y: (rect.height / 4) * 3 + randomNumber(-100, 100),
                        }
                        force = { ...force, x: force.x, y: -force.y }
                        break
                    }
                    case 3: {
                        startPos = {
                            x: (rect.width / 4) * 3 + randomNumber(-100, 100),
                            y: (rect.height / 4) * 3 + randomNumber(-100, 100),
                        }
                        force = { ...force, x: -force.x, y: -force.y }
                        break
                    }
                }
                this.unloadAnimation.addBodyWithForce(
                    item.goettItem.PI,
                    startPos,
                    force,
                    50,
                )
            })
        this.unloadAnimation.start()

        this.saveMetrics(this.index)
    },
    beforeDestroy() {
        this.unloadAnimation.stop()
    },
    methods: {
        saveMetrics(user) {
            console.log(user)
            const currentUser = this.$store.getters.getUserAtUnit(
                'market_' + user,
            )

            const userZone = user + 1
            const randomUser = currentUser?.settings?.no_app === true
            let userLanguage = currentUser.settings.language

            if (!randomUser) {
                this.$userLog.saveUserLog(
                    'play_with_app ',
                    currentUser.uuid,
                    {
                        language: userLanguage,
                    },
                    userZone,
                )
            } else {
                this.$userLog.saveUserLog(
                    'play_without_app ',
                    currentUser.uuid,
                    {
                        language: userLanguage,
                    },
                    userZone,
                )
            }
        },
        toggleSelectedItem(item) {
            this.$store.dispatch('market/toggleSelectedItem', {
                userIndex: this.index,
                item,
            })
        },
        getUnitKey(userIndex) {
            return `market_${userIndex}`
        },
        getItemStyle(item) {
            const position = this.positions[item.goettItem.PI]
            if (!position) {
                // TODO: smarter fallback, draw offscreen for now
                return {
                    position: 'absolute',
                    left: `${-200}px`,
                    top: `${-200}px`,
                    zIndex: 1,
                }
            }
            return {
                position: 'absolute',
                transform: `translateX(calc(-50% + ${position.x}px)) translateY(calc(-50% + ${position.y}px))`,
                zIndex: 1,
            }
        },
        isItemSelected(item) {
            return this.selectedItems.find(
                (i) => i?.goettItem?.PI === item.goettItem.PI,
            )
        },
        itemHasNoDataForGame(item) {
            const category = this.$store.state.market.categories[this.index]
            switch (category) {
                case CATEGORIES.SIZE:
                    return !!getMaxLengthInMm(item.goettItem)
                case CATEGORIES.YEAR:
                    return !!item.goettItem[FIELDS.YEAR]
                default:
                    return true
            }
        },
        deselectItem(item) {
            this.$store.dispatch('market/toggleSelectedItem', {
                userIndex: this.index,
                item,
            })
        },
    },
}
</script>

<style scoped lang="scss">
.items {
    pointer-events: all;
    z-index: 1;

    .item {
        width: 128px;
        height: 128px;
        display: flex;
        position: absolute;

        img {
            transform: scale(1.2);
            object-fit: cover;
            width: 100%;
            position: relative;

            &.selected {
                border: 10px solid var(--green);
                border-radius: 25px;
            }
        }
    }
}
</style>
