<template>
    <div class="h-100 w-100">
        <transition name="fade" mode="in-out">
            <desk-new-annotation-overlay
                v-if="annotation"
                :annotation-model="annotation"
                :new-annotation="newAnnotation"
                @add-annotation="getAnnotations"
                @update-annotation="getAnnotations"
                @delete-annotation="getAnnotations"
                @cancel-annotation="annotation = null"
            />
        </transition>
        <div ref="animateContainer">
            <iiif-viewer
                v-if="getManifest"
                :manifest="getManifest"
                :annotations="annotations"
            />
        </div>
    </div>
</template>

<script>
import IiifViewer from '../../global/iiifViewer/IiifViewer'
import DeskNewAnnotationOverlay from './DeskNewAnnotationOverlay'
import api from '@/mixins/api'

export default {
    name: 'DeskAnnotation',
    components: { DeskNewAnnotationOverlay, IiifViewer },
    data() {
        return {
            annotations: [],
            annotation: null,
            newAnnotation: false,
        }
    },
    computed: {
        getManifest() {
            return this.$store.getters['desk/getItem'].manifest
                ? this.$store.getters['desk/getItem'].manifest
                : null
        },
    },
    async mounted() {
        this.$root.$on('open_annotation', this.openAnnotation)
        await this.getAnnotations()
    },
    beforeDestroy() {
        this.$root.$off('open_annotation')
    },
    methods: {
        openAnnotation(ev) {
            setTimeout(() => {
                this.annotation = ev.annotation
                this.newAnnotation = ev.new
            }, 250)
        },
        async getAnnotations() {
            this.annotation = null
            this.annotations = (
                await api.twoavy.getDeskAnnotations()
            )?.data?.data?.filter(
                (x) => x.externalId === this.getManifest['@id'],
            )
            // console.log(this.annotations)
        },
    },
}
</script>
