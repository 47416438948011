<template>
    <box-wrapper
        id="iiif-structures"
        class="position-fixed d-flex flex-column"
        :class="openModal ? '' : 'hide'"
        :title="$t('structures')"
    >
        <template #button>
            <div
                class="fowi-btn fowi-btn-primary fowi-btn-sq48"
                @click="$emit('close')"
            >
                <font-awesome-icon :icon="['far', 'times']" size="2x" />
            </div>
        </template>
        <template #content>
            <div class="d-flex flex-column p-3">
                <div
                    v-for="(structurePart, index) in structures"
                    :key="'structures-' + index"
                >
                    <h5
                        class="m-0 mb-2 p-0"
                        :class="structurePart.within ? 'ml-4' : ''"
                        @click="setNewPage(structurePart)"
                    >
                        <span
                            v-if="
                                structurePart.canvases.includes(
                                    currentPages[0],
                                ) ||
                                structurePart.canvases.includes(currentPages[1])
                            "
                            class="marker"
                        />
                        <font-awesome-icon
                            v-else
                            :icon="['far', 'bookmark']"
                            size="sm"
                        />
                        {{ structurePart.label }}
                    </h5>
                </div>
            </div>
        </template>
    </box-wrapper>
</template>

<script>
import BoxWrapper from '@/components/global/BoxWrapper'

export default {
    name: 'IiifStructures',
    components: { BoxWrapper },
    props: {
        currentPages: {
            type: Array,
            default: () => [],
        },
        structures: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: '',
        },
        openModal: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        setNewPage(structurePart) {
            if (structurePart.canvases.length === 1) {
                this.$emit('setNewPage', structurePart.canvases[0])
            } else {
                this.$emit('setNewPage', structurePart.canvases[1])
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/global';

#iiif-structures {
    transition: all ease-in-out 250ms;
    overflow: hidden;
    top: calc(81vw * var(--pxToVw));
    right: calc(50vw * var(--pxToVw));
    z-index: 1;
    opacity: 1;
    pointer-events: all;
    max-height: calc(917vw * var(--pxToVw));

    &.hide {
        pointer-events: none;
        opacity: 0;
        right: -25%;
    }

    .marker {
        display: inline-block;
        position: relative;
        height: 1rem;
        width: calc(3vw * var(--pxToVw));
        margin-left: calc(-7vw * var(--pxToVw));
        background-color: var(--secondary);
    }
}
</style>
