<template>
    <div class="iiif-viewer-page position-relative">
        <img
            v-if="prefixImageUrl && appendImageUrl"
            :src="prefixImageUrl + height + appendImageUrl"
            @load="getSvgSize"
        />
        <div
            v-if="svgContainer"
            class="annotation-box"
            :class="getPageId % 2 ? 'even' : 'uneven'"
        >
            <svg
                :width="svgContainer.width"
                :height="svgContainer.height"
                :viewBox="
                    '0 0 ' + svgContainer.width + ' ' + svgContainer.height
                "
                xmlns="http://www.w3.org/2000/svg"
                @touchstart="startDrawRect"
                @touchmove="moveDrawRect"
                @touchend="endDrawRect"
            >
                <template v-for="(annotation, index) in annotations">
                    <rect
                        :key="'svg-annotation-rect-' + index"
                        :x="
                            $store.state.desk.svgWidth *
                            annotation.params.position.x
                        "
                        :y="
                            $store.state.desk.svgHeight *
                            annotation.params.position.y
                        "
                        :width="
                            $store.state.desk.svgWidth *
                            annotation.params.size.width
                        "
                        :height="
                            $store.state.desk.svgHeight *
                            annotation.params.size.height
                        "
                        :rx="roundCorner"
                        :ry="roundCorner"
                        fill="transparent"
                        stroke="white"
                        stroke-width="2"
                        @touchstart.stop="openAnnotation(annotation)"
                        @click.stop="openAnnotation(annotation)"
                    />
                    <line
                        :key="'svg-annotation-line-' + index"
                        :x1="
                            getPageId % 2
                                ? annotation.params.position.x *
                                      $store.state.desk.svgWidth +
                                  annotation.params.size.width *
                                      $store.state.desk.svgWidth
                                : annotation.params.position.x *
                                  $store.state.desk.svgWidth
                        "
                        :y1="
                            annotation.params.position.y *
                                $store.state.desk.svgHeight +
                            (annotation.params.size.height *
                                $store.state.desk.svgHeight) /
                                2
                        "
                        :x2="
                            getPageId % 2
                                ? svgContainer.width + circleDiameter / 2
                                : -circleDiameter / 2
                        "
                        :y2="
                            annotation.params.position.y *
                                $store.state.desk.svgHeight +
                            (annotation.params.size.height *
                                $store.state.desk.svgHeight) /
                                2
                        "
                        stroke="white"
                    />
                    <rect
                        :key="'svg-annotation-click-rect-' + index"
                        class="clickable"
                        :x="
                            getPageId % 2
                                ? svgContainer.width + circleDiameter / 2
                                : -(circleDiameter + circleDiameter / 2)
                        "
                        :y="
                            $store.state.desk.svgHeight *
                                annotation.params.position.y +
                            ($store.state.desk.svgHeight *
                                annotation.params.size.height) /
                                2 -
                            circleDiameter / 2
                        "
                        fill="white"
                        :width="circleDiameter"
                        :height="circleDiameter"
                        @touchstart.stop="openAnnotation(annotation)"
                        @click.stop="openAnnotation(annotation)"
                    />
                    <svg
                        :key="'svg-annotation-icon-' + index"
                        class="pointer-events-none"
                        viewBox="0 0 100 100"
                        :x="
                            getPageId % 2
                                ? svgContainer.width +
                                  (circleDiameter -
                                      (circleDiameter * faIconScaleFactor) / 2)
                                : -(
                                      circleDiameter +
                                      (circleDiameter * faIconScaleFactor) / 2
                                  )
                        "
                        :y="
                            $store.state.desk.svgHeight *
                                annotation.params.position.y +
                            ($store.state.desk.svgHeight *
                                annotation.params.size.height) /
                                2 -
                            (circleDiameter / 2) * faIconScaleFactor
                        "
                        :width="circleDiameter * faIconScaleFactor"
                        :height="circleDiameter * faIconScaleFactor"
                    >
                        <font-awesome-icon
                            v-if="annotation.type === 'url'"
                            class="fa-icon"
                            :icon="['far', 'external-link']"
                        />
                        <font-awesome-icon
                            v-else-if="annotation.type === 'object_link'"
                            class="fa-icon"
                            :icon="['far', 'object-group']"
                        />
                        <font-awesome-icon
                            v-else-if="annotation.type === 'text'"
                            class="fa-icon"
                            :icon="['far', 'comment']"
                        />
                        <font-awesome-icon
                            v-else
                            class="fa-icon"
                            :icon="['far', 'comment-lines']"
                        />
                    </svg>
                </template>

                <template v-if="annotationSvg.rect">
                    <rect
                        :x="annotationSvg.rect.x"
                        :y="annotationSvg.rect.y"
                        :width="annotationSvg.rect.width"
                        :height="annotationSvg.rect.height"
                        :rx="roundCorner"
                        :ry="roundCorner"
                        fill="transparent"
                        stroke="red"
                    />
                </template>
            </svg>
        </div>
        <transition name="fade" mode="out-in">
            <div
                v-if="showTranscription && transcription"
                class="transcription-box"
                :class="getPageId % 2 ? 'even' : 'uneven'"
            >
                <p v-html="transcription" />
            </div>
        </transition>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'IiifViewerPage',
    props: {
        manifestId: {
            type: String,
            default: '',
            required: true,
        },
        manifestPage: {
            type: Object,
            default: () => {},
            required: true,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
            default: 700,
        },
        showTranscription: {
            type: Boolean,
            default: false,
        },
        transcription: {
            type: String,
            default: '',
        },
        annotations: {
            type: Array,
            default: () => [],
        },
        customizedUrl: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            svgContainer: null,
            circleDiameter: 48,
            faIconScaleFactor: 0.65,
            roundCorner: 10,
            annotationSvg: {},
            drawingRect: false,
            startPos: { x: 0, y: 0 },
            prefixImageUrl: '',
            appendImageUrl: '',
        }
    },
    computed: {
        getPageId() {
            return parseInt(this.manifestPage['@id'].slice(-8))
        },
    },
    mounted() {
        this.getImageUrl()
    },
    methods: {
        getAnnotationIconType(type) {
            console.log(type)
            switch (type) {
                case 'url':
                    return "['far', 'external-link']"
                case 'object_link':
                    return "['far', 'object-group']"
                case 'text':
                    return "['far', 'comment']"
                default:
                    return "['far', 'comment-lines']"
            }
        },
        getImageUrl() {
            if (this.customizedUrl) {
                const id = this.manifestPage.images[0].resource.service['@id']
                const imageId = id.split('/')[id.split('/').length - 1]
                this.prefixImageUrl = this.customizedUrl + imageId + '/full/,'
                this.appendImageUrl = '/0/nofold+transparentbg.png'
            } else {
                this.prefixImageUrl =
                    this.manifestPage.images[0].resource.service['@id'] +
                    '/full/,'
                this.appendImageUrl = '/0/default.jpg'
            }
        },
        getSvgSize(ev) {
            const svgContainer = {
                width: ev.target.getBoundingClientRect().width,
                height: ev.target.getBoundingClientRect().height,
            }
            this.$store.dispatch('desk/setSVGvalues', svgContainer)
            this.svgContainer = svgContainer
        },
        openAnnotation(annotation) {
            if (this.getPageId === 1) {
                this.$store.dispatch('desk/setIsSinglePage', true)
            } else {
                this.$store.dispatch('desk/setIsSinglePage', false)
            }

            if (annotation) {
                this.$store.dispatch(
                    'desk/setOpenInfoModalOnSide',
                    this.getPageId % 2 ? 'left' : 'right',
                )

                this.$root.$emit('open_annotation', {
                    annotation: annotation,
                    new: false,
                })
            }
        },
        startDrawRect(ev) {
            console.log('start')
            if (this.drawingRect || !this.editMode) {
                // console.log('forbidden')
                return
            }

            if (ev.type === 'touchstart') {
                // console.log('draw')
                this.drawingRect = true
                const target = ev.touches[0].target.getBoundingClientRect()
                this.startPos = {
                    x: ev.touches[0].pageX - target.x,
                    y: ev.touches[0].pageY - target.y,
                }
                const rect = {
                    x: this.startPos.x,
                    y: this.startPos.y,
                    width: 0,
                    height: 0,
                }
                Vue.set(this.annotationSvg, 'rect', rect)
            }
        },
        moveDrawRect(ev) {
            if (ev.type === 'touchmove' && this.drawingRect) {
                const currentRect = this.annotationSvg.rect
                const target = ev.touches[0].target.getBoundingClientRect()
                const width = ev.touches[0].pageX - target.x - this.startPos.x
                const height = ev.touches[0].pageY - target.y - this.startPos.y
                const newRect = {
                    x: width >= 0 ? currentRect.x : this.startPos.x + width,
                    y: height >= 0 ? currentRect.y : this.startPos.y + height,
                    width: Math.abs(width),
                    height: Math.abs(height),
                }
                Vue.set(this.annotationSvg, 'rect', newRect)
            }
        },
        endDrawRect(ev) {
            if (ev.type === 'touchend' && this.drawingRect) {
                if (
                    this.annotationSvg['rect'].width > 20 &&
                    this.annotationSvg['rect'].height > 15
                ) {
                    const newAnnotation = {
                        imageUrl:
                            this.manifestPage.images[0].resource.service['@id'],
                        externalId: this.manifestId,
                        params: {
                            size: {
                                width:
                                    ((100 / this.$store.state.desk.svgWidth) *
                                        this.annotationSvg['rect'].width) /
                                    100,
                                height:
                                    ((100 / this.$store.state.desk.svgHeight) *
                                        this.annotationSvg['rect'].height) /
                                    100,
                            },
                            position: {
                                x:
                                    ((100 / this.$store.state.desk.svgWidth) *
                                        this.annotationSvg['rect'].x) /
                                    100,
                                y:
                                    ((100 / this.$store.state.desk.svgHeight) *
                                        this.annotationSvg['rect'].y) /
                                    100,
                            },
                        },
                    }
                    this.$store.dispatch(
                        'desk/setIsSinglePage',
                        this.getPageId === 1,
                    )
                    this.$store.dispatch(
                        'desk/setOpenInfoModalOnSide',
                        this.getPageId % 2 ? 'left' : 'right',
                    )
                    this.$root.$emit('open_annotation', {
                        annotation: newAnnotation,
                        new: true,
                    })
                }
                this.drawingRect = false
                this.annotationSvg = {}
                this.startPos = { x: 0, y: 0 }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/global';

.iiif-viewer-page {
    .annotation-box,
    .transcription-box {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .transcription-box {
        display: flex;
        overflow: hidden;

        .btn-fowi-toggle-transcription {
            position: absolute;
            top: calc(10vw * var(--pxToVw));
            right: calc(25vw * var(--pxToVw));
        }

        p {
            overflow-x: auto;
            flex-grow: 1;
            margin: calc(0vw * var(--pxToVw));
            padding: calc(25vw * var(--pxToVw)) calc(50vw * var(--pxToVw));
            background-color: rgba(0, 0, 0, 0.35);
            font-size: calc(14vw * var(--pxToVw));
        }
    }

    .annotation-box {
        svg {
            overflow-x: overlay;

            svg {
                transform: scale(0.1);

                .fa-icon {
                    color: var(--primary);
                }
            }
        }

        &.uneven {
            svg {
                transform: translateX(150px);
            }

            left: calc(-150vw * var(--pxToVw));
        }

        &.even {
            right: calc(-150vw * var(--pxToVw));
        }
    }
}
</style>
