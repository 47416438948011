<template>
    <div v-if="shownItems.length > 0" class="relatedItems">
        <div class="header">
            <div class="text">
                {{ $t('cupboards_generator_relateditems_explaination') }}
            </div>
        </div>

        <div class="content">
            <div class="images-container">
                <div v-for="item in shownItems" :key="item.PI" class="item">
                    <img
                        :src="
                            $store.getters['items/getBlob']({
                                item,
                                width: 512,
                            })
                        "
                    />
                </div>
            </div>
            <div
                v-if="startIndex < relatedItems.length - 10"
                class="button clickable"
                @click="showNextItems"
            >
                <font-awesome-icon :icon="['far', 'sync']" />
            </div>
        </div>
    </div>
</template>
<script>
import api from '../../../../mixins/api'

export default {
    name: 'CupboardsGameGeneratorRelatedItemsByCategory',
    data() {
        return { api, relatedItems: [], shownItems: [], startIndex: 0 }
    },
    watch: {
        '$store.state.cupboardsGameGenerator.selectedProps': function (value) {
            this.startIndex = 0
            const selectedProps = JSON.parse(JSON.stringify(value))
            const relatedItems = JSON.parse(
                JSON.stringify(
                    this.$store.state.cupboardsGameGenerator.relatedItems,
                ),
            )
            const selectedCategory = JSON.parse(
                JSON.stringify(
                    this.$store.state.cupboardsGameGenerator.selectedCategory,
                ),
            )
            const prop = selectedProps[selectedCategory]
            const items = [...relatedItems[selectedCategory][prop]]
            this.relatedItems = items
            this.shownItems = items.splice(this.startIndex, 10)
        },
    },
    mounted() {},
    beforeDestroy() {},
    methods: {
        showNextItems() {
            if (this.startIndex < this.relatedItems.length - 10) {
                this.startIndex = this.startIndex + 10
            }
            this.shownItems = this.relatedItems.splice(this.startIndex, 10)
        },
    },
}
</script>
<style lang="scss" scoped>
.relatedItems {
    position: relative;
    width: 100%;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .header {
        display: flex;
        margin-bottom: 12px;
        .text {
            flex-grow: 1;
            font-size: 24px;
        }
    }
    .content {
        display: flex;

        .images-container {
            display: grid;
            /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
            grid-template-columns:
                minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)
                minmax(0, 1fr);
            grid-gap: 15px;

            place-items: start;
            flex-grow: 1;
            padding-right: 50px;
            /* margin: auto; */
            .item {
                width: 100%;
                aspect-ratio: 1;
                display: flex;
                justify-content: center;
                img {
                    object-fit: cover;
                    max-height: 100%;
                    max-width: 100%;
                }
            }
        }
        .button {
            padding: 8px;
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }
}
</style>
