<template>
    <div
        ref="container"
        class="marketGame fill-parent"
        :class="{ debug: debug }"
    >
        <!-- debug grid for position checking -->
        <div class="debugGrid pointer-events-none">
            <div class="left"></div>
            <div class="center"></div>
            <div class="right"></div>
            <div class="horizontal"></div>
        </div>
        <div
            v-for="userIndex in NUMBEROFUSERS"
            :key="`user-area-${userIndex - 1}`"
            :ref="`user-area-${userIndex - 1}`"
            :class="`user-area-${userIndex - 1}`"
            class="position-absolute user-area-size"
        >
            <user-login
                :id="userIndex"
                :key="Object.keys(getActiveUsers).length"
                :create-collection="createCollection"
                :unit="unit"
                :user="userIndex - 1"
                :user-logged-in="
                    $store.getters.getUserAtUnit('market_' + (userIndex - 1))
                "
                class="userLogin"
            ></user-login>
        </div>
        <template v-if="ready && Object.keys(getActiveUsers).length > 0">
            <login-timer
                v-for="(userIndex, name) in getActiveUsers"
                :key="`login-timer-${userIndex.uuid}`"
                :unit="`${$route.name}_${name}`"
                :user-uuid="userIndex.uuid"
                :active-area="$refs[`user-area-${name}`][0]"
            />
        </template>

        <!-- display texts in the back layer -->

        <div class="texts fill-parent pointer-events-none">
            <span
                v-for="userIndex in NUMBEROFUSERS"
                :key="`market-user-${userIndex - 1}`"
                class="relative"
            >
                <texts
                    v-if="
                        !userLoggedIn(userIndex - 1) ||
                        userItemsCount(userIndex - 1) >= 5
                    "
                    :ref="`texts-${userIndex - 1}`"
                    :class="`market-user market-user${userIndex - 1}`"
                    :index="userIndex - 1"
                    :user-language="userLanguage(userIndex - 1)"
                    :random-user="isRandomUser(userIndex - 1)"
                ></texts>
                <market-no-items-modal
                    v-else
                    :unit="getUnitKey(userIndex - 1)"
                    :language="userLanguage(userIndex - 1)"
                />
            </span>
        </div>

        <!-- all cards as well as place holder slots -->
        <div
            v-show="hasLoaded"
            class="cardsContainer fill-parent pointer-events-none"
        >
            <!-- card placeholder slots for each user -->
            <div
                v-for="index in NUMBEROFUSERS"
                :key="`placeholdersContainer-${index - 1}`"
                :ref="`placeholdersContainer_${index - 1}`"
                :class="`placeholdersContainer placeholdersContainer-${
                    index - 1
                }`"
                :style="getPlaceholdersContainerStyle(index - 1)"
            >
                <div class="placeholders">
                    <div
                        v-for="i in NUMBEROFSLOTS"
                        :key="'placeholder_' + i"
                        :ref="`placeholder_${index - 1}-${i - 1}`"
                        :class="`placeholder placeholder_${index - 1}-${i - 1}`"
                    ></div>
                </div>
                <!-- axis -->
                <axis
                    :key="`axis-${index - 1}`"
                    :user-index="index - 1"
                    :user-language="userLanguage(index - 1)"
                    :show="
                        $store.state.market.steps[index - 1] === STEPS.IDLE ||
                        $store.state.market.steps[index - 1] ===
                            STEPS.INTRODUCTION ||
                        $store.state.market.steps[index - 1] ===
                            STEPS.SELECTION ||
                        $store.state.market.steps[index - 1] === STEPS.SORT
                    "
                    :class="`axis axis-${index - 1}`"
                ></axis>
            </div>

            <!-- all cards -->
            <market-card
                v-for="item in $store.getters['market/getAllItems']"
                :key="`card-${item.uuid}`"
                :class="`card card-${item.uuid}`"
                :data-uuid="item.uuid"
                :item="item"
                :show-details="areDetailsShown(item)"
            ></market-card>

            <!-- selectable items for each user-->
            <template v-for="index in NUMBEROFUSERS">
                <items
                    v-if="areItemsShown(index - 1)"
                    :key="`items-${index - 1}`"
                    :class="[
                        `item items-${index - 1}`,
                        isRandomUser(index - 1) ? 'randomUser' : '',
                    ]"
                    :index="index - 1"
                ></items>
            </template>

            <!-- buttons for each user-->
            <template v-for="userIndex in NUMBEROFUSERS">
                <div
                    :key="`buttons-${userIndex - 1}`"
                    :class="`buttons buttons-${userIndex - 1}`"
                    :style="getButtonsStyle(userIndex - 1)"
                >
                    <div
                        v-if="
                            (isStep(userIndex - 1, STEPS.SORT) &&
                                $store.getters['market/getUserItemsCount'](
                                    userIndex - 1,
                                ) === NUMBEROFSLOTS) ||
                            isStep(userIndex - 1, STEPS.EXCHANGE) ||
                            isStep(userIndex - 1, STEPS.CONCLUSION) ||
                            isStep(userIndex - 1, STEPS.HIGHSCORE)
                        "
                        class="button clickable"
                        @click="onCheckIconClicked(userIndex - 1)"
                    >
                        <font-awesome-icon :icon="['far', 'check']" />
                        <p>{{ getButtonTexts(userIndex - 1) }}</p>
                    </div>
                    <template
                        v-if="
                            isStep(userIndex - 1, STEPS.FINISH) &&
                            !isRandomUser(userIndex - 1)
                        "
                    >
                        <div
                            class="button clickable"
                            @click="discardCollection(userIndex - 1)"
                        >
                            <font-awesome-icon :icon="['far', 'trash']" />
                            <p>
                                {{
                                    $t(
                                        'market-discard-collection',
                                        userLanguage(userIndex),
                                    )
                                }}
                            </p>
                        </div>
                        <div
                            class="button clickable"
                            @click="saveCollection(userIndex - 1)"
                        >
                            <font-awesome-icon
                                :icon="['far', 'cloud-upload']"
                            />
                            <p>
                                {{
                                    $t(
                                        'market-save-collection',
                                        userLanguage(userIndex),
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Texts from './MarketTexts.vue'
import MarketCard from './MarketCard.vue'
import Axis from './MarketAxis.vue'
import Items from './MarketItems.vue'
import {
    NUMBEROFSLOTS,
    NUMBEROFUSERS,
    STEPS,
} from '@/store/stations/3.009_market/market.js'
import { getDistance, mapNumber, randomNumber } from '@/utils/math.js'
import { stationsData } from '@/stationsData.js'
import interact from 'interactjs'
import _ from 'lodash'
import LoginTimer from '@/components/global/LoginTimer.vue'
import MarketNoItemsModal from '@/components/stations/3.009_market/MarketNoItemsModal.vue'
import UserLogin from '@/components/global/UserLogin.vue'
import api from '@/mixins/api'

let cleanupTimeoutId

export default {
    name: 'MarketGame',
    components: {
        UserLogin,
        MarketNoItemsModal,
        Axis,
        MarketCard,
        Texts,
        Items,
        LoginTimer,
    },
    data() {
        return {
            STEPS,
            NUMBEROFSLOTS,
            NUMBEROFUSERS,
            placeholderPositions: [],
            debug: Object.keys(this.$route.query).includes('debug'),
            currentlyDraggingUsers: [],
            stationData: stationsData.find(
                (station) => station.key === 'market',
            ),
            hasLoaded: false,
            ready: false,
            createCollection: [
                {
                    unit: 'market',
                    count: 20, //max 14
                },
            ],
            unit: 'market',
        }
    },
    computed: {
        areItemsShown() {
            return (userIndex) => {
                const userLoggedIn = this.$store.getters.getUserAtUnit(
                    this.getUnitKey(userIndex),
                )
                const step = this.$store.getters['market/getStep'](userIndex)

                return (
                    userLoggedIn &&
                    [STEPS.INTRODUCTION, STEPS.SELECTION].includes(step)
                )
            }
        },
        userLoggedIn() {
            return (userIndex) => {
                const userLoggedIn = this.$store.getters.getUserAtUnit(
                    this.getUnitKey(userIndex),
                )
                return !!userLoggedIn
            }
        },
        userItemsCount() {
            return (userIndex) => {
                const userLoggedIn = this.$store.getters.getUserAtUnit(
                    this.getUnitKey(userIndex),
                )
                if (userLoggedIn && userLoggedIn.collection) {
                    return userLoggedIn.collection.length
                }
                return 5
            }
        },
        getActiveUsers() {
            const activeUsers = {}
            for (
                let userIndex = 0;
                userIndex < this.NUMBEROFUSERS;
                userIndex++
            ) {
                const activeUser = this.$store.getters.getUserAtUnit(
                    `${this.$route.name}_${userIndex}`,
                )
                if (activeUser) {
                    activeUsers[userIndex] = activeUser
                }
            }
            return activeUsers
        },
    },
    watch: {
        // TODO: can this be moved to the store module
        '$store.state.users': {
            handler(value) {
                console.log(value)
                const cleanupDelayTimeInMs = 5000
                if (Object.keys(value).length === 0) {
                    cleanupTimeoutId = setTimeout(() => {
                        if (!this.$store.getters['isAUserLoggedIn']) {
                            this.setRandomCardPositions()
                        }
                    }, cleanupDelayTimeInMs)
                } else {
                    clearTimeout(cleanupTimeoutId)
                }

                for (
                    let userIndex = 0;
                    userIndex < this.NUMBEROFUSERS;
                    userIndex++
                ) {
                    // if user is idle and new user logged in at unit
                    if (
                        this.$store.getters['market/getStep'](userIndex) ===
                            STEPS.IDLE &&
                        value[this.getUnitKey(userIndex)]
                    ) {
                        // start the game
                        const lang = this.$store.getters.getUserAtUnit(
                            this.getUnitKey(userIndex),
                        ).settings.language
                        this.$store.dispatch('market/setLanguage', {
                            userIndex,
                            lang,
                        })
                        this.$store.dispatch('market/start', userIndex)
                    }
                    // if user was not idle and now has been logged out
                    else if (
                        this.$store.getters['market/getStep'](userIndex) !==
                            STEPS.IDLE &&
                        !value[this.getUnitKey(userIndex)]
                    ) {
                        this.$store.dispatch('market/resetUser', userIndex)
                    }
                }
            },
            deep: true,
        },
        '$store.state.market.selectedItems': {
            handler(value) {
                this.initDraggables()
                for (
                    let userIndex = 0;
                    userIndex < this.NUMBEROFUSERS;
                    userIndex++
                ) {
                    for (
                        let cardIndex = 0;
                        cardIndex < this.NUMBEROFSLOTS;
                        cardIndex++
                    ) {
                        const item = value[userIndex][cardIndex]
                        if (item) {
                            this.$nextTick(() => {
                                const card = document.querySelector(
                                    `.card-${item.uuid}`,
                                )
                                if (card) {
                                    // only set if not yet set
                                    if (!card.getAttribute('data-x')) {
                                        const style =
                                            this.getSelectedCardStyle(item)
                                        const transform = `translate(${style.x}px, ${style.y}px) rotate(${style.rotation}deg)`
                                        card.style.transform = transform
                                        card.setAttribute('data-x', style.x)
                                        card.setAttribute('data-y', style.y)
                                        if (
                                            card.getAttribute(
                                                'data-absolute-start-x',
                                            ) === null ||
                                            card.getAttribute(
                                                'data-absolute-start-y',
                                            ) === null
                                        ) {
                                            card.setAttribute(
                                                'data-absolute-start-x',
                                                style.x,
                                            )
                                            card.setAttribute(
                                                'data-absolute-start-y',
                                                style.y,
                                            )
                                        }
                                        card.setAttribute(
                                            'data-angle',
                                            style.rotation,
                                        )
                                    }
                                }
                            })
                        }
                    }
                }
            },
            deep: true,
        },
        '$store.state.market.replacedItems': {
            handler(value) {
                console.log('market store update')
            },
            immediate: true,
            deep: true,
        },
    },
    async mounted() {
        this.setPlaceholderPositions()
        await this.$store.dispatch('market/init')
        this.hasLoaded = true

        this.setRandomCardPositions()
        this.initDraggables()
        this.initDropzones()
        this.$nextTick(() => {
            this.ready = true
        })
    },
    beforeDestroy() {
        interact('.card').unset()
        interact('.placeholder').unset()
    },
    methods: {
        isRandomUser(userIndex) {
            return (
                this.$store.getters.getUserAtUnit('market_' + userIndex)
                    ?.settings?.no_app === true
            )
        },
        userLanguage(userIndex) {
            return this.$store.getters['market/getUserLanguage'](userIndex)
        },
        getButtonTexts(userIndex) {
            switch (this.$store.getters['market/getStep'](userIndex)) {
                case STEPS.SORT:
                    return this.$t(
                        'reveal-values',
                        this.userLanguage(userIndex),
                    )
                case STEPS.EXCHANGE:
                    return this.$t(
                        'market-conclusion',
                        this.userLanguage(userIndex),
                    )
                case STEPS.CONCLUSION:
                    return this.$t(
                        'market-show-highscore',
                        this.userLanguage(userIndex),
                    )
                case STEPS.HIGHSCORE:
                    return this.$t(
                        'market-end-selection',
                        this.userLanguage(userIndex),
                    )
                default:
                    return ''
            }
        },
        initDraggables() {
            interact('.card').unset()
            interact('.card').draggable({
                inertia: true,
                modifiers: [
                    interact.modifiers.restrictRect({
                        restriction: 'parent',
                        endOnly: true,
                    }),
                ],
                autoScroll: false,

                listeners: {
                    move: this.onCardMove,
                    end: this.onCardMoveEnd,
                },
            })
        },
        initDropzones() {
            interact('.placeholder').dropzone({
                accept: '.card',
                overlap: 0.3,
                ondragenter: this.onCardDraggedOverPlaceholder,
                ondrop: this.onCardDrop,
                ondragleave: this.onCardDraggedLeave,
            })
        },
        onCardDraggedLeave(event) {
            event.currentTarget.style.border =
                'dotted calc(4vw * var(--pxToVw)) gray'
        },
        onCardMove(event) {
            const { target } = event
            const initialX = 0
            const initialY = 0

            const uuid = target.getAttribute('data-uuid')
            const userIndex =
                this.$store.getters['market/getUserIndexByUuid'](uuid)

            if (
                this.isStep(userIndex, this.STEPS.EXCHANGE) ||
                this.isStep(userIndex, this.STEPS.CONCLUSION) ||
                this.isStep(userIndex, this.STEPS.HIGHSCORE) ||
                this.isStep(userIndex, this.STEPS.FINISH) ||
                this.isStep(userIndex, this.STEPS.OUTRODISCARDED) ||
                this.isStep(userIndex, this.STEPS.OUTROSAVED)
            ) {
                return
            }

            const x =
                (parseFloat(target.getAttribute('data-x')) || initialX) +
                event.dx
            const y =
                (parseFloat(target.getAttribute('data-y')) || initialY) +
                event.dy
            let rotation = parseFloat(target.getAttribute('data-angle')) || 0

            // set start position for resetting
            if (!target.getAttribute('data-startx')) {
                target.setAttribute('data-startx', x)
            }
            if (!target.getAttribute('data-starty')) {
                target.setAttribute('data-starty', y)
            }
            if (!target.getAttribute('data-startangle')) {
                target.setAttribute('data-startangle', rotation)
            }

            let quadrant = -1
            if (userIndex) {
                this.currentlyDraggingUsers.push(userIndex)
                quadrant = userIndex
            } else {
                const left = x < this.stationData.width / 2
                const top = y < this.stationData.height / 2
                if (left && top) {
                    quadrant = 0
                } else if (!left && top) {
                    quadrant = 1
                } else if (left && !top) {
                    quadrant = 2
                } else if (!left && !top) {
                    quadrant = 3
                }
            }

            const upsideDown = quadrant !== -1 && quadrant < 2

            // rotation when dragged vertically
            // get center place holder
            const placeholderRect = this.getPlaceholderRect(quadrant, 2)

            const containerHeight = this.stationData.height
            if (upsideDown) {
                const upperThreshold = containerHeight * 0.33
                const lowerThreshold =
                    placeholderRect.y + placeholderRect.height
                if (upperThreshold > y && y > lowerThreshold) {
                    rotation = mapNumber(
                        y,
                        upperThreshold,
                        lowerThreshold,
                        rotation,
                        rotation > 180 ? 180 : 180,
                    )
                } else if (y <= lowerThreshold) {
                    rotation = 180
                }
            } else if (quadrant >= 2) {
                const upperThreshold = containerHeight * 0.66
                const lowerThreshold = placeholderRect.y
                if (upperThreshold < y && y < lowerThreshold) {
                    rotation = mapNumber(
                        y,
                        upperThreshold,
                        lowerThreshold,
                        rotation,
                        rotation > 180 ? 360 : 0,
                    )
                } else if (y >= lowerThreshold) {
                    rotation = 0
                }
            }

            // translate the element
            target.style.transform = `translate(${x}px, ${y}px) rotate(${rotation}deg)`
            target.style.zIndex = 1000
            target.style.transition = ''

            // update the posiion attributes
            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
        },
        onCardMoveEnd(event) {
            const { target } = event
            const cardUuid = target.getAttribute('data-uuid')
            const userIndex =
                this.$store.getters['market/getUserIndexByUuid'](cardUuid)

            if (this.isStep(parseInt(userIndex), this.STEPS.SORT)) {
                if (
                    Object.hasOwn(event, 'relatedTarget') &&
                    event.relatedTarget === null &&
                    !this.isStackItem(cardUuid)
                ) {
                    console.log('error in if cardmoveend')
                    this.animateToStartPosition(target)
                    return
                } else {
                    if (this.isStackItem(cardUuid)) {
                        return
                    }

                    const [placeholderUserIndex, otherPlaceholder] =
                        event.relatedTarget.className
                            .split(' ')
                            .filter((className) =>
                                className.startsWith('placeholder_'),
                            )[0]
                            .split('_')[1]
                            .split('-')
                    if (
                        parseInt(placeholderUserIndex) !== parseInt(userIndex)
                    ) {
                        console.log('error in elseif cardmoveend')
                        this.animateToStartPosition(target)
                        return
                    }
                }
            }

            if (target.getAttribute('data-wasdropped')) {
                target.removeAttribute('data-wasdropped')
                if (userIndex) {
                    const index = this.currentlyDraggingUsers.indexOf(userIndex)
                    if (index > -1) {
                        this.currentlyDraggingUsers.splice(index, 1)
                    }
                }
            }
            // card was not dropped
            else {
                if (userIndex) {
                    // card belongs to a user
                    // if user is in sort step and card was not moved from their stack to the center => reset
                    if (this.isStep(userIndex, this.STEPS.SORT)) {
                        const uuids = this.$store.state.market.userItems[
                            userIndex
                        ]
                            .map((e) => e?.uuid)
                            .filter((e) => {
                                return e === cardUuid || e === undefined
                            })
                        if (uuids.includes(cardUuid)) {
                            const x = target.getAttribute('data-backup-x')
                            const y = target.getAttribute('data-backup-y')
                            const rotation = target.getAttribute('data-angle')
                            target.setAttribute('data-x', x)
                            target.setAttribute('data-y', y)
                            target.style.transform = `translate(${x}px, ${y}px) rotate(${rotation}deg)`
                            target.style.transition = '1s ease-in-out'
                        } else {
                            return
                        }
                    }
                    // if user is in exchange step and card was moved from their stack to the center => remove from stack

                    // check if card is already in deck
                    let alreadyOnDeck = false
                    for (let item of this.$store.state.market.userItems[
                        userIndex
                    ]) {
                        if (
                            item?.goettItem?.PI ===
                            this.$store.getters['market/getItemByUuid'](
                                cardUuid,
                            )?.goettItem?.PI
                        ) {
                            console.log(
                                'already in deck. cancelling exchange (onCardMoveEnd).',
                            )
                            alreadyOnDeck = true
                        }
                    }
                    if (alreadyOnDeck) {
                        return
                    }

                    if (this.isStep(userIndex, this.STEPS.EXCHANGE)) {
                        const item =
                            this.$store.getters['market/getItemByUuid'](
                                cardUuid,
                            )
                        this.$store.dispatch('market/removeItem', {
                            userIndex,
                            item,
                        })
                    }
                } else {
                    // if card does not belong to a user and was dragged from center
                    const maxDistance = 800
                    const center = {
                        x: this.stationData.width / 2,
                        y: this.stationData.height / 2,
                    }
                    const cardCenter = {
                        x:
                            parseFloat(target.getAttribute('data-x')) +
                            target.getBoundingClientRect().width / 2,
                        y:
                            parseFloat(target.getAttribute('data-y')) +
                            target.getBoundingClientRect().height / 2,
                    }
                    const distance = getDistance(center, cardCenter)
                    if (distance > maxDistance) {
                        const x = target.getAttribute('data-startx')
                        const y = target.getAttribute('data-starty')
                        const rotation = target.getAttribute('data-angle')
                        target.setAttribute('data-x', x)
                        target.setAttribute('data-y', y)
                        const transform = `translate(${x}px, ${y}px) rotate(${rotation}deg)`
                        target.style.transform = transform
                        target.style.transition = '1s ease-in-out'
                    }
                }
            }
            target.style.zIndex = 1
            if (!this.isStep(parseInt(userIndex), this.STEPS.SORT)) {
                target.removeAttribute('data-startx')
                target.removeAttribute('data-starty')
            }
            target.removeAttribute('data-backup-x')
            target.removeAttribute('data-backup-y')
            target.removeAttribute('data-startangle')
        },
        onCardDraggedOverPlaceholder(event) {
            const card = event.dragEvent.currentTarget
            const placeholder = event.currentTarget
            const [userIndex, placeholderIndex] = placeholder.className
                .split(' ')
                .filter((className) => className.startsWith('placeholder_'))[0]
                .split('_')[1]
                .split('-')
            placeholder.style.border = 'solid calc(4vw * var(--pxToVw)) white'
            const cardUuid = card.getAttribute('data-uuid')
            const cardIndex =
                this.$store.getters['market/getCardIndexByUuid'](cardUuid)
            card.setAttribute(
                'data-backup-x',
                placeholder.getBoundingClientRect().x,
            )
            card.setAttribute(
                'data-backup-y',
                placeholder.getBoundingClientRect().y,
            )
            const cardUserIndex =
                this.$store.getters['market/getUserIndexByUuid'](cardUuid)
            const item = this.$store.getters['market/getItemByUuid'](cardUuid)

            if (cardUserIndex === parseInt(userIndex)) {
                card.setAttribute(
                    'data-startx',
                    placeholder.getBoundingClientRect().x,
                )
                card.setAttribute(
                    'data-starty',
                    placeholder.getBoundingClientRect().y,
                )
            }

            if (
                item &&
                cardIndex != placeholderIndex &&
                // if user is currently sorting
                (this.isStep(userIndex, this.STEPS.SORT) ||
                    // or if user is currently sorting their cards while being in exchange step
                    (this.isStep(userIndex, this.STEPS.EXCHANGE) &&
                        cardUserIndex !== null &&
                        cardUserIndex !== undefined))
            ) {
                // if card belongs to user
                if (cardUserIndex !== null && cardUserIndex !== undefined) {
                    const itemToMove = this.$store.getters['market/getItem'](
                        userIndex,
                        placeholderIndex,
                    )
                    this.$store.dispatch('market/sortItem', {
                        userIndex,
                        item,
                        index: placeholderIndex,
                    })
                    const rotation = userIndex > 1 ? 0 : 180
                    if (itemToMove) {
                        const cardToMove = document.querySelector(
                            `.card-${itemToMove.uuid}`,
                        )

                        if (cardToMove) {
                            const { x, y } = document
                                .querySelector(
                                    `.placeholder_${userIndex}-${cardIndex}`,
                                )
                                .getBoundingClientRect()

                            cardToMove.setAttribute('data-x', x)
                            cardToMove.setAttribute('data-y', y)
                            cardToMove.setAttribute('data-angle', rotation)
                            const transform = `translate(${x}px, ${y}px) rotate(${rotation}deg)`
                            cardToMove.style.transform = transform
                        }
                    }
                }
            }
        },
        onCardDrop(event) {
            const placeHolder = event.currentTarget
            placeHolder.style.border = 'dotted calc(4vw * var(--pxToVw)) gray'
            const card = event.dragEvent.currentTarget
            card.setAttribute('is-dropped', 'true')
            const cardUuid = card.getAttribute('data-uuid')

            const [userIndex, placeholderIndex] = placeHolder.className
                .split(' ')
                .filter((className) => className.startsWith('placeholder_'))[0]
                .split('_')[1]
                .split('-')

            // if user is sorting
            const uuids = this.$store.state.market.userItems[userIndex]
                .map((e) => e?.uuid)
                .filter((e) => {
                    return e === cardUuid || e === undefined
                })

            if (this.isStep(userIndex, STEPS.SORT)) {
                if (
                    this.$store.state.market.userItems[userIndex][
                        placeholderIndex
                    ] !== null &&
                    !uuids.includes(cardUuid)
                ) {
                    if (
                        this.$store.getters['market/getUserOwnsItem'](
                            userIndex,
                            cardUuid,
                        )
                    ) {
                        card.setAttribute(
                            'data-startx',
                            card.getAttribute('data-absolute-start-x') ||
                                '1000',
                        )
                        card.setAttribute(
                            'data-starty',
                            card.getAttribute('data-absolute-start-y') ||
                                '1000',
                        )
                    }
                    this.animateToStartPosition(card)
                    return
                }
                const item = this.$store.getters['market/getAllItems'].find(
                    (i) => i.uuid === cardUuid,
                )

                const cardUserIndex =
                    this.$store.getters['market/getUserIndexByUuid'](cardUuid)
                if (cardUserIndex !== parseInt(userIndex)) {
                    return
                }
                if (cardUserIndex !== null && cardUserIndex !== undefined) {
                    this.$store.dispatch('market/sortItem', {
                        userIndex,
                        index: placeholderIndex,
                        item,
                    })
                    const x = placeHolder.getBoundingClientRect().x
                    const y = placeHolder.getBoundingClientRect().y
                    const rotation = userIndex > 1 ? 0 : 180
                    card.setAttribute('data-x', x)
                    card.setAttribute('data-y', y)
                    card.setAttribute('data-angle', rotation)
                    card.style.transform = `translate(${x}px, ${y}px) rotate(${rotation}deg)`
                }
                // if card does not belong to a user but is dropped on a user's stack who is currently sortin
                else {
                    this.animateToStartPosition()
                }
            }
            // if user is exchanging
            else if (this.isStep(userIndex, STEPS.EXCHANGE)) {
                console.log('start exchanging')

                if (
                    this.$store.getters['market/getRemainingExchanges'](
                        userIndex,
                    ) <= 0
                ) {
                    console.log('no more exchanges possible')
                    return
                }

                // check if card is already in deck
                let alreadyOnDeck = false
                for (let item of this.$store.state.market.userItems[
                    userIndex
                ]) {
                    if (
                        item?.goettItem?.PI ===
                        this.$store.getters['market/getItemByUuid'](cardUuid)
                            ?.goettItem?.PI
                    ) {
                        console.log(
                            'already in deck. cancelling exchange (onCardDrop).',
                        )
                        alreadyOnDeck = true
                    }
                }
                if (alreadyOnDeck) {
                    return
                }

                // execute exchange
                let i = 0
                for (let item of this.$store.state.market.userItems[
                    userIndex
                ]) {
                    if (
                        (item?.goettItem.PI ===
                            this.$store.getters['market/getItemByUuid'](
                                cardUuid,
                            )?.goettItem?.PI &&
                            !uuids.includes(cardUuid)) ||
                        uuids.length === 0
                    ) {
                        if (
                            item?.goettItem.PI ===
                            this.$store.getters['market/getItemByUuid'](
                                cardUuid,
                            )?.goettItem?.PI
                        ) {
                            console.log('not exchanged')
                            this.animateToStartPosition(card)
                            return
                        }

                        if (i === parseInt(placeholderIndex)) {
                            const oldCard =
                                this.$store.state.market.userItems[userIndex][
                                    placeholderIndex
                                ]
                            const oldElement = document.getElementsByClassName(
                                `card-${oldCard.uuid}`,
                            )[0]
                            //push oldCard to items
                            this.$store.dispatch('market/removeItem', {
                                userIndex,
                                item: oldCard,
                            })
                            this.$store.dispatch('market/flipItemInUserArray', {
                                userIndex,
                                placeholderIndex: placeholderIndex,
                                cardUuid,
                            })
                            this.$store.dispatch(
                                'market/removeItemFromItemsArray',
                                cardUuid,
                            )
                            this.$nextTick(() => {
                                this.getRandomCardPosition(oldElement)
                                oldElement.style.transition = '1s ease-in-out'
                            })
                            this.$store.dispatch('market/setExchangedItem', {
                                userIndex: parseInt(userIndex),
                                placeIndex: parseInt(placeholderIndex),
                                oldItem: oldCard.id,
                                newItem:
                                    this.$store.getters['market/getItemByUuid'](
                                        cardUuid,
                                    )?.goettItem?.PI,
                            })
                            console.log('exchanged')
                        }
                        i++
                    } else {
                        console.log('nothing to exchange')
                    }
                }

                this.$store.dispatch('market/incrementExchanges', {
                    userIndex,
                })

                const item =
                    this.$store.getters['market/getItemByUuid'](cardUuid)

                this.$store.dispatch('market/addItemToUser', {
                    userIndex,
                    // index: placeholderIndex,
                    item,
                })

                const x = placeHolder.getBoundingClientRect().x
                const y = placeHolder.getBoundingClientRect().y
                const rotation = userIndex > 1 ? 0 : 180
                card.setAttribute('data-x', x)
                card.setAttribute('data-y', y)
                card.setAttribute('data-angle', rotation)
                const transform = `translate(${x}px, ${y}px) rotate(${rotation}deg)`
                card.style.transform = transform
            }
            card.setAttribute('data-wasdropped', true)
            card.style.zIndex = '1'
            this.$forceUpdate()
        },
        animateToStartPosition(card) {
            const x = card.getAttribute('data-startx')
            const y = card.getAttribute('data-starty')
            const rotation = card.getAttribute('data-startangle')
            card.setAttribute('data-x', x)
            card.setAttribute('data-y', y)
            card.setAttribute('data-angle', rotation)
            const transform = `translate(${x}px, ${y}px) rotate(${rotation}deg)`
            card.style.transform = transform
            card.style.transition = '1s ease-in-out'
        },
        setPlaceholderPositions() {
            const positions = new Array(this.NUMBEROFUSERS * this.NUMBEROFSLOTS)
            Object.entries(this.$refs)
                .filter((entry) => entry[0].startsWith('placeholder_'))
                .forEach((entry) => {
                    const key = entry[0]
                    const value = entry[1]
                    const words = key.split('_')
                    const indices = words.pop().split('-')
                    const userIndex = indices[0]
                    const placeholderIndex = indices.pop()
                    const rect = value[0].getBoundingClientRect()
                    positions[
                        parseInt(userIndex) * this.NUMBEROFSLOTS +
                            parseInt(placeholderIndex)
                    ] = {
                        x: rect.x + rect.width / 2,
                        y: rect.y + rect.height / 2,
                    }
                })
            this.placeholderPositions = positions
        },
        reshuffleCards(userIndex) {
            // reshuffle cards from user at userIndex
            this.$store.dispatch('market/sortUserCards', userIndex)
            const userCards =
                this.$store.getters['market/getUserItems'](userIndex)
            let posArray = []
            userCards.forEach((card) => {
                posArray.push(
                    document
                        .getElementsByClassName('card-' + card.uuid)[0]
                        .getAttribute('data-x'),
                )
            })
            posArray.sort((a, b) => a - b)
            userCards.forEach((card, index) => {
                const cardElement = document.getElementsByClassName(
                    'card-' + card.uuid,
                )[0]
                const yPosition = cardElement.getAttribute('data-y')
                const xPosition = posArray[index]
                const userIndex =
                    this.$store.getters['market/getUserIndexByItem'](card)
                cardElement.setAttribute('data-x', xPosition)
                cardElement.style.transform = `translate(${xPosition}px, ${yPosition}px) rotate(${
                    userIndex < 2 ? 180 : 0
                }deg)`
                cardElement.style.transition = '500ms ease-in-out'
            })
        },
        confirmIntroduction(userIndex) {
            this.$store.dispatch('market/confirmIntroduction', userIndex)
        },
        confirmSort(userIndex) {
            this.$store.dispatch('market/confirmSort', userIndex)
        },
        confirmExchange(userIndex) {
            this.$store.dispatch('market/confirmExchange', userIndex)
        },
        confirmConclusion(userIndex) {
            this.$store.dispatch('market/confirmConclusion', userIndex)
        },
        confirmHighscore(userIndex) {
            this.$store.dispatch('market/confirmHighscore', userIndex)
        },
        saveCollection(userIndex) {
            this.$store.dispatch('market/saveCollection', userIndex)
        },
        discardCollection(userIndex) {
            this.$store.dispatch('market/discardCollection', userIndex)
        },
        isStep(userIndex, step) {
            return this.$store.getters['market/getStep'](userIndex) === step
        },
        getSelectedCardStyle(item) {
            if (!item) {
                return null
            }
            const userIndex =
                this.$store.getters['market/getUserIndexByItem'](item)
            const cardIndex = this.$store.getters['market/getCardIndex'](item)
            const isUpsideDown = userIndex < 2

            const { height, width } = document
                .querySelector('.card')
                .getBoundingClientRect()
            let x, y
            let rotation = 0

            switch (userIndex) {
                case 0: {
                    // x = this.stationData.width * 0.05 - width / 2
                    x = this.stationData.width * 0.37 - width / 2
                    y = this.stationData.height * 0.33 - height
                    break
                }
                case 1: {
                    x = this.stationData.width * 0.65 - width / 2
                    y = this.stationData.height * 0.33 - height
                    break
                }
                case 2: {
                    x = this.stationData.width * 0.37 - width / 2
                    y = this.stationData.height * 0.67
                    break
                }
                case 3: {
                    // x = this.stationData.width * 0.97 - width / 2
                    x = this.stationData.width * 0.65 - width / 2
                    y = this.stationData.height * 0.67
                    break
                }
            }
            y += ((isUpsideDown ? -1 : 1) * cardIndex * height) / 2
            // apply some rotation
            // 180deg rotation for upper users
            // plus some "random" rotation
            switch (cardIndex) {
                case 0: {
                    rotation = (isUpsideDown ? -180 : 0) - 2
                    break
                }
                case 1: {
                    rotation = (isUpsideDown ? -180 : 0) - 7
                    break
                }
                case 2: {
                    rotation = (isUpsideDown ? -180 : 0) + 5
                    break
                }
                case 3: {
                    rotation = (isUpsideDown ? -180 : 0) + 3
                    break
                }
                case 4: {
                    rotation = (isUpsideDown ? -180 : 0) + 1
                    break
                }
            }
            return {
                x,
                y,
                rotation,
            }
        },
        getPlaceholdersContainerStyle(userIndex) {
            const step = this.$store.getters['market/getStep'](userIndex)
            let opacity = 1
            let top = 128
            let bottom = 128

            let selectedItems =
                this.$store.state.market.selectedItems[userIndex]
            let emptyItems = 0
            selectedItems.forEach((item) => {
                if (item === null) {
                    emptyItems += 1
                }
            })

            if (
                this.$store.state.market.selectedItems[userIndex].length ===
                    this.NUMBEROFSLOTS &&
                emptyItems === 0
            ) {
                const structureIds = this.$store.state.market.selectedItems[
                    userIndex
                ].map((el) => {
                    return el?.nfcItem?.structureId
                })
                const userZone = userIndex + 1
                const currentUser = this.$store.getters.getUserAtUnit(
                    'market_' + userIndex,
                )
                const category =
                    this.$store.getters['market/getCategory'](userIndex)

                this.$userLog.saveUserLog(
                    'market_selected_items',
                    currentUser.uuid,
                    {
                        structureIds: structureIds,
                        step: step,
                        category: category,
                    },
                    userZone,
                )
            }
            if (
                ![
                    STEPS.SORT,
                    STEPS.EXCHANGE,
                    STEPS.HIGHSCORE,
                    STEPS.FINISH,
                    STEPS.OUTRODISCARDED,
                    STEPS.OUTROSAVED,
                ].includes(step)
            ) {
                opacity = 0.001
            }
            let style = {
                opacity,
            }

            /* TODO: get rid of the magic numbers here */
            switch (userIndex) {
                case 0: {
                    const x = 760
                    style = {
                        ...style,
                        top: `${top}px`,
                        transform: `rotate(180deg) translateX(calc(50% - ${x}px))`,
                    }
                    break
                }
                case 1: {
                    const x = 3060
                    style = {
                        ...style,
                        top: `${top}px`,
                        transform: `rotate(180deg) translateX(calc(50% - ${x}px))`,
                    }
                    break
                }
                case 2: {
                    const x = 760
                    style = {
                        ...style,
                        bottom: `${bottom}px`,
                        transform: `translateX(calc(-50% + ${x}px))`,
                    }
                    break
                }
                case 3: {
                    const x = 3060
                    style = {
                        ...style,
                        bottom: `${bottom}px`,
                        transform: `translateX(calc(-50% + ${x}px))`,
                    }
                    break
                }
            }
            return style
        },
        getButtonsStyle(userIndex) {
            let rect
            const placeHoldersContainer =
                this.$refs[`placeholdersContainer_${userIndex}`]
            if (placeHoldersContainer) {
                rect = placeHoldersContainer[0].getBoundingClientRect()
            }

            let left, top
            let rotation = 0
            const offsetY = 50
            if (!rect) {
                return {}
            }
            switch (userIndex) {
                case 0: {
                    rotation = 180
                    left = rect.left + rect.width / 2
                    top = rect.top - offsetY
                    break
                }
                case 1: {
                    rotation = 180
                    left = rect.left + rect.width / 2
                    top = rect.top - offsetY
                    break
                }
                case 2: {
                    left = rect.left + rect.width / 2
                    top = rect.bottom + offsetY
                    break
                }
                case 3: {
                    left = rect.left + rect.width / 2
                    top = rect.bottom + offsetY
                    break
                }
            }
            return {
                left: `${left}px`,
                top: `${top}px`,
                transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
            }
        },
        getUnitKey(userIndex) {
            return `market_${userIndex}`
        },
        getPlaceholderRect(userIndex, placeholderIndex) {
            let rect = null
            Object.entries(this.$refs)
                .filter((ref) => ref[0].startsWith('placeholder_'))
                .forEach((entry) => {
                    const key = entry[0]
                    const value = entry[1]
                    const words = key.split('_')
                    const indices = words.pop().split('-')
                    const otherUserIndex = parseInt(indices[0])
                    const otherPlaceholderIndex = parseInt(indices.pop())
                    if (
                        userIndex === otherUserIndex &&
                        placeholderIndex === otherPlaceholderIndex
                    ) {
                        rect = value[0].getBoundingClientRect()
                    }
                })
            return rect
        },
        onCheckIconClicked(userIndex) {
            if (this.isStep(userIndex, STEPS.INTRODUCTION)) {
                this.confirmIntroduction(userIndex)
            } else if (this.isStep(userIndex, STEPS.SORT)) {
                this.reshuffleCards(userIndex)
                this.confirmSort(userIndex)
            } else if (this.isStep(userIndex, STEPS.EXCHANGE)) {
                this.confirmExchange(userIndex)
            } else if (this.isStep(userIndex, STEPS.CONCLUSION)) {
                this.confirmConclusion(userIndex)
            } else if (this.isStep(userIndex, STEPS.HIGHSCORE)) {
                this.confirmHighscore(userIndex)
            }

            const step = this.$store.getters['market/getStep'](userIndex)
            const userZone = userIndex + 1
            const currentUser = this.$store.getters.getUserAtUnit(
                'market_' + userIndex,
            )
            this.$userLog.saveUserLog(
                'market_confirmed_step',
                currentUser.uuid,
                {
                    step: step,
                },
                userZone,
            )
        },
        areDetailsShown(item) {
            const debug = Object.keys(this.$route.query).includes('debug')
            if (debug) return true

            const userIndex =
                this.$store.getters['market/getUserIndexByItem'](item)
            if (userIndex != null) {
                return (
                    this.isStep(userIndex, STEPS.EXCHANGE) ||
                    this.isStep(userIndex, STEPS.HIGHSCORE) ||
                    this.isStep(userIndex, STEPS.CONCLUSION) ||
                    this.isStep(userIndex, STEPS.FINISH)
                )
            }
            return false
        },
        setRandomCardPositions() {
            document.querySelectorAll('.card').forEach((card) => {
                this.getRandomCardPosition(card)
            })
        },
        getRandomCardPosition(card) {
            const container = document.querySelector('.marketGame')

            const cardRect = document
                .querySelector('.card')
                .getBoundingClientRect()
            const initialX =
                container.clientWidth / 2 +
                randomNumber(
                    -container.clientWidth / 12,
                    container.clientWidth / 12,
                ) -
                cardRect.width / 4
            const initialY =
                container.clientHeight / 2 +
                randomNumber(
                    -container.clientHeight / 8,
                    container.clientHeight / 8,
                )
            const initialAngle = randomNumber(0, 359)

            card.setAttribute('data-x', initialX)
            card.setAttribute('data-y', initialY)
            card.setAttribute('data-angle', initialAngle)
            card.setAttribute('is-dropped', 'false')
            const transform = `translate(${initialX}px, ${initialY}px) rotate(${initialAngle}deg)`
            card.style.transform = transform
        },
        isStackItem(uuid) {
            return (
                this.$store.getters['market/getUserIndexByUuid'](uuid) === null
            )
        },
    },
}
</script>

<style lang="scss" scoped>
$userDesktopWidth: 40%;
$gap: calc(100% - 2 * #{$userDesktopWidth});

.debug {
    .debugGrid {
        position: absolute;
        width: 100%;
        height: 100%;

        div {
            background-color: white;
            position: absolute;

            &.left {
                left: calc(#{$userDesktopWidth} / 2);
                height: 100%;
                width: 4px;
            }

            &.center {
                left: 50%;
                height: 100%;
                width: 4px;
            }

            &.right {
                left: calc(100% - #{$userDesktopWidth} / 2);
                height: 100%;
                width: 4px;
            }

            &.horizontal {
                top: 50%;
                width: 100%;
                height: 4px;
            }
        }
    }

    .texts {
        .market-user {
            background-color: rgba(24, 24, 24, 0.2);
        }
    }
}

.user-area- {
    &size {
        width: 40%;
        height: 40%;
    }

    &0 {
        left: 5%;
        top: 5%;
    }

    &1 {
        left: 55%;
        top: 5%;
    }

    &2 {
        left: 5%;
        top: 55%;
    }

    &3 {
        left: 55%;
        top: 55%;
    }
}

.texts {
    display: grid;
    grid-template-columns: $userDesktopWidth $userDesktopWidth;
    grid-gap: 0 $gap;

    .market-user0 {
        transform: translateX(calc(-50% + 760px)) rotate(180deg);
    }

    .market-user1 {
        transform: translateX(calc(-50% + 760px)) rotate(180deg);
    }
}

.cardsContainer {
    .card {
        position: absolute;
    }

    .placeholdersContainer {
        pointer-events: all;
        display: flex;
        flex-direction: column;
        position: absolute;
        transform: translateX(-50%);

        &.invisibleButMounted {
            opacity: 0.001;
        }

        .placeholders {
            display: flex;
            flex-direction: row;
            text-align: center;
            justify-content: center;

            .placeholder {
                $cardWidth: var(--marketCardWidth);
                $cardHeight: var(--marketCardHeight);

                width: $cardWidth;
                height: $cardHeight;
                border-radius: calc(15vw * var(--pxToVw));
                border: dotted calc(4vw * var(--pxToVw)) gray;
                background-color: transparent;
                margin-left: 10px;
                margin-right: 10px;
                transition: all ease 0.5s;
            }
        }

        .axis {
            width: 100%;
            margin-top: 24px;
        }
    }
}

.buttons {
    pointer-events: all;
    display: flex;
    position: absolute;
    font-size: 24px;

    .button {
        justify-content: center;
        align-items: center;
        width: auto;
        margin: 16px;
        padding: 1vh;
        display: flex;
        flex-direction: row;
        grid-gap: 2ch;

        p {
            display: inline-block;
            margin: 0;
            width: auto;
            color: gray;
        }
    }
}

.debug {
    .items {
        background-color: rgba(255, 0, 0, 0.2);
    }
}

.items {
    $margin: 0;
    position: absolute;
    width: $userDesktopWidth;
    height: 30%;
    width: 30%;

    &.items-0 {
        transform: rotate(180deg);
        top: $margin;
        left: $margin;

        &.randomUser {
            margin-left: 6%;
        }
    }

    &.items-1 {
        transform: rotate(180deg);
        top: $margin;
        right: $margin;

        &.randomUser {
            margin-right: 6%;
        }
    }

    &.items-2 {
        bottom: $margin;
        left: $margin;

        &.randomUser {
            margin-left: 6%;
        }
    }

    &.items-3 {
        bottom: $margin;
        right: $margin;

        &.randomUser {
            margin-right: 6%;
        }
    }
}
</style>
