import { v4 as uuidv4 } from 'uuid'
import api, { FIELDS } from '../../../mixins/api'
import { getMaxLengthInMm } from '../../../utils/items'
import { debounce, sortBy } from 'lodash'
import _ from 'lodash'

const STEPS = {
    IDLE: 'IDLE',
    INTRODUCTION: 'INTRODUCTION',
    SELECTION: 'SELECTION',
    SORT: 'SORT',
    EXCHANGE: 'EXCHANGE',
    CONCLUSION: 'CONCLUSION',
    HIGHSCORE: 'HIGHSCORE',
    FINISH: 'FINISH',
    OUTRODISCARDED: 'OUTRODISCARDED',
    OUTROSAVED: 'OUTROSAVED',
}

const CATEGORIES = {
    YEAR: 'YEAR',
    SIZE: 'SIZE',
    BEAUTY: 'BEAUTY',
    MONETARYVALUE: 'MONETARYVALUE',
    SIGNIFICANCE: 'SIGNIFICANCE',
}

const NUMBEROFUSERS = 4
const NUMBEROFSLOTS = 5
const MAXNUMBEROFEXCHANGES = 5
const INTRODUCTIONDURATION = 10000
// const HIGHSCOREDURATION = 100000
// const CONCLUSIONDURATION = 40000

const introductionTimeouts = new Array(NUMBEROFUSERS)
const conclusionTimeouts = new Array(NUMBEROFUSERS)
const highscoreTimeouts = new Array(NUMBEROFUSERS)
const logoutTimeouts = new Array(NUMBEROFUSERS)

const stayAtUnit = async (rootGetters, userIndex) => {
    const unit = `market_${userIndex}`
    const userUuid = rootGetters.getUuid(`market_${userIndex}`)
    await api.twoavy.stayAtUnit(userUuid, unit)
}
const debouncedStayAtUnit = debounce(stayAtUnit, 5000, {
    maxWait: 10000,
    leading: true,
})
const getTwoavyType = (category) => {
    switch (category) {
        case CATEGORIES.MONETARYVALUE: {
            return 'monetary_value'
        }
        case CATEGORIES.BEAUTY: {
            return CATEGORIES.BEAUTY.toLowerCase()
        }
        case CATEGORIES.SIZE: {
            return CATEGORIES.SIZE.toLowerCase()
        }
        case CATEGORIES.YEAR: {
            return CATEGORIES.YEAR.toLowerCase()
        }
        case CATEGORIES.SIGNIFICANCE: {
            return CATEGORIES.SIGNIFICANCE.toLowerCase()
        }
    }
    return category
}

const getInitialState = () => {
    return {
        items: [],
        rankings: null,
        highscores: null,
        // user states
        sessions: new Array(NUMBEROFUSERS),
        steps: [STEPS.IDLE, STEPS.IDLE, STEPS.IDLE, STEPS.IDLE],
        categories: ['', '', '', ''],
        selectedItems: [[], [], [], []],
        confirmedSelectedItems: [[], [], [], []],
        replacedItems: [[], [], [], []],
        userItems: [
            [null, null, null, null, null],
            [null, null, null, null, null],
            [null, null, null, null, null],
            [null, null, null, null, null],
        ],
        userLanguages: ['de', 'de', 'de', 'de'],
        exchanges: [0, 0, 0, 0],
        exchangedItems: [[], [], [], []],
    }
}
export default {
    namespaced: true,
    state: getInitialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getInitialState())
        },
        resetUser(state, userIndex) {
            const userItems = [...state.userItems]
            const userLanguages = [...state.userLanguages]
            const selectedItems = [...state.selectedItems]
            const steps = [...state.steps]
            const exchanges = [...state.exchanges]
            const exchangedItems = [...state.exchangedItems]

            userItems[userIndex] = [null, null, null, null, null]
            userLanguages[userIndex] = 'de'
            selectedItems[userIndex] = []
            steps[userIndex] = STEPS.IDLE
            exchanges[userIndex] = 0
            exchangedItems[userIndex] = []

            state.userItems = userItems
            state.userLanguages = userLanguages
            state.selectedItems = selectedItems
            state.steps = steps
            state.exchanges = exchanges
            state.exchangedItems = exchangedItems

            // and clear timeouts
            clearTimeout(introductionTimeouts[userIndex])
            clearTimeout(highscoreTimeouts[userIndex])
            clearTimeout(logoutTimeouts[userIndex])
        },
        setItems(state, items) {
            state.items = items
        },
        setLanguage(state, { userIndex, lang }) {
            const userLanguages = [...state.userLanguages]
            userLanguages[userIndex] = lang
            state.userLanguages = userLanguages
        },
        setHighscore(state, highscore) {
            state.highscores = highscore
        },
        toggleSelectedItem(state, { item, userIndex }) {
            if (
                state.steps[userIndex] !== STEPS.INTRODUCTION &&
                state.steps[userIndex] !== STEPS.SELECTION &&
                state.steps[userIndex] !== STEPS.SORT
            ) {
                return
            }
            if (state.steps[userIndex] === STEPS.INTRODUCTION) {
                clearTimeout(introductionTimeouts[userIndex])
                const steps = [...state.steps]
                steps[userIndex] = STEPS.SELECTION
                state.steps = steps
            }
            const selectedItems = [...state.selectedItems]
            const index = selectedItems[userIndex].findIndex(
                (i) => i?.goettItem.PI === item.goettItem.PI,
            )
            const steps = [...state.steps]
            if (index !== -1) {
                selectedItems[userIndex][index] = null // .splice(index, 1)
            } else {
                const firstNullIndex = selectedItems[userIndex].findIndex(
                    (i) => i == null,
                )
                if (firstNullIndex !== -1) {
                    selectedItems[userIndex][firstNullIndex] = {
                        ...item,
                        uuid: uuidv4(),
                    }
                } else {
                    selectedItems[userIndex].push({
                        ...item,
                        uuid: uuidv4(),
                    })
                }
            }
            if (
                selectedItems[userIndex].length === NUMBEROFSLOTS &&
                selectedItems[userIndex].every((i) => i != null)
            ) {
                steps[userIndex] = STEPS.SORT
                state.steps = steps
                const confirmedSelectedItems = [...state.selectedItems]
                confirmedSelectedItems[userIndex] = selectedItems[userIndex]
                state.confirmedSelectedItems = confirmedSelectedItems
            }
            state.selectedItems = selectedItems
        },
        sortItem(state, { userIndex, item, index }) {
            const userItems = [...state.userItems]
            const selectedItems = [...state.selectedItems]
            const selectedItemIndex = selectedItems[userIndex].findIndex(
                (i) => i?.uuid === item.uuid,
            )
            let oldIndex = -1
            userItems[userIndex].forEach((otherItem, otherIndex) => {
                if (otherItem?.goettItem?.PI === item?.goettItem?.PI) {
                    oldIndex = otherIndex
                }
            })
            if (index === oldIndex) {
                return
            }
            const itemToReplace = _.cloneDeep(userItems[userIndex][index])
            userItems[userIndex][index] = item

            if (oldIndex !== -1) {
                userItems[userIndex][oldIndex] = itemToReplace
            } else if (
                itemToReplace != null &&
                Object.keys(itemToReplace).length > 0
            ) {
                for (let i = 0; i < index; i++) {
                    if (
                        userItems[userIndex][i] == null ||
                        Object.keys(userItems[userIndex][i]).length === 0
                    ) {
                        oldIndex = i
                    }
                    if (oldIndex === -1) {
                        for (let i = NUMBEROFSLOTS - 1; i > index; i--) {
                            if (
                                userItems[userIndex][i] == null ||
                                Object.keys(userItems[userIndex][i]).length ===
                                    0
                            ) {
                                oldIndex = i
                            }
                        }
                    }
                }
                userItems[userIndex][oldIndex] = itemToReplace
            }
            state.userItems = userItems
            if (selectedItemIndex >= 0) {
                selectedItems[userIndex][selectedItemIndex] = null
                state.selectedItems = selectedItems
            }
        },
        removeItem(state, { userIndex, item }) {
            const userItems = [...state.userItems]
            const items = [...state.items]
            userItems[userIndex] = userItems[userIndex].map((i) =>
                i?.goettItem?.PI === item.goettItem.PI ? null : i,
            )
            items.push(item)
            state.userItems = userItems
            state.items = items
        },
        addItemToUser(state, { userIndex, item }) {
            const userItems = [...state.userItems]
            const items = [...state.items]
            const exchanges = [...state.exchanges]
            let index = -1
            userItems[userIndex].forEach((selectedItem, i) => {
                if (selectedItem == null && index === -1) {
                    index = i
                }
            })
            // if there is an empty slot
            if (index >= 0 && index < userItems[userIndex].length) {
                userItems[userIndex][index] = item
                // remove from items
                state.items = items.filter(
                    (i) => i.goettItem.PI !== item.goettItem.PI,
                )
                exchanges[userIndex]++
                const replacedItems = state.replacedItems
                replacedItems[userIndex].push(item.item.goettItem.PI)
                state.replacedItems = replacedItems

                state.userItems = userItems
                state.exchanges = exchanges
            }
        },
        sortUserCards(state, { userIndex, getters }) {
            let cards = state.userItems[userIndex]
            switch (state.categories[userIndex]) {
                case CATEGORIES.BEAUTY:
                    cards = sortBy(cards, function (card) {
                        return getters['getRanking'](card, CATEGORIES.BEAUTY)
                    })
                    break
                case CATEGORIES.MONETARYVALUE:
                    cards = sortBy(cards, function (card) {
                        return getters['getRanking'](
                            card,
                            CATEGORIES.MONETARYVALUE,
                        )
                    })
                    break
                case CATEGORIES.SIZE:
                    cards = sortBy(cards, function (card) {
                        return (
                            getMaxLengthInMm(card.goettItem) / 1000
                        ).toFixed(2)
                    })
                    break
                case CATEGORIES.YEAR:
                    cards = sortBy(cards, function (card) {
                        return card.goettItem[FIELDS.YEAR][0]
                    })
                    break
                case CATEGORIES.SIGNIFICANCE:
                    cards = sortBy(cards, function (card) {
                        return getters['getRanking'](
                            card,
                            CATEGORIES.SIGNIFICANCE,
                        )
                    })
                    break
            }
            state.userItems[userIndex] = cards
        },
        setStep(state, { step, userIndex }) {
            const steps = [...state.steps]
            steps[userIndex] = step
            state.steps = steps
        },
        setStepIntroduction(state, userIndex) {
            const steps = [...state.steps]
            steps[userIndex] = STEPS.INTRODUCTION
            state.steps = steps
        },
        addMarketItems(state, userIndex) {
            const userItems = [...state.userItems]
            userItems[userIndex] = userItems[userIndex].map((item) => {
                return {
                    ...item,
                    marketItem: state.items.find(
                        (i) => i.goettItem.PI === item.goettItem.PI,
                    )?.marketItem,
                }
            })
            state.userItems = userItems
        },
        discardCollection(state, userIndex) {
            const steps = [...state.steps]
            steps[userIndex] = STEPS.OUTRODISCARDED
            state.steps = steps
        },
        saveCollection(state, userIndex) {
            const steps = [...state.steps]
            steps[userIndex] = STEPS.OUTROSAVED
            state.steps = steps
        },
        setCategory(state, { userIndex, category }) {
            const categories = [...state.categories]
            categories[userIndex] = category
            state.categories = categories
        },
        setRankings(state, rankings) {
            state.rankings = rankings
        },
        setHighscores(state, highscores) {
            state.highscores = highscores
        },
        setSession(state, { userIndex, session }) {
            const sessions = [...state.sessions]
            sessions[userIndex] = session
            state.sessions = sessions
        },
        setSwap(state, { swapArray, userIndex }) {
            state.userItems[userIndex] = swapArray
        },
        removeItemFromItemsArray(state, newItems) {
            state.items = newItems
        },
        incrementExchanges(state, index) {
            const exchanges = [...state.exchanges]
            exchanges[index]++
            state.exchanges = exchanges
        },
        setExchangedItem(state, exchangedItem) {
            const index = state.exchangedItems[
                exchangedItem.userIndex
            ].findIndex((x) => x.oldItem === exchangedItem.oldItem)
            if (index < 0) {
                state.exchangedItems[exchangedItem.userIndex].push(
                    exchangedItem,
                )
            }
        },
    },
    actions: {
        async init({ commit, state }) {
            const items = await api.twoavy.getCollectableItems('market')
            console.log(
                items.data.length + ' collectable market items from CMS',
            )

            const goettItems = (await api.goettingen.getItems(items.data)).data
                .docs

            const mappedItems = goettItems.map((item) => {
                return {
                    goettItem: item,
                    uuid: uuidv4(),
                    collectable: true,
                }
            })

            const marketItems = await api.twoavy.getContent(10)
            console.log(marketItems.data.data.length + ' market items from CMS')
            const preparedMarketItems = []
            if (marketItems.data.data.length) {
                for (const item of marketItems.data.data) {
                    const preparedItem = {
                        id: item.content.identifier.global,
                    }

                    const goettItem = (
                        await api.goettingen.getItem(
                            item.content.identifier.global,
                        )
                    ).data.docs[0]

                    preparedItem.goettItem = goettItem
                    preparedItem.beauty = item.content.beauty.global
                    preparedItem.monetary_value =
                        item.content.monetary_value.global
                    preparedItem.significance = item.content.significance.global

                    preparedMarketItems.push(preparedItem)
                    mappedItems.push({
                        goettItem,
                        uuid: uuidv4(),
                        collectable: false,
                    })
                }
            }

            console.log(mappedItems.length + ' market items in game')

            await commit('setItems', mappedItems)
            const rankings = (await api.twoavy.getMarketRanking()).data

            preparedMarketItems.forEach((item) => {
                rankings[item.id] = {
                    userRated: false,
                    beauty: {
                        rating: item.be,
                    },
                    monetary_value: {
                        rating: item.monetary_value,
                    },
                    significance: {
                        rating: item.significance,
                    },
                    size: {
                        rating: 2.5,
                    },
                    year: {
                        rating: 2.5,
                    },
                }
            })

            await commit('setRankings', rankings)
            const scores = (await api.twoavy.getMarketScores()).data
            await commit('setHighscores', scores)
        },
        resetState({ commit }) {
            commit('resetState')
        },
        resetUser({ commit }, userIndex) {
            commit('resetUser', userIndex)
        },
        setLanguage({ commit }, { userIndex, lang }) {
            commit('setLanguage', { userIndex, lang })
        },
        setHighscore({ commit }, highscore) {
            commit('setHighscore', highscore)
        },
        toggleSelectedItem({ commit, rootGetters }, payload) {
            commit('toggleSelectedItem', payload)
            debouncedStayAtUnit(rootGetters, payload.userIndex)
        },
        sortItem({ commit, rootGetters }, { userIndex, item, index }) {
            commit('sortItem', { userIndex, item, index })
            debouncedStayAtUnit(rootGetters, userIndex)
        },
        sortUserCards({ commit, getters }, userIndex) {
            console.log(getters)
            commit('sortUserCards', { userIndex, getters })
        },
        removeItem({ commit, rootGetters }, { userIndex, item }) {
            commit('removeItem', { userIndex, item })
            debouncedStayAtUnit(rootGetters, userIndex)
        },
        async addItemToUser({ commit, rootGetters, state, getters }, payload) {
            commit('addItemToUser', payload)
            const exchanges = [...state.exchanges]
            if (exchanges[payload.userIndex] === MAXNUMBEROFEXCHANGES) {
                console.log('send score to api')
                const user = rootGetters.getUserAtUnit(
                    `market_${payload.userIndex}`,
                )
                const score = getters['getScore'](payload.userIndex)
                await api.twoavy
                    .setMarketScores(
                        user.uuid,
                        state.sessions[payload.userIndex],
                        getTwoavyType(state.categories[payload.userIndex]),
                        state.userItems[payload.userIndex],
                        score,
                    )
                    .then((response) => {
                        commit('setHighscore', response.data)
                    })

                const steps = [...state.steps]
                steps[payload.userIndex] = STEPS.CONCLUSION
                state.steps = steps
            }
            debouncedStayAtUnit(rootGetters, payload.userIndex)
        },
        setStep({ commit, rootGetters }, payload) {
            commit('setStep', payload)
            debouncedStayAtUnit(rootGetters, payload.userIndex)
        },
        start({ commit, state, dispatch }, userIndex) {
            commit('setSession', { userIndex, session: uuidv4() })
            commit('setStepIntroduction', userIndex)

            // only allow categories that are not already in use
            const availableCategories = Object.keys(CATEGORIES).filter(
                (x) => !state.categories.includes(x),
            )

            console.log(availableCategories)

            const category =
                CATEGORIES[
                    availableCategories[
                        (availableCategories.length * Math.random()) << 0
                    ]
                ]

            console.log(category)

            commit('setCategory', { userIndex, category })
            // clear timeout in case it exists
            if (introductionTimeouts[userIndex]) {
                clearTimeout(introductionTimeouts[userIndex])
            }
            // set selection step after 5s
            introductionTimeouts[userIndex] = setTimeout(() => {
                dispatch('setStep', {
                    userIndex,
                    step: STEPS.SELECTION,
                })
            }, INTRODUCTIONDURATION)
        },
        addMarketItems({ commit }, userIndex) {
            commit('addMarketItems', userIndex)
        },
        confirmIntroduction({ commit }, userIndex) {
            // clear timeout in case it exists
            if (introductionTimeouts[userIndex]) {
                clearTimeout(introductionTimeouts[userIndex])
            }
            commit('setStep', { userIndex, step: STEPS.SELECTION })
        },
        confirmSort({ commit, dispatch, state, rootGetters }, userIndex) {
            const user = rootGetters.getUserAtUnit(`market_${userIndex}`)
            api.twoavy
                .setMarketRanking(
                    user.uuid,
                    state.sessions[userIndex],
                    getTwoavyType(state.categories[userIndex]),
                    state.userItems[userIndex].map((i) => i.goettItem.PI),
                )
                .then((response) => {
                    commit('setRankings', response.data)
                })

            // sort items
            console.log('sort items for user ' + userIndex)
            console.log(state.userItems[userIndex])

            dispatch('addMarketItems', userIndex)
            commit('setStep', { userIndex, step: STEPS.EXCHANGE })
        },
        confirmExchange({ commit, state, getters, rootGetters }, userIndex) {
            console.log('confirm exchange')
            const user = rootGetters.getUserAtUnit(`market_${userIndex}`)
            const score = getters['getScore'](userIndex)
            api.twoavy
                .setMarketScores(
                    user.uuid,
                    state.sessions[userIndex],
                    getTwoavyType(state.categories[userIndex]),
                    state.userItems[userIndex],
                    score,
                )
                .then((response) => {
                    commit('setHighscore', response.data)
                })

            commit('setStep', { userIndex, step: STEPS.CONCLUSION })
            clearTimeout(conclusionTimeouts[userIndex])
            /*conclusionTimeouts[userIndex] = setTimeout(() => {
                commit('setStep', { userIndex, step: STEPS.HIGHSCORE })
                clearTimeout(highscoreTimeouts[userIndex])
                highscoreTimeouts[userIndex] = setTimeout(() => {
                    commit('setStep', { userIndex, step: STEPS.FINISH })
                }, HIGHSCOREDURATION)
            }, CONCLUSIONDURATION)*/
        },
        confirmConclusion({ commit }, userIndex) {
            clearTimeout(conclusionTimeouts[userIndex])
            commit('setStep', { userIndex, step: STEPS.HIGHSCORE })
        },
        confirmHighscore({ commit }, userIndex) {
            clearTimeout(conclusionTimeouts[userIndex])
            clearTimeout(highscoreTimeouts[userIndex])
            commit('setStep', { userIndex, step: STEPS.FINISH })
        },
        discardCollection({ commit, dispatch }, userIndex) {
            commit('discardCollection', userIndex)
            if (logoutTimeouts[userIndex] != null) {
                clearTimeout(logoutTimeouts[userIndex])
            }
            logoutTimeouts[userIndex] = setTimeout(async () => {
                await api.twoavy.logoutFromUnit(`market_${userIndex}`)
                // trigger manually, user watcher does not receive old state to compare
                dispatch('unsetUser', `market_${userIndex}`, { root: true })
            }, 5000)
        },
        async saveCollection(
            { commit, dispatch, state, rootGetters },
            userIndex,
        ) {
            commit('saveCollection', userIndex)

            if (logoutTimeouts[userIndex] != null) {
                clearTimeout(logoutTimeouts[userIndex])
            }

            const user = rootGetters.getUserAtUnit(`market_${userIndex}`)

            if (state.exchangedItems[userIndex].length > 0) {
                const uncollectItemsPIs = state.exchangedItems[userIndex].map(
                    (exchangedItem) => {
                        return exchangedItem.oldItem
                    },
                )
                await api.twoavy.unCollectItems(user.uuid, uncollectItemsPIs)
                const collectItemsPIs = state.exchangedItems[userIndex].map(
                    (exchangedItem) => {
                        return exchangedItem.newItem
                    },
                )
                await api.twoavy.collectItems(user.uuid, collectItemsPIs)
            }

            logoutTimeouts[userIndex] = setTimeout(() => {
                api.twoavy.logoutFromUnit(`market_${userIndex}`)
                // trigger manually, user watcher does not receive old state to compare
                dispatch('unsetUser', `market_${userIndex}`, { root: true })
            }, 5000)
        },
        stayAtUnit({ rootGetters }, userIndex) {
            debouncedStayAtUnit(rootGetters, userIndex)
        },
        removeItemFromItemsArray({ commit, state }, uuidToRemove) {
            const newItems = state.items.filter((e) => e.uuid !== uuidToRemove)
            commit('removeItemFromItemsArray', newItems)
        },
        flipItemInUserArray(
            { commit, state, getters },
            { userIndex, placeholderIndex, cardUuid },
        ) {
            state.userItems[userIndex][placeholderIndex] =
                getters['getItemByUuid'](cardUuid)
        },
        incrementExchanges({ commit }, index) {
            commit('incrementExchanges', parseInt(index.userIndex))
        },
        setExchangedItem({ commit }, payload) {
            commit('setExchangedItem', payload)
        },
    },
    getters: {
        getAllItems: (state) => {
            // exchangeable items
            const items = [...state.items].filter((x) => !x.collectable)
            // selected items
            state.selectedItems.forEach((selectedItems, userIndex) => {
                if (
                    state.steps[userIndex] === STEPS.SORT ||
                    state.steps[userIndex] === STEPS.SELECTION
                ) {
                    selectedItems
                        .filter((item) => item != null)
                        .forEach((selectedItem) => {
                            items.push(selectedItem)
                        })
                }
            })
            //user items
            state.userItems.forEach((selectedItems) => {
                selectedItems
                    .filter(
                        (item) => item != null && Object.keys(item).length > 0,
                    )
                    .forEach((selectedItem) => {
                        items.push(selectedItem)
                    })
            })

            return items
        },
        getItems: (state) => {
            return state.items
        },
        getHighscore: (state) => (index) => {
            if (
                state.highscores.highScore[
                    getTwoavyType(state.categories[index])
                ].length !== 0
            ) {
                const selfStep = state.highscores.highScore[
                    getTwoavyType(state.categories[index])
                ].filter((e) => e.self === true)[0]
                if (selfStep?.position <= 5) {
                    return state.highscores.highScore[
                        getTwoavyType(state.categories[index])
                    ].slice(0, 5)
                } else {
                    return [
                        ...state.highscores.highScore[
                            getTwoavyType(state.categories[index])
                        ].slice(0, 3),
                        selfStep,
                    ]
                }
            } else {
                return { score: 'Noch kein Highscore verfügbar' }
            }
        },
        getScore: (state, getters) => (userIndex) => {
            const items = state.userItems[userIndex]
            const category = state.categories[userIndex]
            let sum = 0
            items.forEach((item) => {
                if (item) {
                    if (
                        [
                            CATEGORIES.BEAUTY,
                            CATEGORIES.MONETARYVALUE,
                            CATEGORIES.SIGNIFICANCE,
                        ].includes(category)
                    ) {
                        const value = getters['getRanking'](item, category)
                        if (value) {
                            sum += value
                        }
                    } else if (category === CATEGORIES.SIZE) {
                        const length = getMaxLengthInMm(item.goettItem) / 1000
                        sum += parseFloat(length.toFixed(2))
                    } else if (category === CATEGORIES.YEAR) {
                        const year = item.goettItem[FIELDS.YEAR]
                        if (year) {
                            sum += new Date().getFullYear() - year
                        }
                    }
                }
            })
            return sum
        },
        getStep: (state) => (index) => {
            return state.steps[index]
        },
        getExchanges: (state) => (index) => {
            return state.exchanges[index]
        },
        getCategory: (state) => (index) => {
            return state.categories[index]
        },
        getItem: (state) => (userIndex, index) => {
            return state.userItems[userIndex][index]
        },
        getItemByUuid: (state) => (uuid) => {
            let foundItem = state.items.find((item) => item.uuid === uuid)
            if (foundItem) {
                return foundItem
            }
            for (let userIndex = 0; userIndex < NUMBEROFUSERS; userIndex++) {
                state.userItems[userIndex].forEach((i) => {
                    if (i?.uuid === uuid) {
                        foundItem = i
                    }
                })
            }
            if (foundItem) {
                return foundItem
            }
        },
        getSelectedItems: (state) => (index) => {
            return state.selectedItems[index]
        },
        isSelected:
            (state) =>
            ({ userIndex, item }) => {
                let isSelected = false
                state.selectedItems[userIndex].forEach((i) => {
                    if (i?.uuid === item.uuid) {
                        isSelected = true
                    }
                })
                return isSelected
            },
        getUserItems: (state) => (index) => {
            return state.userItems[index]
        },
        getRemainingExchanges: (state) => (index) => {
            return MAXNUMBEROFEXCHANGES - state.exchanges[index]
        },
        getSelectedItemsCount: (state) => (index) => {
            let value = 0
            state.selectedItems[index].forEach((item) => {
                if (item != null) {
                    value++
                }
            })
            return value
        },
        getUserItemsCount: (state) => (index) => {
            let value = 0
            state.userItems[index].forEach((item) => {
                if (item != null) {
                    value++
                }
            })
            return value
        },
        hasValidSelectedItems: (state) => (index) => {
            let value = true
            state.userItems[index].forEach((item) => {
                if (item == null) {
                    value = false
                }
            })
            return value
        },
        getUserIndexByUuid: (state) => (uuid) => {
            let value = null
            state.userItems.forEach((userItems, index) => {
                userItems.forEach((userItem) => {
                    if (userItem?.uuid == uuid) {
                        value = index
                    }
                })
            })

            if (value == null) {
                state.selectedItems.forEach((selectedItems, index) => {
                    selectedItems.forEach((selectedItem) => {
                        if (selectedItem?.uuid == uuid) {
                            value = index
                        }
                    })
                })
            }
            return value
        },
        getUserIndexByItem: (state) => (item) => {
            const { uuid } = item
            let value = null
            state.userItems.forEach((userItems, index) => {
                userItems.forEach((userItem) => {
                    if (userItem?.uuid == uuid) {
                        value = index
                    }
                })
            })

            if (value == null) {
                state.selectedItems.forEach((selectedItems, index) => {
                    selectedItems.forEach((selectedItem) => {
                        if (selectedItem?.uuid == uuid) {
                            value = index
                        }
                    })
                })
            }
            return value
        },
        getCardIndex: (state) => (item) => {
            const { uuid } = item
            let value = null
            for (let userIndex = 0; userIndex < NUMBEROFUSERS; userIndex++) {
                state.userItems[userIndex].forEach((userItem, cardIndex) => {
                    if (userItem?.uuid === uuid) {
                        value = cardIndex
                    }
                })
                if (value == null) {
                    const step = state.steps[userIndex]
                    if (step === STEPS.SELECTION || step === STEPS.SORT) {
                        state.selectedItems.forEach((selectedItems) => {
                            selectedItems.forEach((selectedItem, cardIndex) => {
                                if (selectedItem?.uuid === uuid) {
                                    value = cardIndex
                                }
                            })
                        })
                    }
                }
            }
            return value
        },
        getCardIndexByUuid: (state) => (uuid) => {
            let value = null
            for (let userIndex = 0; userIndex < NUMBEROFUSERS; userIndex++) {
                state.userItems[userIndex].forEach((userItem, cardIndex) => {
                    if (userItem?.uuid === uuid) {
                        value = cardIndex
                    }
                })
                if (value == null) {
                    const step = state.steps[userIndex]
                    if (step === STEPS.SELECTION || step === STEPS.SORT) {
                        state.selectedItems.forEach((selectedItems) => {
                            selectedItems.forEach((selectedItem, cardIndex) => {
                                if (selectedItem?.uuid === uuid) {
                                    value = cardIndex
                                }
                            })
                        })
                    }
                }
            }
            return value
        },
        getRanking: (state) => (item, category) => {
            const type = getTwoavyType(category)
            const PI = item?.goettItem?.PI
            if (state.rankings) {
                const rankingsForItem = state.rankings[PI]
                if (rankingsForItem) {
                    return rankingsForItem[type]?.rating
                }
                return null
            }
            return null
        },
        getSession: (state) => (userIndex) => state.sessions[userIndex],
        getCountOfNonNullValues: (state) => (userIndex) => {
            const nonNulls = state.userItems[userIndex].filter(
                (e) => e !== null,
            )
            return nonNulls.length
        },
        getUserOwnsItem: (state) => (userIndex, itemUUID) => {
            const flatarray = state.selectedItems[userIndex]
                .filter((e) => e !== null)
                .map((e) => e.uuid)
            return flatarray.includes(itemUUID)
        },
        getUserLanguage: (state) => (userIndex) => {
            return state.userLanguages[userIndex]
                ? state.userLanguages[userIndex]
                : 'de'
        },
    },
}

export { CATEGORIES, STEPS, NUMBEROFUSERS, NUMBEROFSLOTS, MAXNUMBEROFEXCHANGES }
