import api from '../../../mixins/api'

const getInitialState = () => {
    return {
        somestate: 0,
        debates: [],
    }
}
export default {
    namespaced: true,
    state: getInitialState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getInitialState())
        },
        init(state, debates) {
            state.debates = debates
        },
    },
    actions: {
        resetState({ commit }) {
            commit('resetState')
        },
        init({ commit }) {
            api.twoavy.getSaloonDebates().then((r) => {
                commit('init', r.data.data)
            })
        },
    },
    getters: {},
}
