<template>
    <div id="slider-distance" slider>
        <div>
            <div progressbar style="width: 100%" />
            <div v-if="showDraggedAmount" range :style="rangeStyle" />
            <span thumb :style="thumbStyle" />
        </div>

        <input
            v-model="sliderValue"
            class="min"
            type="range"
            :min="min"
            :max="max"
            :step="step"
        />
        <span v-if="!hideLabel" class="label sliderLabel">
            {{ sliderValue }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'Slider',
    props: {
        value: {
            type: Number,
            default: 0,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 1,
        },
        step: {
            type: Number,
            default: 1,
        },
        showDraggedAmount: {
            type: Boolean,
            default: false,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            thumbStyle: 'left:0%',
            rangeStyle: 'left:0%',
            delay: null,
            sliderValue: this.min,
            oldValGtNewVal: false,
        }
    },
    watch: {
        sliderValue(val) {
            this.$emit('input', val)
            let value = ((val - this.min) / (this.max - this.min)) * 100
            this.thumbStyle = 'left:' + value + '%'
            this.rangeStyle = 'width:' + value + '%;'
        },
    },
    methods: {
        setValue(val) {
            this.sliderValue = val
        },
    },
}
</script>

<style lang="scss" scoped>
[slider] {
    position: relative;
    height: calc(14vw * var(--pxToVw));
    border-radius: calc(10vw * var(--pxToVw));
    text-align: left;
    margin: 0;
}

[slider] > div {
    position: absolute;
    left: calc(13vw * var(--pxToVw));
    right: calc(15vw * var(--pxToVw));
    height: calc(14vw * var(--pxToVw));
}

[slider] > div > [progressbar] {
    position: absolute;
    left: 0;
    height: calc(10vw * var(--pxToVw));
    border-radius: 0;
    background-color: var(--lightestgrey);
    margin: 0 calc(5vw * var(--pxToVw));
}

[slider] > div > [range] {
    position: absolute;
    left: 0;
    height: calc(10vw * var(--pxToVw));
    background-color: var(--mediumgrey);
    z-index: 1;
}

[slider] > div > [thumb] {
    position: absolute;
    top: calc(-12vw * var(--pxToVw));
    z-index: 2;
    height: calc(32vw * var(--pxToVw));
    width: calc(32vw * var(--pxToVw));
    text-align: left;
    margin-left: calc(-11vw * var(--pxToVw));
    cursor: pointer;
    box-shadow: 0 calc(3vw * var(--pxToVw)) calc(8vw * var(--pxToVw))
        rgba(0, 0, 0, 0.4);
    background-color: #fff;
    outline: none;
    border: calc(3vw * var(--pxToVw)) solid #939393;
}

[slider] > input[type='range'] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 3;
    height: calc(10vw * var(--pxToVw));
    top: calc(-2vw * var(--pxToVw));
    width: 100%;
    opacity: 0;
}

div[slider] > input[type='range']:focus::-webkit-slider-runnable-track {
    background: transparent;
    border: transparent;
}

div[slider] > input[type='range']:focus {
    outline: none;
}

div[slider] > input[type='range']::-webkit-slider-thumb {
    pointer-events: all;
    height: calc(32vw * var(--pxToVw));
    width: calc(32vw * var(--pxToVw));
    border-radius: 0;
    border: 0 none;
    -webkit-appearance: none;
}

.sliderLabel {
    width: 100px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(20vw * var(--pxToVw));
    padding-left: calc(10vw * var(--pxToVw));
    right: calc(-100vw * var(--pxToVw));
    text-align: left;
}

input[type='range'] {
    pointer-events: none;
}
</style>
