var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"gameTaxonomy fill-parent"},[_c('cupboards-game-header',{attrs:{"game-title":_vm.$t('cupboards_taxonomy_title')}}),_c('div',{staticClass:"content"},[(
                _vm.$store.state.cupboardsGameTaxonomy.step ===
                    _vm.STEPS.CONCLUSION ||
                _vm.$store.state.cupboardsGameTaxonomy.step === _vm.STEPS.SAVED
            )?_c('success-animation',{ref:"container",staticClass:"fill-parent"}):_c('texts'),(_vm.$store.state.cupboardsGameTaxonomy.step === _vm.STEPS.SELECT)?[_c('div',{staticClass:"slots"},[_c('img',{attrs:{"src":require("@/assets/export/case-leer.png"),"alt":""}}),_vm._l((_vm.$store.state
                        .cupboardsGameTaxonomy.selectedItems),function(item,index){return _c('div',{key:'selected_item_slot_' + index,ref:`selectedItemSlot_${index}`,refInFor:true,class:`selectedItemSlot selectedItemSlot_${index}`},[_c('div',{staticClass:"handle"})])})],2),_c('div',{staticClass:"items",on:{"touchmove":_vm.dragUpdate,"touchend":_vm.dragEnd,"mouseleave":_vm.dragEnd}},_vm._l((_vm.$store.state
                        .cupboardsGameTaxonomy.items),function(item,index){return _c('div',{key:item.goettItem.PI + index,staticClass:"item rounded-circle",style:(_vm.getItemStyle(item)),attrs:{"id":item.goettItem.PI},on:{"touchstart":function($event){$event.preventDefault();$event.stopPropagation();return _vm.dragStart(item, $event)}}},[_c('img',{staticClass:"pointer-events-none",attrs:{"src":_vm.api.goettingen.getThumbnailUrl(item.goettItem),"width":"100px","height":"100px"}})])}),0),_c('div',{staticClass:"name d-flex justify-content-center align-items-center"},[_c('div',[_c('p',{staticClass:"m-0 mb-1"},[_vm._v(" "+_vm._s(_vm.$t('cupboards_taxonomy_name_label'))+" ")]),_c('h3',{staticClass:"value m-0"},[_vm._v(" "+_vm._s(_vm.$store.state.cupboardsGameTaxonomy.name !== '' ? _vm.$store.state.cupboardsGameTaxonomy.name : _vm.$t('cupboards_taxonomy_defaultname'))+" ")])]),_c('div',{staticClass:"ml-3 fowi-btn fowi-btn-primary fowi-btn-sq48",class:{ disabled: !_vm.isEnabled() },on:{"click":_vm.saveCollection}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'cloud-upload'],"size":"lg"}})],1)])]:_vm._e()],2),(_vm.debug)?[_c('div',{staticClass:"possible-positions pointer-events-none"}),_vm._l((3),function(i){return _c('div',{key:'excluded_' + i,staticClass:"exclude pointer-events-none",class:'exclude-' + i})})]:_vm._e(),(_vm.$store.state.cupboardsGameTaxonomy.step === _vm.STEPS.SELECT)?_c('cupboards-game-footer'):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }