<template>
    <div
        id="confirm-popup"
        class="position-absolute d-flex justify-content-center align-items-center h-100 w-100"
        @click="clickedBackground"
    >
        <div
            v-if="getContent"
            :class="getContent.image ? 'wide' : ''"
            class="popup-container"
            :style="getStyle"
        >
            <div class="content d-flex flex-column overflow-auto px-5 py-4">
                <h2 v-if="getContent.text" v-html="getContent.text" />
                <div
                    v-if="getContent.image"
                    class="image-container h-100 w-auto"
                >
                    <img class="w-100" :src="getContent.image" />
                </div>
                <div v-if="getContent.btns" class="mt-3 btn-box d-flex">
                    <div
                        v-for="btn in getContent.btns"
                        :key="'btn_' + btn.value"
                        :class="
                            btn.icon && !btn.label
                                ? 'fowi-btn-sq48'
                                : 'fowi-btn-h48'
                        "
                        class="fowi-btn fowi-btn-primary mr-3"
                        @click.prevent.stop="triggerEvent(btn.value)"
                    >
                        <font-awesome-icon v-if="btn.icon" :icon="btn.icon" />
                        <p
                            v-if="btn.label"
                            :class="btn.icon ? 'pl-3' : ''"
                            class="text-white m-0"
                        >
                            {{ btn.label }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/**
 ERKLÄRUNG

 Inhalt:
 {
    text: 'Wollen Sie die Zeichnung wirklich löschen?',
    image: 'base64 image or url'
    width: 1000,
    btns: {
        0: {
            icon: ['far', 'check'],
            label: 'text'
            value: true,
        },
        1: {
            icon: ['far', 'times'],
            value: false,
        },
        2: {
            label: 'text'
            value: 'between',
        },
    },
    timer: { time: time in sec, event: root event},
}

 Beliebig viele Buttons möglich, value wird wie unter value eingegeben zurück gegeben.

 Zum aufrufen:
 this.$store.dispatch('setConfirmPopup', Inhalt)

 **/
export default {
    name: 'ConfirmPopup',
    data() {
        return {
            timer: null,
        }
    },
    computed: {
        getContent() {
            return this.$store.getters.getConfirmPopup
        },
        getStyle() {
            return this.getContent.width
                ? 'width: ' + this.getContent.width + 'px;'
                : ''
        },
    },
    mounted() {
        if (this.getContent.timer) {
            this.timer = setTimeout(() => {
                this.$root.$emit('confirm', this.getContent.timer.event)
                this.$store.dispatch('resetConfirmPopup')
            }, this.getContent.timer.time * 1000)
        }
    },
    beforeDestroy() {
        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = null
        }
        this.$store.dispatch('resetConfirmPopup')
    },
    methods: {
        triggerEvent(ev) {
            this.$store.dispatch('resetConfirmPopup')
            this.$root.$emit('confirm', ev)
        },
        clickedBackground() {
            if (this.getContent.timer && this.getContent.btns.length <= 1) {
                this.$root.$emit('confirm', this.getContent.timer.event)
                this.$store.dispatch('resetConfirmPopup')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
#confirm-popup {
    z-index: 10000;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);

    .popup-container {
        background-color: var(--primary);
        width: calc(548vw * var(--pxToVw));
        text-align: left;
        overflow-y: hidden;

        &.wide {
            width: calc(1200vw * var(--pxToVw));
        }
    }
}

.dialog-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
}

.dialog-mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
</style>
