<template>
    <div
        v-if="manifest"
        id="iiif-viewer"
        :class="!analysis ? 'full-height vw-100' : ''"
        class="d-flex justify-content-end flex-column align-items-center"
    >
        <div ref="iiif" class="d-flex viewer-container" :style="containerSize">
            <transition-group name="fade" mode="out-in">
                <iiif-post-it
                    v-if="!autoactive && !analysis && generatePostItsTop"
                    :key="'iiif-viewer-post-its-top-' + index"
                    :even-page-id="evenPageId"
                    :odd-page-id="oddPageId"
                    :post-its="generatePostItsTop"
                    position="top"
                    @click="jumpToPage"
                />
                <iiif-post-it
                    v-if="!autoactive && !analysis && generatePostItsBottom"
                    :key="'iiif-viewer-post-its-bottom-' + index"
                    :even-page-id="evenPageId"
                    :odd-page-id="oddPageId"
                    :post-its="generatePostItsBottom"
                    position="bottom"
                    @click="jumpToPage"
                />
            </transition-group>
            <transition name="fade" mode="out-in">
                <div :key="'iiif-viewer-' + index" class="d-flex">
                    <iiif-viewer-page
                        v-if="manifest.sequences[0].canvases[index]"
                        :customized-url="customUrl()"
                        :height="height"
                        :show-transcription="showTranscription"
                        :transcription="transcription.left"
                        :manifest-id="manifest['@id']"
                        :manifest-page="manifest.sequences[0].canvases[index]"
                        :edit-mode="$store.getters['desk/getIsLoggedIn']"
                        :annotations="
                            getPageAnnotation(
                                manifest.sequences[0].canvases[index].images[0]
                                    .resource.service['@id'],
                            )
                        "
                    />
                    <iiif-viewer-page
                        v-if="
                            manifest.sequences[0].canvases[index + 1] &&
                            index !== 0
                        "
                        :customized-url="customUrl()"
                        :height="height"
                        :show-transcription="showTranscription"
                        :transcription="transcription.right"
                        :manifest-id="manifest['@id']"
                        :manifest-page="
                            manifest.sequences[0].canvases[index + 1]
                        "
                        :edit-mode="$store.getters['desk/getIsLoggedIn']"
                        :annotations="
                            getPageAnnotation(
                                manifest.sequences[0].canvases[index + 1]
                                    .images[0].resource.service['@id'],
                            )
                        "
                    />
                </div>
            </transition>
            <div class="preload">
                <img
                    v-if="manifest.sequences[0].canvases[index + 1]"
                    :src="
                        manifest.sequences[0].canvases[index + 1].images[0]
                            .resource.service['@id'] +
                        '/full/,' +
                        height +
                        '/0/default.jpg'
                    "
                />
                <img
                    v-if="manifest.sequences[0].canvases[index + 2]"
                    :src="
                        manifest.sequences[0].canvases[index + 2].images[0]
                            .resource.service['@id'] +
                        '/full/,' +
                        height +
                        '/0/default.jpg'
                    "
                />
            </div>
        </div>
        <template v-if="!autoactive">
            <div class="d-flex justify-content-center align-items-center">
                <div
                    class="fowi-btn fowi-btn-natural fowi-btn-sq48 mr-4"
                    :class="index === 0 ? 'disabled' : ''"
                    @click="prevPage"
                >
                    <font-awesome-icon
                        :icon="['far', 'angle-left']"
                        size="2x"
                    />
                </div>
                <h3 class="p-0 m-0 mr-4">
                    {{ $t('desk_page') }} {{ index + 1 }} / {{ lastIndex + 1 }}
                </h3>
                <div
                    class="fowi-btn fowi-btn-natural fowi-btn-sq48"
                    :class="index === lastIndex - 1 ? 'disabled' : ''"
                    @click="nextPage"
                >
                    <font-awesome-icon
                        :icon="['far', 'angle-right']"
                        size="2x"
                    />
                </div>
            </div>
            <div
                v-if="!autoactive"
                class="iiif-viewer-sub-menu d-flex"
                :class="analysis ? 'analysis' : ''"
            >
                <div
                    v-if="analysis"
                    class="fowi-btn fowi-btn-primary fowi-btn-sq48 mr-2"
                    :class="
                        transcription.left === '' && transcription.right === ''
                            ? 'disabled'
                            : ''
                    "
                    @click="toggleShowTranscription"
                >
                    <font-awesome-icon
                        :icon="[
                            'far',
                            showTranscription ? 'remove-format' : 'text',
                        ]"
                        size="lg"
                    />
                </div>
                <div
                    class="fowi-btn fowi-btn-primary btn-fowi-structures fowi-btn-sq48 mr-2"
                    :class="[
                        typeof manifest.structures !== 'object'
                            ? 'disabled'
                            : '',
                        structureModalOpen ? 'active text-white' : '',
                    ]"
                    @click="openStructureModal"
                >
                    <font-awesome-icon :icon="['far', 'list-ul']" size="lg" />
                </div>
                <div
                    class="fowi-btn fowi-btn-primary btn-fowi-metadata fowi-btn-sq48 mr-2"
                    :class="metadataModalOpen ? 'active text-white' : ''"
                    @click="openMetadataModal"
                >
                    <font-awesome-icon
                        :icon="['far', 'info-circle']"
                        size="2x"
                    />
                </div>
            </div>
            <iiif-structures
                v-if="manifest.structures"
                :current-pages="getPageIds"
                :open-modal="structureModalOpen"
                :structures="manifest.structures"
                @close="closeStructureModal"
                @setNewPage="jumpToPageByCanvasId"
            />
            <iiif-metadata
                v-if="manifest.metadata"
                :label="manifest.label"
                :open-modal="metadataModalOpen"
                :metadata="manifest.metadata"
                @close="closeMetadataModal"
            />
        </template>
    </div>
</template>

<script>
import IiifMetadata from './IiifMetadata'
import IiifStructures from './IiifStructures'
import IiifPostIt from './iiifPostIt'
import IiifViewerPage from './iiifViewerPage'
import * as d3 from 'd3'

export default {
    name: 'IiifViewer',
    components: {
        IiifStructures,
        IiifViewerPage,
        IiifPostIt,
        IiifMetadata,
    },
    props: {
        autoactive: {
            type: Boolean,
            default: false,
        },
        analysis: {
            type: Boolean,
            default: false,
        },
        manifest: {
            type: Object,
            required: true,
        },
        annotations: {
            type: Array,
            default: () => [],
        },
        height: {
            type: Number,
            default: 700,
        },
        duration: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            autoactiveTimer: null,
            index: 0,
            lastIndex: 0,
            showTranscription: true,
            metadataModalOpen: false,
            structureModalOpen: false,
            transcription: {
                left: '',
                right: '',
            },
        }
    },
    computed: {
        getPageIds() {
            if (
                this.manifest.sequences[0].canvases[this.index + 1] &&
                this.index !== 0
            ) {
                return [
                    this.manifest.sequences[0].canvases[this.index]['@id'],
                    this.manifest.sequences[0].canvases[this.index + 1]['@id'],
                ]
            }
            return [this.manifest.sequences[0].canvases[this.index]['@id']]
        },
        generatePostItsTop() {
            if (this.annotations?.length > 0) {
                return Array.from(d3.group(this.annotations, (i) => i.imageUrl))
                    .map((x) => {
                        return {
                            page: x[0],
                            label: parseInt(
                                x[0].split(':')[x[0].split(':').length - 1],
                            ),
                        }
                    })
                    .splice(0, 10)
                    .sort(function (a, b) {
                        return parseFloat(b['label']) - parseFloat(a['label'])
                    })
            }
            return ''
        },
        generatePostItsBottom() {
            if (this.annotations?.length > 0) {
                return Array.from(d3.group(this.annotations, (i) => i.imageUrl))
                    .map((x) => {
                        return {
                            page: x[0],
                            label: parseInt(
                                x[0].split(':')[x[0].split(':').length - 1],
                            ),
                        }
                    })
                    .splice(10, 10)
                    .sort(function (a, b) {
                        return parseFloat(b['label']) - parseFloat(a['label'])
                    })
            }
            return ''
        },
        evenPageId() {
            const evenPageId =
                this.manifest.sequences[0].canvases[this.index].images[0]
                    .resource.service['@id']
            return parseInt(
                evenPageId.split(':')[evenPageId.split(':').length - 1],
            )
        },
        oddPageId() {
            if (this.index > 0 && this.index + 1 <= this.lastIndex) {
                const oddPageId =
                    this.manifest.sequences[0].canvases[this.index + 1]
                        .images[0].resource.service['@id']
                return parseInt(
                    oddPageId.split(':')[oddPageId.split(':').length - 1],
                )
            }
            return -1
        },
        containerSize() {
            return {
                height: 'calc(' + this.height + 'vw * var(--pxToVw))',
                width: 'auto',
            }
        },
        currentUser() {
            return this.$store.getters.getUserAtUnit(this.$route.name)
        },
    },
    watch: {
        async index() {
            if (this.analysis) {
                this.$emit('newText', '')
                let responseText
                responseText = this.transcription.left = await this.fetchText(
                    this.index,
                )
                if (this.index > 0) {
                    responseText += this.transcription.right =
                        await this.fetchText(this.index + 1)
                }
                if (responseText !== '') {
                    this.$emit('newText', responseText)
                }
            }
        },
    },
    async mounted() {
        let leftOffset = null

        this.$root.$on('open_annotation', (e) => {
            if (!this.$store.state.desk.isSinglePage) {
                leftOffset =
                    this.$store.state.desk.openInfoModalOnSide === 'left'
                        ? 39
                        : 61
            } else {
                leftOffset =
                    this.$store.state.desk.openInfoModalOnSide === 'left'
                        ? 45
                        : 600
            }

            if (this.$refs.iiif) {
                this.moveTo(50, leftOffset)
            }
        })
        this.$root.$on('close_annotation', () => {
            if (this.$refs.iiif) {
                this.moveTo(leftOffset, 50)
            }
        })

        this.lastIndex = this.manifest.sequences[0].canvases.length - 1

        if (this.autoactive) {
            this.autoactiveTimer = setInterval(() => {
                if (this.index < this.lastIndex - 1) {
                    this.nextPage()
                } else {
                    this.jumpToPage(0)
                }
            }, this.duration * 1000)
        }
    },
    beforeDestroy() {
        if (this.autoactive) {
            clearInterval(this.autoactiveTimer)
            this.autoactiveTimer = null
        }
    },
    methods: {
        async fetchText(index) {
            let responseData
            let responseText
            await fetch(
                this.manifest.sequences[0].canvases[index].otherContent[0][
                    '@id'
                ],
            )
                .then((response) => response.json())
                .then((data) => {
                    responseData = data
                })
            await fetch(responseData.resources[0].resource['@id'])
                .then((response) => response.text())
                .then((data) => {
                    responseText = data
                })
            return responseText
        },
        customUrl() {
            // 'http://meta.dev.kustodie.uni-goettingen.de/image/v2/'
            return ''
        },
        getPageAnnotation(pageId) {
            return this.annotations?.filter((x) => x.imageUrl === pageId)
        },
        prevPage() {
            if (this.index !== 1) {
                this.index = this.index - 2
            } else if (this.index !== 0) {
                this.index--
            }
        },
        nextPage() {
            if (this.index > 0) {
                this.index = this.index + 2
            } else {
                this.index++
            }
        },
        jumpToPage(index) {
            if (index === 1 || index % 2 === 0) {
                this.index = index - 1
            } else {
                this.index = index - 2
            }
        },
        openStructureModal() {
            if (this.structureModalOpen) {
                this.structureModalOpen = false
                return
            }
            this.metadataModalOpen = false
            this.structureModalOpen = true

            this.$userLog.saveUserLog(
                'desk_open_structures',
                this.currentUser.uuid,
                {
                    game: this.$store.getters['desk/getGame'],
                    id: this.$store.getters['desk/getItem'].id,
                    structureId: this.$store.getters['desk/getItem'].content.id,
                },
            )
        },
        closeStructureModal() {
            this.structureModalOpen = false
        },
        openMetadataModal() {
            if (this.metadataModalOpen) {
                this.metadataModalOpen = false
                return
            }
            this.structureModalOpen = false
            this.metadataModalOpen = true

            this.$userLog.saveUserLog(
                'desk_open_metadata',
                this.currentUser.uuid,
                {
                    game: this.$store.getters['desk/getGame'],
                    id: this.$store.getters['desk/getItem']?.id,
                    structureId: this.$store.getters['desk/getItem'].content.id,
                },
            )
        },
        closeMetadataModal() {
            this.metadataModalOpen = false
        },
        jumpToPageByCanvasId(canvasId) {
            const index = this.manifest.sequences[0].canvases.findIndex(
                (x) => x['@id'] === canvasId,
            )
            if (index > -1) {
                this.jumpToPage(index)
            }
        },
        toggleShowTranscription() {
            this.showTranscription = !this.showTranscription

            this.$userLog.saveUserLog(
                this.showTranscription
                    ? 'desk_open_transcription'
                    : 'desk_close_transcription',
                this.currentUser.uuid,
                {
                    game: this.$store.getters['desk/getGame'],
                    page: this.index + 1,
                    structureId: this.$store.getters['desk/getItem'].content.id,
                },
            )
        },
        moveTo(fromValue, toValue) {
            this.$refs.iiif.animate(
                [
                    {
                        left: `${fromValue}%`,
                        easing: 'ease-in-out',
                    },
                    {
                        left: `${toValue}%`,
                        easing: 'ease-in-out',
                    },
                ],
                {
                    duration: 500,
                    fill: 'forwards',
                },
            )
        },
    },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/global';

#iiif-viewer {
    overflow: hidden;

    &.full-height {
        margin-top: calc(25vw * var(--pxToVw));
        height: calc(1030vw * var(--pxToVw));
    }

    .viewer-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .preload {
        img {
            width: 1px;
            height: 1px;
        }

        width: 1px;
        height: 1px;
        position: absolute;
        opacity: 0;
        pointer-events: none;
        top: 100%;
    }

    .iiif-viewer-sub-menu {
        position: absolute;
        bottom: calc(25vw * var(--pxToVw));
        right: calc((50vw + 48vw) * var(--pxToVw));

        &.analysis {
            bottom: calc(0vw * var(--pxToVw));
            right: calc(50vw * var(--pxToVw));
        }
    }
}
</style>
