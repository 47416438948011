<template>
    <div class="select" :tabindex="tabindex" @blur="open = false">
        <div class="value clickable text-nowrap" @click="toggleOpen">
            <div class="label">
                {{
                    options.find((option) => option.value === value)
                        ? options.find((option) => option.value == value).label
                        : 'not found'
                }}
            </div>
            <font-awesome-icon :icon="['far', 'angle-down']" size="lg" />
        </div>
        <ul v-show="open" class="options">
            <li
                v-for="option in options"
                :key="option.label"
                class="option"
                @click="onOptionSelected(option)"
            >
                {{ option.label }}
            </li>
        </ul>
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
    components: { FontAwesomeIcon },
    props: {
        options: {
            type: Array,
            required: false,
            default: () => null,
        },
        value: {
            type: [Object, String],
            required: false,
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    emits: ['valueChanged'],
    data() {
        return {
            open: false,
        }
    },
    mounted() {},
    methods: {
        onOptionSelected(option) {
            this.$emit('valueChanged', option.value)
            this.open = false
        },
        toggleOpen() {
            this.open = !this.open
        },
    },
}
</script>
<style scoped lang="scss">
.value {
    padding: 16px;
    display: flex;
    .label {
        overflow: auto;
        flex-grow: 1;
        margin-right: 5px;
    }
}
.options {
    position: absolute;
    /* background-color: var(--mediumgrey); */
    background-color: var(--white);
    color: var(--black);
    left: 0;
    right: 0;
    z-index: 999999;
    list-style-type: none;
    max-height: 512px;
    overflow: auto;
    border-bottom: 1px solid black;
    padding-left: 0;
    .option {
        border-bottom: 1px solid black;
        padding: 16px;
    }
}
</style>
