<template>
    <div class="h-100 w-100">
        <transition name="fade" mode="in-out">
            <p
                :key="getFeedback"
                class="station-feedback"
                v-html="$t(getFeedback)"
            />
        </transition>
        <div class="micro-wrapper">
            <div
                ref="neededCircleWidthHeight"
                class="overlay-grid"
                :style="{
                    width:
                        showAnimation || showStates
                            ? imageSizes.width + 'px'
                            : '40vw',
                    height:
                        showAnimation || showStates
                            ? imageSizes.height + 'px'
                            : '40vw',
                }"
            >
                <div
                    ref="actualImageToCalc"
                    class="actual-image"
                    :style="{
                        opacity: 0,
                        zIndex: -1,
                        width:
                            showAnimation || showStates
                                ? imageSizes.width + 'px'
                                : '40vw',
                        height:
                            showAnimation || showStates
                                ? imageSizes.height + 'px'
                                : '40vw',
                    }"
                >
                    <svg
                        :width="imageSizes.width * zoomFactor + 'px'"
                        :height="imageSizes.height * zoomFactor + 'px'"
                    ></svg>
                </div>
                <div
                    ref="actualImage"
                    class="actual-image"
                    :style="{
                        zIndex: 10,
                        width:
                            showAnimation || showStates
                                ? imageSizes.width + 'px'
                                : '40vw',
                        height:
                            showAnimation || showStates
                                ? imageSizes.height + 'px'
                                : '40vw',
                    }"
                >
                    <svg
                        ref="imageRef"
                        :width="imageSizes.width * zoomFactor + 'px'"
                        :height="imageSizes.height * zoomFactor + 'px'"
                        @touchmove="touchMove"
                    >
                        <rect
                            x="0"
                            y="0"
                            width="100%"
                            height="100%"
                            fill="#ffffff"
                            fill-opacity="1"
                        />
                        <image
                            ref="imageInSvg"
                            x="0"
                            y="0"
                            height="100%"
                            width="100%"
                            :mask="showStates && 'url(#savedCircles)'"
                            :xlink:href="imageUrl"
                            opacity="1"
                        />
                        <mask
                            id="savedCircles"
                            width="100%"
                            height="100%"
                            x="0"
                            y="0"
                        >
                            <rect
                                ref="animateWhiteOverlayOut"
                                width="100%"
                                height="100%"
                                x="0"
                                y="0"
                                fill="#fff"
                                fill-opacity="1"
                            />
                            <circle
                                v-for="(circle, index) in savedZoomState"
                                :key="`circle_${index}`"
                                :cx="circle.pos.x * imageSizes.width"
                                :cy="circle.pos.y * imageSizes.height"
                                :r="circle.size * 500"
                                stroke-width="300"
                                opacity="1"
                                fill="#fff"
                            />
                        </mask>
                        <circle
                            v-if="
                                savedZoomState && (showStates || showAnimation)
                            "
                            ref="lastCircle"
                            :transform="`translate(${
                                savedZoomState[savedZoomState.length - 1].pos
                                    .x *
                                imageSizes.width *
                                zoomFactor
                            } ${
                                savedZoomState[savedZoomState.length - 1].pos
                                    .y *
                                imageSizes.height *
                                zoomFactor
                            })`"
                            cx="0"
                            cy="0"
                            :r="
                                savedZoomState[savedZoomState.length - 1].size *
                                500 *
                                zoomFactor
                            "
                            fill="0"
                            fill-opacity="0"
                            stroke-width="5"
                            stroke="yellow"
                        >
                            <animateTransform
                                ref="startCircleAnimation"
                                attributeName="transform"
                                attributeType="XML"
                                type="translate"
                                :from="`${
                                    savedZoomState[savedZoomState.length - 1]
                                        .pos.x *
                                    imageSizes.width *
                                    zoomFactor
                                } ${
                                    savedZoomState[savedZoomState.length - 1]
                                        .pos.y *
                                    imageSizes.height *
                                    zoomFactor
                                }`"
                                :to="`${
                                    savedZoomState[savedZoomState.length - 1]
                                        .pos.x * imageSizes.width
                                } ${
                                    savedZoomState[savedZoomState.length - 1]
                                        .pos.y * imageSizes.height
                                }`"
                                dur="1.5s"
                                fill="freeze"
                                begin="indefinitely"
                            />
                        </circle>
                    </svg>
                </div>
            </div>
            <div v-if="!showStates" class="slider-save">
                <div style="width: 100%">
                    <slider
                        v-model.number="zoomFactor"
                        :min="startZoomFactor"
                        :max="maxZoomFactor"
                        :step="0.01"
                        show-dragged-amount
                        hide-label
                    />
                </div>
                <div class="action-buttons-container mx-1 d-flex">
                    <div
                        v-if="!showStates"
                        :class="!canBeSaved ? 'disabled' : ''"
                        class="fowi-btn fowi-btn-primary fowi-btn-sq48"
                        @click="save"
                    >
                        <font-awesome-icon
                            :icon="['far', 'cloud-upload']"
                            size="lg"
                        />
                    </div>
                </div>
            </div>
            <div v-else class="balance-height-on-show-results"></div>
        </div>
    </div>
</template>

<script>
import api from '@/mixins/api'
import Slider from '@/components/global/Slider.vue'
import * as d3 from 'd3'

export default {
    name: 'Microscope',
    components: { Slider },
    data() {
        return {
            imageReady: false,
            startZoomFactor: 1.0,
            maxZoomFactor: 4,
            portraitMode: null,
            zoomFactor: 1.0,
            showAnimation: false,
            showStates: false,
            imageUrl: null,
            imageSizes: { width: 1000, height: 1000 },
            lastDistanceBetweenFingers: 0,
            canBeSaved: false,
        }
    },
    computed: {
        getFeedback() {
            return this.showAnimation
                ? 'atelier-microscope-outro'
                : 'atelier-microscope-intro'
        },
        savedZoomState() {
            const imgData = this.$store.getters[
                'atelier/getMicroscopeViewedPositions'
            ](this.$store.state.atelier.item.id)
            return imgData ? imgData.positions : null
        },
        currentUser() {
            return this.$store.getters.getUserAtUnit(this.$route.name)
        },
    },
    watch: {
        zoomFactor: function (newValue, oldValue) {
            this.canBeSaved = this.zoomFactor / this.maxZoomFactor >= 0.3
            if (newValue && oldValue && this.$refs?.actualImage) {
                const newWidth = this.imageSizes.width * newValue
                let offsetLeft
                const scrollLeft = this.$refs.actualImage.scrollLeft
                const scrollRight =
                    this.imageSizes.width * oldValue -
                    this.$refs.actualImage.scrollLeft -
                    this.$refs.actualImage.clientWidth
                if (scrollLeft > 0 && scrollRight > 0) {
                    console.log(scrollLeft, scrollRight)
                    offsetLeft =
                        (scrollLeft / (scrollLeft + scrollRight)) *
                        (newWidth - this.$refs.actualImage.clientWidth)
                } else {
                    offsetLeft =
                        (newWidth - this.$refs.actualImage.clientWidth) / 2
                }
                this.$refs.actualImage.scrollLeft = offsetLeft

                const newHeight = this.imageSizes.height * newValue
                let offsetTop
                const scrollTop = this.$refs.actualImage.scrollTop
                const scrollBottom =
                    this.imageSizes.height * oldValue -
                    this.$refs.actualImage.scrollTop -
                    this.$refs.actualImage.clientHeight
                if (scrollTop > 0 && scrollBottom > 0) {
                    offsetTop =
                        (scrollTop / (scrollTop + scrollBottom)) *
                        (newHeight - this.$refs.actualImage.clientHeight)
                } else {
                    offsetTop =
                        (newHeight - this.$refs.actualImage.clientHeight) / 2
                }
                this.$refs.actualImage.scrollTop = offsetTop
            }
        },
        imageSizes: function (value) {
            if (value && this.$refs?.actualImage) {
                const offsetLeft = (this.imageSizes.width * value) / 2
                const offsetTop = (this.imageSizes.height * value) / 2

                this.$refs.actualImage.scrollLeft = offsetLeft
                this.$refs.actualImage.scrollTop = offsetTop
            }
        },
        deep: true,
    },
    async mounted() {
        const image = new Image()
        image.onload = (e) => {
            this.portraitMode = image.naturalWidth <= image.naturalHeight
            if (this.portraitMode) {
                this.imageSizes = {
                    width: this.$refs.neededCircleWidthHeight.offsetWidth,
                    height:
                        (image.naturalHeight / image.naturalWidth) *
                        this.$refs.neededCircleWidthHeight.offsetWidth,
                }
                this.maxZoomFactor = image.naturalWidth / this.imageSizes.width
            } else {
                this.imageSizes = {
                    width:
                        (image.naturalWidth / image.naturalHeight) *
                        this.$refs.neededCircleWidthHeight.offsetHeight,
                    height: this.$refs.neededCircleWidthHeight.offsetHeight,
                }
                this.maxZoomFactor =
                    image.naturalHeight / this.imageSizes.height
            }
        }

        if (this.$store.state.atelier.item.nfcItem?.high_res_image) {
            const imageId = JSON.parse(
                this.$store.state.atelier.item.nfcItem?.high_res_image,
            ).imageAsset
            const asset = (await api.twoavy.getAsset(imageId)).data.data
            this.imageUrl = asset.paths.url
        } else {
            this.imageUrl = this.$store.state.atelier.item.fullsizeUrl
        }
        image.src = this.imageUrl

        await this.$store.dispatch('atelier/getMVPFromLocal')
        this.imageLoaded()
    },
    methods: {
        touchMove(e) {
            if (e.targetTouches.length > 1) {
                e.preventDefault()
                const currentDistance = this.calculateDistance(
                    {
                        x: e.targetTouches[0].clientX,
                        y: e.targetTouches[0].clientY,
                    },
                    {
                        x: e.targetTouches[1].clientX,
                        y: e.targetTouches[1].clientY,
                    },
                )
                if (
                    currentDistance > this.lastDistanceBetweenFingers &&
                    this.zoomFactor < 6
                ) {
                    this.setZoomFactor((this.zoomFactor += 0.01))
                } else if (
                    currentDistance < this.lastDistanceBetweenFingers &&
                    this.zoomFactor > 1
                ) {
                    this.setZoomFactor((this.zoomFactor -= 0.02))
                }
                this.lastDistanceBetweenFingers = currentDistance
            }
        },
        calculateDistance(p1, p2) {
            return Math.sqrt(
                Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2),
            )
        },
        imageLoaded() {
            this.$nextTick(() => {
                this.setZoomFactor(this.startZoomFactor)
                this.$refs.actualImage.scrollLeft =
                    (this.$refs.actualImage.scrollWidth -
                        this.$refs.neededCircleWidthHeight.offsetWidth) /
                    2
                this.$refs.actualImage.scrollTop =
                    (this.$refs.actualImage.scrollHeight -
                        this.$refs.neededCircleWidthHeight.offsetHeight) /
                    2
            })
        },
        setZoomFactor(newVal) {
            this.zoomFactor = newVal
        },
        calcX() {
            const img = this.$refs.imageRef
            return (
                (img.parentElement.scrollLeft +
                    img.parentElement.clientWidth / 2) /
                img.clientWidth
            )
        },
        calcY() {
            const img = this.$refs.imageRef
            return (
                (img.parentElement.scrollTop +
                    img.parentElement.clientHeight / 2) /
                img.clientHeight
            )
        },
        async save() {
            if (this.canBeSaved) {
                const id = this.$store.state.atelier.item.id
                if (id) {
                    const img = this.$refs.imageRef
                    const size = this.portraitMode
                        ? img.parentElement.clientHeight / img.clientHeight
                        : img.parentElement.clientWidth / img.clientWidth
                    const x = this.calcX()
                    const y = this.calcY()
                    const payload = {
                        id: id,
                        position: {
                            pos: {
                                x,
                                y,
                            },
                            size,
                        },
                    }
                    await this.$store.dispatch(
                        'atelier/saveMicroscopeView',
                        payload,
                    )

                    this.animated3()
                }

                this.$userLog.saveUserLog(
                    'atelier_microscope_save',
                    this.currentUser.uuid,
                    {
                        pid: this.$store.state.atelier.item.id,
                    },
                )
            }
        },
        async animated3() {
            this.getCount().then((count) => {
                this.showAnimation = true

                const xMinus = this.$refs.actualImage.scrollLeft / count
                const yMinus = this.$refs.actualImage.scrollTop / count

                this.$nextTick(() => {
                    //animiert kreisposition
                    this.$refs.startCircleAnimation.beginElement()
                    //animiert borderradius vom wrapper
                    this.$refs.actualImage.animate(
                        [
                            {
                                borderRadius: '50%',
                            },
                            {
                                borderRadius: '0px',
                            },
                        ],
                        {
                            duration: 1500,
                            fill: 'forwards',
                        },
                    )
                    //animiert kreisgröße
                    d3.select(this.$refs.lastCircle)
                        .transition()
                        .duration(1500)
                        .ease(d3.easeLinear)
                        .attr(
                            'r',
                            this.savedZoomState[this.savedZoomState.length - 1]
                                .size * 500,
                        )

                    //animiert ausschnittgröße
                    d3.select(this.$refs.actualImage)
                        .transition()
                        .duration(1500)
                        .ease(d3.easeLinear)
                        .style('width', this.imageSizes.width + 'px')
                        .style('height', this.imageSizes.height + 'px')
                        .tween('side-effect', () => {
                            return () => {
                                this.$refs.actualImage.scrollLeft -= xMinus
                                this.$refs.actualImage.scrollTop -= yMinus
                            }
                        })

                    //animiert bildgröße
                    d3.select(this.$refs.imageRef)
                        .transition()
                        .duration(1500)
                        .ease(d3.easeLinear)
                        .attr(
                            'width',
                            (this.imageSizes.width >= window.innerWidth
                                ? window.innerWidth
                                : this.imageSizes.width) + 'px',
                        )
                        .attr(
                            'height',
                            (this.imageSizes.height >= window.innerHeight
                                ? window.innerHeight
                                : this.imageSizes.height) + 'px',
                        )
                        .on('end', () => {
                            setTimeout(() => {
                                this.showStates = !this.showStates
                                d3.select(this.$refs.animateWhiteOverlayOut)
                                    .transition()
                                    .duration(750)
                                    .attr('fill-opacity', '0')
                            }, 1000)
                        })
                })
            })
        },
        async getCount() {
            const p = new Promise((resolve, reject) => {
                let i = 0
                d3.select(this.$refs.actualImageToCalc)
                    .transition()
                    .duration(500)
                    .ease(d3.easeLinear)
                    .style('width', this.imageSizes.width + 'px')
                    .style('height', this.imageSizes.height + 'px')
                    .tween('side-effect', () => {
                        return () => {
                            i++
                        }
                    })
                    .on('end', () => resolve(i * 3 - 3))
            })
            return await p
        },
    },
}
</script>

<style scoped lang="scss">
.micro-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
    grid-gap: 2vh;
}

.actual-image {
    position: absolute;
    overflow: scroll;
    border-radius: 50%;
    transition: all ease 1s;

    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.balance-height-on-show-results {
    height: 5vh;
    margin: 0;
    width: calc(40vw * var(--pxToVw));
}

.overlay-grid {
    transition: all ease 1s;
}

.slider-save {
    width: 40vw;
    height: 5vh;
    display: flex;
    align-items: center;
    grid-gap: 1vw;
}

.station-feedback {
    color: var(--secondary);
    width: calc(350vw * var(--pxToVw));
}
</style>
