<template>
    <ul class="filters">
        <li class="filter">
            <div class="title">
                {{ $t('material') }}
            </div>
            <my-select
                ref="select-material"
                title="material"
                :options="
                    [
                        { value: null, label: $t('none_selected') },
                        ...$store.state.cupboardsGameHaystack.materialIndices.map(
                            (option) => {
                                return {
                                    value: option,
                                    label: option,
                                }
                            },
                        ),
                    ]
                        .filter(uniqueFilter)
                        .sort((a, b) => {
                            if (a.label > b.label) {
                                return 1
                            } else {
                                return -1
                            }
                        })
                "
                :value="
                    $store.getters['cupboardsGameHaystack/getMaterialIndex']
                "
                @valueChanged="onMaterialIndexChange"
            ></my-select>
        </li>
        <li class="filter">
            <div class="title">
                {{ $t('person') }}
            </div>
            <my-select
                ref="select-person"
                title="person"
                :options="
                    [
                        { value: null, label: $t('none_selected') },
                        ...$store.state.cupboardsGameHaystack.persons.map(
                            (option) => {
                                return {
                                    value: option,
                                    label: option,
                                }
                            },
                        ),
                    ]
                        .filter(uniqueFilter)
                        .sort((a, b) => {
                            if (a.label > b.label) {
                                return 1
                            } else {
                                return -1
                            }
                        })
                "
                :value="$store.getters['cupboardsGameHaystack/getPerson']"
                @scroll-top="scrollTopPosition"
                @valueChanged="onPersonChange"
            ></my-select>
        </li>

        <li class="filter">
            <div class="title">
                {{ $t('authenticity') }}
            </div>
            <my-select
                ref="select-authenticity"
                title="authenticity"
                :options="
                    [
                        { value: null, label: $t('none_selected') },
                        ...$store.state.cupboardsGameHaystack.authenticities.map(
                            (option) => {
                                return {
                                    value: option,
                                    label: option,
                                }
                            },
                        ),
                    ]
                        .filter(uniqueFilter)
                        .sort((a, b) => {
                            if (a.label > b.label) {
                                return 1
                            } else {
                                return -1
                            }
                        })
                "
                :value="$store.getters['cupboardsGameHaystack/getAuthenticity']"
                @valueChanged="onAuthenticityChange"
            ></my-select>
        </li>
        <li class="filter">
            <div class="title">
                {{ $t('date') }}
            </div>
            <range-slider
                :min="parseInt($store.state.cupboardsGameHaystack.minDate)"
                :max="parseInt($store.state.cupboardsGameHaystack.maxDate)"
                :value="$store.getters['cupboardsGameHaystack/getDate']"
                :step="1"
                @valueChanged="onDateChange"
            ></range-slider>
        </li>
        <li class="filter">
            <div class="title">
                {{ $t('weight') }}
            </div>
            <range-slider
                :min="Math.floor($store.state.cupboardsGameHaystack.minWeight)"
                :max="Math.ceil($store.state.cupboardsGameHaystack.maxWeight)"
                :value="$store.getters['cupboardsGameHaystack/getWeight']"
                :step="1"
                @valueChanged="onWeightChange"
            ></range-slider>
        </li>
        <li class="filter">
            <div class="title">
                {{ $t('diameter') }}
            </div>
            <range-slider
                :min="
                    Math.floor($store.state.cupboardsGameHaystack.minDiameter)
                "
                :max="Math.ceil($store.state.cupboardsGameHaystack.maxDiameter)"
                :value="$store.getters['cupboardsGameHaystack/getDiameter']"
                :step="1"
                @valueChanged="onDiameterChange"
            ></range-slider>
        </li>
    </ul>
</template>
<script>
import { getWeightInG, getDiameterInMm } from '@/utils/items'
import RangeSlider from '@/components/global/RangeSlider.vue'
import Select from '@/components/global/Select2.vue'

export default {
    name: 'CupboardsGameHaystack',
    components: {
        RangeSlider,
        MySelect: Select,
    },
    data() {
        return {
            getWeightInG,
            getDiameterInMm,
        }
    },
    methods: {
        scrollTopPosition() {},
        closeVisibleSelectLists() {
            const refs = []
            for (let [key, value] of Object.entries(this.$refs)) {
                if (/^select/.test(key)) {
                    refs.push({ value })
                }
            }
            refs.forEach((ref) => {
                ref.value.setOpen(false)
            })
        },
        uniqueFilter(value, index, self) {
            return self.indexOf(value) === index
        },
        onMaterialIndexChange(value) {
            this.$store.dispatch(
                'cupboardsGameHaystack/setMaterialIndex',
                value,
            )
        },
        onNominalChange(value) {
            this.$store.dispatch('cupboardsGameHaystack/setNominal', value)
        },
        onPersonChange(value) {
            this.$store.dispatch('cupboardsGameHaystack/setPerson', value)
        },
        onAuthenticityChange(value) {
            this.$store.dispatch('cupboardsGameHaystack/setAuthenticity', value)
        },
        onDateChange(value) {
            this.$store.dispatch('cupboardsGameHaystack/setDate', {
                min: Math.round(value.min),
                max: Math.round(value.max),
            })
        },
        onCenturyChange(value) {
            this.$store.dispatch('cupboardsGameHaystack/setCentury', {
                min: Math.round(value.min),
                max: Math.round(value.max),
            })
        },
        onWeightChange(value) {
            this.$store.dispatch('cupboardsGameHaystack/setWeight', {
                min: Math.round(value.min),
                max: Math.round(value.max),
            })
        },
        onDiameterChange(value) {
            this.$store.dispatch('cupboardsGameHaystack/setDiameter', {
                min: Math.round(value.min),
                max: Math.round(value.max),
            })
        },
    },
}
</script>

<style scoped lang="scss">
.filters {
    font-size: calc(26vw * var(--pxToVw));
    list-style-type: none;
    .filter {
        position: relative;
        //padding-left: 64px;
        //padding-right: 64px;
        margin-bottom: 12px;

        .select {
            /* padding-right: calc(30vw * var(--pxToVw)); */
        }

        flex-basis: 100%;

        select {
            width: 100%;
        }

        .selected {
            display: block;
        }

        .title {
            font-weight: bold;
        }

        .labels {
            display: flex;
            flex-direction: row;

            .min {
                flex-grow: 1;
            }
        }
    }
}
</style>
