<template>
    <desk-analysis-word-cloud-generator
        class="word-cloud"
        :data="defaultWords"
        name-key="text"
        value-key="value"
        font="GoodPro"
        font-scale="log"
        :color="colors"
        :show-tooltip="false"
    />
</template>

<script>
import DeskAnalysisWordCloudGenerator from '@/components/stations/3.004_desk/analysis/DeskAnalysisWordCloudGenerator'
import * as d3 from 'd3'
import { StemmerDe, TokenizerDe, StopwordsDe } from '@nlpjs/lang-de'

export default {
    name: 'DeskAnalysisWordCloud',
    components: { DeskAnalysisWordCloudGenerator },
    props: {
        text: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            colors: ['#ffffff', '#eeeeee', '#dddddd', '#cccccc'],
            defaultWords: [],
            canvas: null,
        }
    },
    watch: {
        text() {
            if (this.text !== '') {
                this.analyseText()
            } else {
                this.defaultWords = []
            }
        },
    },
    mounted() {},
    methods: {
        analyseText() {
            const tokenizer = new TokenizerDe()
            const stopwords = new StopwordsDe()
            const stemmer = new StemmerDe()
            const str = this.htmlDecode(this.text).replace(
                /[^a-zA-ZäöüÄÖÜßÿ ]/g,
                ' ',
            )
            const words = stemmer.stem(
                stopwords.removeStopwords(tokenizer.tokenize(str, true)),
            )
            const mappedWords = words.map((x) => {
                return {
                    text: x,
                }
            })
            const groupedWords = d3.group(mappedWords, (t) => t.text)
            this.defaultWords = Array.from(groupedWords)
                .map((x) => {
                    return { text: x[0], value: x[1].length }
                })
                .sort(function (a, b) {
                    return parseFloat(b['value']) - parseFloat(a['value'])
                })
            //    .splice(0, 150)
            // .filter(x => x.value > Array.from(groupedWords).length / 100)
        },
        htmlDecode(input) {
            const doc = new DOMParser().parseFromString(input, 'text/html')
            return doc.documentElement.textContent
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/global';

.word-cloud {
    position: absolute;
    overflow: hidden;
    z-index: 1;
    top: calc(98vw * var(--pxToVw));
    left: calc(50vw * var(--pxToVw));
    width: calc(868vw * var(--pxToVw));
    height: calc(957vw * var(--pxToVw));
}
</style>
