import { v4 as uuidv4 } from 'uuid'

import store from '../store/index'

export default {
    saveUserLog(type, identifier = '', data = null, interactiveZone = 0) {
        if (!identifier) {
            identifier = uuidv4()
        }
        const payload = {
            type,
            identifier,
            message: store.state.unitName,
            data,
            interactiveZone,
        }

        // delete interactiveZone if it is 0
        if (interactiveZone === 0) {
            delete payload.interactiveZone
        }

        console.log(payload)

        if (window.electronApi) {
            window.electronApi.send('saveUserLog', { type: 'insert', payload })
            console.log('userLog: electron API available')
        } else {
            console.log('userLog: electron API not available')
        }
    },
}
