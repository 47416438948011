<template>
    <div class="texts">
        <div class="instructions">
            {{ $t(`cupboards_taxonomy_instruction`) }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'CupboardsTaxonomyTexts',
    data() {
        return {}
    },
    computed: {},
    async mounted() {},
    beforeDestroy: function () {},
    methods: {},
}
</script>

<style scoped lang="scss">
.texts {
    width: 800px;
    padding-left: 50px;
    font-size: 32px;
}
</style>
