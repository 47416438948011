<template>
    <div
        v-if="!electronApi"
        class="d-flex flex-column flex-grow-1 p-5 h-100 w-100 justify-content-center align-items-center"
    >
        <h1 class="text-center">
            EINSTELLUNGEN NICHT MÖGLICH!
            <br />
            <br />
            BITTE ELECTRON ANWENDUNG STARTEN
        </h1>
    </div>
    <div v-else class="d-flex flex-column flex-grow-1 p-5 h-100 w-100">
        <div class="d-flex w-100 mb-3">
            <div class="col-4"><h2>Electron-Einstellungen</h2></div>
            <div class="col-4"><h2>Allgemeine Einstellungen</h2></div>
        </div>
        <div class="d-flex flex-grow-1 w-100">
            <div v-if="electronSettings" class="col-4">
                <h6 class="mb-3">Allgemeine Fenster-Einstellungen</h6>
                <b-form-checkbox
                    v-model="electronSettings.alwaysOnTop"
                    size="lg"
                >
                    Fenster immer im Vordergrund?
                </b-form-checkbox>
                <b-form-checkbox v-model="electronSettings.closable" size="lg">
                    Fenster schließbar?
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="electronSettings.zoomToPageWidth"
                    size="lg"
                >
                    Fenster zoombar?
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="electronSettings.fullscreen"
                    size="lg"
                    class="my-3"
                >
                    Anwendung im Fullscreen
                </b-form-checkbox>
                <div
                    v-if="!electronSettings.fullscreen"
                    class="d-flex flex-column"
                >
                    <h6 class="my-3">Bounding-Einstellungen</h6>
                    <div class="d-flex">
                        <div class="d-flex flex-column mr-2">
                            <label :for="'width'">Fensterbreite</label>
                            <b-form-input
                                id="width"
                                v-model.number="electronSettings.width"
                                size="lg"
                                type="number"
                                placeholder="1920"
                            ></b-form-input>
                        </div>
                        <div class="d-flex flex-column">
                            <label :for="'height'">Fensterhöhe</label>
                            <b-form-input
                                id="height"
                                v-model.number="electronSettings.height"
                                size="lg"
                                type="number"
                                placeholder="1080"
                            ></b-form-input>
                        </div>
                    </div>
                    <div class="d-flex mt-2">
                        <div class="d-flex flex-column mr-2">
                            <label :for="'x'">x Position</label>
                            <b-form-input
                                id="x"
                                v-model.number="electronSettings.x"
                                size="lg"
                                type="number"
                                placeholder="0"
                            ></b-form-input>
                        </div>
                        <div class="d-flex flex-column">
                            <label :for="'height'">y Position</label>
                            <b-form-input
                                id="height"
                                v-model.number="electronSettings.y"
                                size="lg"
                                type="number"
                                placeholder="0"
                            ></b-form-input>
                        </div>
                    </div>
                    <b-form-checkbox
                        v-model="electronSettings.movable"
                        size="lg"
                        class="mt-3"
                    >
                        Fenster bewegbar?
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="electronSettings.resizable"
                        size="lg"
                    >
                        Fenstergröße veränderbar?
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="electronSettings.roundedCorners"
                        size="lg"
                    >
                        Fenster mit abgerundeten Ecken? (macOS)
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="electronSettings.hasShadow"
                        size="lg"
                    >
                        Fenster hat Schatten? (macOS)
                    </b-form-checkbox>
                </div>
                <hr />
                <h6 class="my-3">Geräte-Einstellungen</h6>
                <div class="d-flex flex-column mb-5">
                    <label :for="'height'">Device ID</label>
                    <b-form-input
                        id="height"
                        v-model.number="electronSettings.deviceId"
                        size="lg"
                        type="number"
                        placeholder="0"
                    ></b-form-input>
                </div>
            </div>
            <div v-if="fowiSettings" class="col-4">
                <template v-if="$store.state.nfcTags">
                    <h6 class="mb-3">NFC-Tag auswählen</h6>
                    <b-form-select
                        v-model="fowiSettings.tagId"
                        class="mb-3 w-25"
                        :options="loadNfcTags"
                    ></b-form-select>
                    <h6 v-if="fowiSettings.tagId">
                        Ausgewählter NFC-Tag:
                        <b>{{ fowiSettings.tagId }}</b>
                    </h6>
                </template>

                <h6 class="my-3">Station auswählen</h6>
                <div class="row">
                    <div
                        v-for="(station, index) in stationsData"
                        :key="'station' + index"
                        class="col-5 p-3 m-2 btn"
                        :class="
                            station.key === fowiSettings.station
                                ? 'btn-primary'
                                : 'btn-dark'
                        "
                        @click="selectStation(station)"
                    >
                        <h5>{{ station.name }}</h5>
                    </div>
                    <div
                        v-if="fowiSettings.station"
                        class="col-10 p-3 m-2 bg-white"
                    >
                        <pre>{{
                            stationsData.find(
                                (x) => x.key === fowiSettings.station,
                            )
                        }}</pre>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex w-100 align-bottom">
            <div class="col-12">
                <div class="btn btn-primary mr-3" @click="saveData">
                    Speichern
                </div>
                <div class="btn btn-primary mr-3" @click="rebootApp">
                    Anwendung neustarten
                </div>
                <div
                    class="btn btn-danger"
                    @click="
                        $router.push({
                            name: fowiSettings.station
                                ? fowiSettings.station
                                : 'home',
                        })
                    "
                >
                    Schließen
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { stationsData } from '@/stationsData'

export default {
    name: 'Settings',
    data() {
        return {
            electronApi: false,
            electronSettings: null,
            fowiSettings: null,
            nfcTags: [{ value: null, text: '-' }],
            stationsData: stationsData,
        }
    },
    computed: {
        loadNfcTags() {
            this.$store.state.nfcTags
                .filter((x) => x.type === 'table')
                .forEach((tag) =>
                    this.nfcTags.push({
                        value: tag.identifier,
                        text: tag.identifier,
                    }),
                )
            return this.nfcTags
        },
    },
    watch: {
        fowiSettings: {
            deep: true,
            handler() {
                if (this.fowiSettings) {
                    window.electronApi.send('fowiConfig', this.fowiSettings)
                }
            },
        },
    },
    mounted() {
        if (window.electronApi) {
            this.electronApi = true
            window.electronApi.send('electronConfig', 'getConfig')
            window.electronApi.receive('electronConfig', (settings) => {
                this.electronSettings = settings
            })
            window.electronApi.send('fowiConfig', 'getConfig')
            window.electronApi.receive('fowiConfig', (settings) => {
                this.fowiSettings = settings
            })
        }
    },
    beforeDestroy() {
        window.electronApi.off('fowiConfig')
        window.electronApi.off('electronConfig')
    },
    methods: {
        selectStation(station) {
            this.fowiSettings.station = station.key
            this.fowiSettings.resolution = station.resolution
            window.electronApi.send('fowiConfig', this.fowiSettings)
        },
        saveData() {
            if (window.electronApi) {
                window.electronApi.send('electronConfig', this.electronSettings)
                window.electronApi.send('fowiConfig', this.fowiSettings)
            }
        },
        rebootApp() {
            if (window.electronApi) {
                window.electronApi.send('sendCommand', 'relaunch')
            }
        },
    },
}
</script>

<style scoped></style>
