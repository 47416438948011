<template>
    <div>
        <span v-for="word in words" :key="`word_${word}`" class="word">
            {{ word }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'CupboardsIdleAnimation',
    data() {
        return {}
    },
    computed: {
        words() {
            const uniqueFilter = (value, index, self) => {
                return self.indexOf(value) === index
            }
            const words = this.$store.getters['cupboards/getCollectionNames']
            //     TODO: regex
            return words
                .map((word) => word.split(' '))
                .flat()
                .map((word) => word.split('-'))
                .flat()
                .filter(uniqueFilter)
        },
    },
    async mounted() {},
    beforeDestroy: function () {},
    methods: {},
}
</script>

<style scoped lang="scss"></style>
