<script>
import api from '../../../../mixins/api'

export default {
    name: 'UserLogin',
    props: {
        connectedUsers: {
            type: Array,
            default: null,
        },
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            openObj: [],
            selectedObj: [],
            timeoutObj: [],
            idle: 2000,
            idle2: 10000,
            idle3: 20000,
            touchIcon: true,
            playNowObj: [],
            pulseInterval: null,
            transformSwipe: 33.3,
            qrcodeIdle: null,
            qrcodeShowCountdown: null,
            qrcodeCountdown: 20,
        }
    },
    mounted() {
        setTimeout(() => {
            this.touchIcon = true
        }, this.idle)
    },
    methods: {
        swipe(direction, id) {
            if (direction === 'left') {
                this.transformSwipe += 33.3
            } else if (direction === 'right') {
                this.transformSwipe -= 33.3
            }
            clearTimeout(this.timeoutObj[id])
            // console.log(id)

            this.qrcodeIdle = setTimeout(() => {
                this.closeQR(id)
            }, this.idle3)

            this.qrcodeShowCountdown = setInterval(() => {
                this.qrcodeCountdown--
            }, 1000)
        },
        closeQR(i) {
            this.transformSwipe = 33.3
            clearTimeout(this.qrcodeIdle)
            clearTimeout(this.qrcodeShowCountdown)
            this.qrcodeCountdown = 20
            this.timeoutObj[i] = setTimeout(() => {
                this.open(i)
            }, this.idle2)
        },
        open(i) {
            // console.log(i)

            if (this.openObj.includes(i)) {
                let index = this.openObj.indexOf(i)

                this.openObj.splice(index, 1)

                clearTimeout(this.timeoutObj[i])
            } else {
                this.openObj.push(i)
                this.timeoutObj[i] = setTimeout(() => {
                    this.open(i)
                }, this.idle2)
                this.transformSwipe = 33.3
            }
            // console.log(this.openObj)
        },
        async loginWithoutMobile(id, language) {
            this.open(id)
            this.playNowObj.push(id)
            const createCollectionFor = [
                {
                    unit: 'cupboardsImpossible',
                    count: 3, //max 14
                },
                {
                    unit: 'cupboardsHaystack',
                    count: 1, //max 2,
                },
                // {
                //     unit: 'cupboardsGenerator',
                //     count: 3, //max 34
                // },
            ]

            const unit = 'cupboards'
            api.twoavy.loginUserWithoutMobile(
                unit,
                createCollectionFor,
                language,
            )
            // setTimeout(() => {
            //     let index = this.playNowObj.indexOf(id)
            //     this.playNowObj.splice(index, 1)
            // }, 1000)
        },
    },
}
</script>
<template>
    <div class="w-100 h-100 relative z-100 cupboards">
        <div
            v-if="touchIcon"
            class="absolute touchIcon"
            :class="
                openObj.includes(id) || playNowObj.includes(id) ? 'hide' : ''
            "
        >
            <img
                class="btn"
                :class="
                    openObj.includes(id) || playNowObj.includes(id)
                        ? 'hide'
                        : ''
                "
                :src="require('@/assets/img/touch-id.svg')"
                alt=""
                @click="open(id)"
            />
        </div>
        <div
            class="bgbox"
            :class="['bgbox-' + id, openObj.includes(id) ? 'open' : '']"
        >
            <!--          :class="pulse ? 'visible' : ''"-->
            <div class="toggle">
                <img
                    v-show="openObj.includes(id)"
                    class="btn-close"
                    :src="require('@/assets/img/close.svg')"
                    alt=""
                    @click.prevent.stop="open(id)"
                />
            </div>
            <div v-if="openObj.includes(id)" class="box-content flex flex-col">
                <div class="language-buttons-wrapper w-100">
                    <div class="w-50 flex leftBox">
                        <p
                            class="loginText mb-2"
                            @click="loginWithoutMobile(id, 'de')"
                        >
                            probieren ohne App
                        </p>
                        <p
                            class="loginText mt-4"
                            @click="loginWithoutMobile(id, 'en')"
                        >
                            try without App
                        </p>
                    </div>
                    <div class="swipe-wrap w-150 h-full">
                        <div
                            class="swipe-wrap-inner flex flex-row relative"
                            :style="
                                'transform: translateX(-' +
                                transformSwipe +
                                '%);'
                            "
                        >
                            <div class="flex flex-column w-full slide">
                                <button
                                    class="right-0 mr-1 absolute close-qr right-arrow"
                                    @click.prevent.stop="closeQR(id)"
                                >
                                    <font-awesome-icon
                                        :icon="['far', 'angle-right']"
                                        size="xl"
                                    />
                                </button>
                                <div
                                    :key="qrcodeShowCountdown"
                                    class="countdown"
                                >
                                    <div class="countdown-number">
                                        {{ qrcodeCountdown }}
                                    </div>
                                    <svg>
                                        <circle r="18" cx="20" cy="20"></circle>
                                    </svg>
                                </div>
                                <p class="mt-1 mb-0">Android</p>
                                <img
                                    class="qr-code"
                                    :src="
                                        require('@/assets/img/qr_code_android.png')
                                    "
                                    alt=""
                                />
                            </div>
                            <div class="slide relative">
                                <button
                                    class="left-0 ml-1 close-qr absolute left-arrow"
                                    @click="swipe('right', id)"
                                >
                                    <font-awesome-icon
                                        :icon="['far', 'angle-left']"
                                        size="xl"
                                    />
                                </button>
                                <div class="flex w-full middleBox">
                                    <p>lade die App</p>
                                    <div class="logos">
                                        <img
                                            class="store-logo"
                                            :src="
                                                require('@/assets/img/android-logo.png')
                                            "
                                            alt=""
                                            @click="swipe('right', id)"
                                        />
                                        <img
                                            class="store-logo"
                                            :src="
                                                require('@/assets/img/apple-logo.png')
                                            "
                                            alt=""
                                            @click="swipe('left', id)"
                                        />
                                    </div>
                                    <p>load the App</p>
                                </div>
                                <button
                                    class="right-0 mr-1 absolute close-qr right-arrow"
                                    @click="swipe('left', id)"
                                >
                                    <font-awesome-icon
                                        :icon="['far', 'angle-right']"
                                        size="xl"
                                    />
                                </button>
                            </div>
                            <div class="flex flex-column absolute w-full slide">
                                <button
                                    class="left-0 ml-1 close-qr left-arrow"
                                    @click.prevent.stop="closeQR(id)"
                                >
                                    <font-awesome-icon
                                        :icon="['far', 'angle-left']"
                                        size="xl"
                                    />
                                </button>
                                <div
                                    :key="qrcodeShowCountdown"
                                    class="countdown"
                                >
                                    <div class="countdown-number">
                                        {{ qrcodeCountdown }}
                                    </div>
                                    <svg>
                                        <circle r="18" cx="20" cy="20"></circle>
                                    </svg>
                                </div>
                                <p>iOS</p>
                                <img
                                    class="qr-code"
                                    :src="
                                        require('@/assets/img/qr_code_ios.png')
                                    "
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.cupboards {
    .box-header {
        width: 60%;
        justify-content: center;
        align-items: center;
        text-align: center;

        h2 {
            padding-bottom: 0;
            padding-left: calc(30vw * var(--pxToVw)); //30px;
            padding-right: 0;
        }

        h1 {
            color: white;
            padding-bottom: calc(5vw * var(--pxToVw)); // 5px;
            padding-top: calc(5vw * var(--pxToVw)); //5px;
        }
    }

    h2 {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .btn-close {
        width: calc(30vw * var(--pxToVw));
        height: calc(30vw * var(--pxToVw));
        object-fit: contain;
        margin: 0 10px;
        position: absolute;
        z-index: 100;
        cursor: pointer;
    }

    .bgbox {
        height: calc(240vw * var(--pxToVw));
        width: calc(500vw * var(--pxToVw));
        display: flex;
        align-content: center;
        align-items: center;
        position: absolute;
        z-index: 10;
        padding-top: 50px;

        &.open {
            box-shadow: rgb(0, 0, 0) 0px 0px 25px;
        }

        p {
            margin: 0;
        }
    }

    .box-content h2,
    .box-content p {
        color: black;
    }

    .box-content {
        width: 100%;
    }

    .bgbox-1,
    .bgbox-2,
    .bgbox-3 {
        transform: translateX(5%) translateY(20vh);
        position: absolute;
        transition: all 0.3s linear;
        left: 0px;
        padding-top: 8vh;
        bottom: 0;

        &.open {
            transform: translateX(5%) translateY(-2vh);
            border: 2px solid;

            padding-top: 0vh;
            //background-color: rgba(0, 0, 0, 0.5);
            background-color: white;
        }

        img.btn-close,
        img.btn {
            position: absolute;
            top: 10px;
            right: 0px;
        }

        .touchIcon {
            position: absolute;
            top: 20px;
            left: 20px;
        }
    }

    .touchIcon,
    .toggle .btn {
        //animation: fadeinout 10s linear infinite;
        opacity: 1;
    }

    .language-buttons-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .language-buttons-wrapper .store {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 3px solid black;
    }

    @keyframes fade-in-out {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .cbutton--click {
        opacity: 1;
        transition: opacity 1s linear;
    }

    .touchIcon.visible,
    .cbutton--click.visible {
        opacity: 1;
        transition: opacity 1s linear;
    }

    .touchIcon.hide,
    .cbutton--click.hide {
        opacity: 0;
        transition: opacity 0.1s linear;
    }

    img.btn {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: calc(70vw * var(--pxToVw));
        height: calc(70vw * var(--pxToVw));
        background-color: #e01d34;
        border-radius: 10px;
        padding: 12px;
        margin: 20px;
    }

    .swipe-wrap {
        width: 50%;
        overflow: hidden;
    }

    .swipe-wrap-inner {
        width: 300%;
        display: flex;
        flex-direction: row;
        transition: all 0.3s linear;

        .slide {
            justify-content: start;
            display: flex;
            width: 50%;
            align-items: center;
            position: relative;
        }
    }

    .close-qr {
        position: absolute;
        border: none;
        height: 40px;
        width: 30px;
        top: 0px;
        background: none;

        svg {
            width: 30px !important;
            height: 50px !important;
        }
    }

    .right-arrow.close-qr {
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .slide .right-arrow.close-qr {
        right: 0px;
    }

    .left-arrow.close-qr {
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
    }
    .slide .left-arrow.close-qr {
        left: -10px;
    }

    .qr-code {
        width: 40%;
        padding-top: 2%;
    }

    img.store-logo {
        width: 50%;
        padding: 0 20px;
    }

    .leftBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        align-content: center !important;
        border-right: 3px solid black;
    }

    .language-buttons-wrapper {
        margin-top: 0px;
    }

    .middleBox {
        width: 100%;
        justify-content: center;
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        .logos {
            padding: 10px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}

.left-arrow ~ .countdown {
    right: 10px;
}

.right-arrow ~ .countdown {
    left: 10px;
}

.countdown {
    position: absolute;
    margin: auto;
    height: 40px;
    width: 40px;
    text-align: center;
    bottom: 0px;

    .countdown-number {
        color: black;
        display: inline-block;
        line-height: 40px;
    }

    svg {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        transform: rotateY(-180deg) rotateZ(-90deg);
    }

    svg circle {
        stroke-dasharray: 140px;
        stroke-dashoffset: 0px;
        stroke-linecap: round;
        stroke-width: 3px;
        stroke: black;
        fill: none;
        animation: countdown 20s linear infinite forwards;
    }

    @keyframes countdown {
        from {
            stroke-dashoffset: 0px;
        }
        to {
            stroke-dashoffset: 140px;
        }
    }
}

.loginText {
    background-color: #e01d34;
    padding: 10px 15px;
    color: white !important;

    &.de {
        margin-bottom: 50px;
    }
}
</style>
