<template>
    <div class="h-100 w-100">
        <title-and-description-idle-mode
            :title="
                $store.getters['interfaceTexts/getInterfaceText'](
                    'desk_station_title',
                )
            "
            :description="
                $store.getters['interfaceTexts/getInterfaceText'](
                    'desk_call_to_action',
                )
            "
        />
        <iiif-viewer
            v-if="manifestObject"
            autoactive
            :duration="6"
            :height="1000"
            :manifest="manifestObject"
        />
        <user-login
            :id="1"
            :create-collection="createCollection"
            :unit="unit"
            class="userLogin"
        ></user-login>
    </div>
</template>

<script>
import IiifViewer from '../../global/iiifViewer/IiifViewer'
import api from '@/mixins/api'
import TitleAndDescriptionIdleMode from '@/components/global/TitleAndDescriptionIdleMode'
import UserLogin from '@/components/global/UserLogin.vue'

export default {
    name: 'DeskIdle',
    components: { UserLogin, TitleAndDescriptionIdleMode, IiifViewer },
    data() {
        return {
            manifestObject: null,
            createCollection: [
                {
                    unit: 'desk',
                    count: 3, //max 14
                },
            ],
            unit: 'desk',
        }
    },
    computed: {
        getContent() {
            return this.$store.getters.getContent
        },
        getNfcContent() {
            return this.$store.getters.getNfcTags
        },
        getRandomManifest() {
            const manifests = this.getNfcContent.filter(
                (x) =>
                    x.units &&
                    x.manifest_url &&
                    JSON.parse(x.units).includes('desk'),
            )
            if (manifests?.length > 0) {
                const index = Math.floor(Math.random() * manifests.length)
                return manifests[index].manifest_url
            }
            return null
        },
        setManifestObject() {
            return this.manifestObject ? this.getRandomManifest : null
        },
    },
    async mounted() {
        this.manifestObject = (
            await api.manifest.getManifestContent(this.getRandomManifest)
        ).data
    },
    methods: {
        async getManifestObject() {
            this.manifestObject = (
                await api.manifest.getManifestContent(this.getRandomManifest)
            ).data
        },
    },
}
</script>

<style scoped lang="scss"></style>
