var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"matter",staticClass:"items"},_vm._l((_vm.$store.getters
            .getUserAtUnit(_vm.getUnitKey(_vm.index))
            .collection.filter((item) => item.goettItem)
            // don't show selected items, they are visualized as cards
            .filter((item) => !_vm.isItemSelected(item))
            .filter((item) => _vm.itemHasNoDataForGame(item))),function(item){return _c('div',{key:item.goettItem.PI,staticClass:"item circle-image",style:(_vm.getItemStyle(item))},[_c('img',{class:{
                selected: _vm.isItemSelected(item),
            },attrs:{"src":item.thumbnailUrl},on:{"click":function($event){return _vm.toggleSelectedItem(item)}}}),(_vm.isItemSelected(item))?_c('font-awesome-icon',{staticClass:"clickable",attrs:{"icon":['far', 'times'],"size":"3x"},on:{"click":function($event){return _vm.deselectItem(item)}}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }