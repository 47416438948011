import api from '../../../mixins/api'

const GAMES = {
    NONE: 'NONE',
    GENERATOR: 'GENERATOR',
    HAYSTACK: 'HAYSTACK',
    TAXONOMY: 'TAXONOMY',
}
const getInitialState = () => {
    return {
        game: GAMES.NONE,
        collectionNames: [],
        haystackItemPIs: [],
        taxonomyItemPIs: [],
    }
}
export default {
    namespaced: true,
    state: getInitialState(),
    mutations: {
        resetState(state) {
            state.game = GAMES.NONE
            // Object.assign(state, getInitialState())
        },
        setGame(state, game) {
            state.game = game
        },
        setCollectionNames(state, collectionNames) {
            state.collectionNames = collectionNames
        },
        setHaystackItemPIs(state, PIs) {
            state.haystackItemPIs = PIs
        },
        setTaxonomyItemPIs(state, PIs) {
            state.taxonomyItemPIs = PIs
        },
    },
    actions: {
        resetState({ commit }) {
            commit('resetState')
        },
        init({ commit }) {
            api.twoavy.getCupboardsCollectionNames().then((response) => {
                commit(
                    'setCollectionNames',
                    response.data.data.map((item) => item.payload.name),
                )
            })
            api.twoavy
                .getCollectableItems('cupboardsHaystack')
                .then((response) => {
                    commit('setHaystackItemPIs', response.data)
                })
            api.twoavy
                .getCollectableItems('cupboardsImpossible')
                .then((response) => {
                    commit('setTaxonomyItemPIs', response.data)
                })
        },
        setGame({ commit }, game) {
            commit('setGame', game)
        },
        setPositions({ commit }, positions) {
            commit('setPositions', positions)
        },
        getCollectionNames({ commit }) {
            api.twoavy.getCupboardsCollectionNames().then((response) => {
                commit(
                    'setCollectionNames',
                    response.data.data.map((item) => item.payload.name),
                )
            })
        },
    },
    getters: {
        getPosition: (state) => (index) => {
            return state.positions[index]
        },
        getCollectionNames: (state) => {
            return state.collectionNames
        },
        getHaystackItemPIs: (state) => {
            // console.log(state.haystackItemPIs)
            return state.haystackItemPIs
        },
        getTaxonomyItemPIs: (state) => {
            return state.taxonomyItemPIs
        },
    },
}
export { GAMES }
