import { nestedSet } from '@twoavy/twoavy-nested-set'
import localforage from 'localforage'
import api, { fetchAsBlob, FIELDS } from '../../../mixins/api'
import * as Promise from 'bluebird'
import { GAMES } from './cupboards'
import { shuffle, uniqBy, uniq, debounce } from 'lodash'
import { stationsData } from '../../../stationsData'

const SELECTRESULTS = {
    NONE: 'NONE',
    SUCCESS: 'SUCCESS',
    MISTAKE: 'MISTAKE',
}
const STEPS = {
    IDLE: 'IDLE',
    INTRODUCTION: 'INTRODUCTION',
    FIND: 'FIND',
    RESULT: 'RESULT',
    GAMEOVER: 'GAMEOVER',
}
const GAMEOVERTIMEOUTDURATION = 30000
const RESULTTIMEOUTDURATION = 5000
const NUMBEROFTRIES = 3
const MAXNUMBEROFITEMS = 400

let gameoverTimeoutId

const getKenomItem = (kenomData) => {
    // TODO: checkout xpath or other query libraries for xml/json
    const kenomItem = {}
    const record = kenomData.elements
        .find((element) => element.name === 'OAI-PMH')
        .elements.find((element) => element.name === 'GetRecord')
    kenomItem.id = record.elements[0].elements
        .find((element) => element.name === 'header')
        .elements.find(
            (element) => element.name === 'identifier',
        ).elements[0].text

    const metaData = record.elements[0].elements.find(
        (element) => element.name === 'metadata',
    )
    const descriptiveMetaData = metaData.elements[0].elements.find(
        (element) => element.name === 'lido:descriptiveMetadata',
    )
    descriptiveMetaData.elements
        .find((element) => element.name === 'lido:objectClassificationWrap')
        .elements.find((element) => element.name === 'lido:classificationWrap')
        .elements.filter((element) => element.name === 'lido:classification')
        .forEach((element) => {
            const key = element.attributes['lido:type']
            const value = element.elements[0].elements[0].text
            kenomItem[key] = value
        })

    const measurementSets = descriptiveMetaData.elements
        .find((element) => element.name === 'lido:objectIdentificationWrap')
        .elements.find(
            (element) => element.name === 'lido:objectMeasurementsWrap',
        )
        .elements[0].elements.find(
            (element) => element.name === 'lido:objectMeasurements',
        ).elements

    measurementSets.forEach((measurementSet) => {
        const key = measurementSet.elements.find(
            (element) =>
                element.name === 'lido:measurementType' &&
                Object.keys(element).includes('attributes') &&
                element.attributes['xml:lang'] === 'en',
        ).elements[0].text
        const value = measurementSet.elements.find(
            (element) => element.name === 'lido:measurementValue',
        ).elements[0].text
        kenomItem[key] = value
    })

    //category
    kenomItem.category = metaData.elements[0].elements
        .find((element) => element.name === 'lido:category')
        .elements.find(
            (element) => element.name === 'lido:term',
        ).elements[0].text

    const eventSets = descriptiveMetaData.elements
        .find((element) => element.name === 'lido:eventWrap')
        .elements.filter((element) => element.name === 'lido:eventSet')

    eventSets.forEach((eventSet) => {
        const events = eventSet.elements.filter(
            (event) => event.name === 'lido:event',
        )

        events.forEach((event) => {
            // event type
            const eventType = event.elements
                .find((element) => element.name === 'lido:eventType')
                .elements.find(
                    (element) =>
                        element.name === 'lido:term' &&
                        Object.keys(element).includes('attributes') &&
                        element.attributes['xml:lang'] === 'en',
                ).elements[0].text

            // event date
            if (eventType === 'Production') {
                const eventDate = event.elements
                    .find((element) => element.name === 'lido:eventDate')
                    .elements.find((element) => element.name === 'lido:date')
                const earliestDate = eventDate.elements
                    .find((element) => element.name === 'lido:earliestDate')
                    .elements[0].text.split('-')[0]
                const latestDate = eventDate.elements
                    .find((element) => element.name === 'lido:latestDate')
                    .elements[0].text.split('-')[0]

                // event place
                // const eventPlace = event.elements
                //     .find(element => element.name === 'lido:eventPlace')
                //     .elements.find(
                //         element =>
                //             element.name === 'lido:place' &&
                //             Object.keys(element).includes('attributes') &&
                //             element.attributes['lido:politicalEntity'] ===
                //                 'minting_authority_country_place',
                //     )
                //     .elements.find(
                //         element => element.name === 'lido:namePlaceSet',
                //     )
                //     .elements.find(
                //         element => element.name === 'lido:appellationValue',
                //     ).elements[0].text

                kenomItem.earliestDate = earliestDate
                kenomItem.latestDate = latestDate
                kenomItem.meanDate = Math.round(
                    (parseInt(earliestDate) + parseInt(latestDate)) / 2,
                )
                // kenomItem.place = eventPlace
            } else if (eventType === 'Commissioning') {
                // commissioner
                const eventActor = event.elements.find(
                    (element) => element.name === 'lido:eventActor',
                )
                if (eventActor) {
                    kenomItem.commissioner = eventActor.elements
                        .find((element) => element.name === 'lido:actorInRole')
                        .elements.find(
                            (element) => element.name === 'lido:actor',
                        )
                        .elements.find(
                            (element) => element.name === 'lido:nameActorSet',
                        )
                        .elements.find(
                            (element) =>
                                element.name === 'lido:appellationValue',
                        ).elements[0].text
                }
            }
        })
    })

    return kenomItem
}

let resultTimeoutId = null

const stayAtUnit = async (rootGetters) => {
    const unit = 'cupboards'
    const userUuid = rootGetters['getUuid'](unit)
    await api.twoavy.stayAtUnit(userUuid, unit)
}
const debouncedStayAtUnit = debounce(stayAtUnit, 5000, {
    maxWait: 10000,
    leading: true,
})

const getInitialState = () => {
    return {
        collectableItemPIs: [],
        items: [],
        filteredItems: [],
        item: null,
        featuredItem: null,
        lives: NUMBEROFTRIES,
        selectedItem: null,
        selectResult: SELECTRESULTS.NONE,
        step: STEPS.IDLE,
        // positions: [],

        // filters
        //materialIndex
        materialIndex: null,
        materialIndices: [],

        //weight
        weight: { min: -1, max: -1 },
        minWeight: -1,
        maxWeight: -1,

        //date
        date: { min: -1, max: -1 },
        minDate: -1,
        maxDate: -1,

        //diameter
        diameter: { min: -1, max: -1 },
        minDiameter: -1,
        maxDiameter: -1,

        //nominal
        nominals: [],
        nominal: null,

        //authenticities
        authenticities: [],
        authenticity: null,

        //person
        persons: [],
        person: null,

        mappings: {},
    }
}
const uniqueFilter = (value, index, self) => {
    return self.indexOf(value) === index
}
const validFilter = (value) => {
    return value != null
}

const getFilteredItems = (state) => {
    const materialIndexFilter = (value) => {
        const materialIndex = state.materialIndex
        if (!materialIndex || materialIndex == '') return true
        const indices = value.goettItem['MD_UNIGOE_MATERIAL_INDEX']
        let includes = false
        if (indices) {
            includes = indices.includes(materialIndex)
        }
        return includes
    }

    const dateFilter = (value) => {
        const date = value.kenomItem.meanDate
        const { min, max } = state.date
        return min <= date && date <= max
    }

    const weightFilter = (value) => {
        const weight = value.kenomItem.weight //getWeightInG(value.goettItem)
        if (weight < 0) {
            return false
        }
        const { min, max } = state.weight
        return min <= weight && weight <= max
    }
    const diameterFilter = (value) => {
        const diameter = value.kenomItem.diameter //getDiameterInMm(value.goettItem)
        if (diameter < 0) {
            return false
        }
        const { min, max } = state.diameter
        return min <= diameter && diameter <= max
    }
    const authenticityFilter = (value) => {
        if (!state.authenticity) {
            return true
        }
        return value.kenomItem.authenticity === state.authenticity
    }
    const nominalFilter = (value) => {
        if (!state.nominal) {
            return true
        }
        return value.kenomItem.nominal === state.nominal
    }
    const personFilter = (value) => {
        if (!state.person) {
            return true
        }
        return value.goettItem[FIELDS.MD_UNIGOE_PERSONEN].includes(state.person)
    }

    const items = [...state.items]
    return items
        .filter(materialIndexFilter)
        .filter(dateFilter)
        .filter(weightFilter)
        .filter(diameterFilter)
        .filter(nominalFilter)
        .filter(authenticityFilter)
        .filter(personFilter)
}

export default {
    namespaced: true,
    state: getInitialState(),
    mutations: {
        resetState(state) {
            const items = [...state.items]
            const filteredItems = [...state.items]
            const materialIndices = [...state.materialIndices]
            const nominals = [...state.nominals]
            const persons = [...state.persons]
            const authenticities = [...state.authenticities]
            const mappings = { ...state.mappings }
            const {
                minWeight,
                maxWeight,
                minDate,
                maxDate,
                minDiameter,
                maxDiameter,
            } = state
            Object.assign(state, getInitialState())
            state.items = items
            state.filteredItems = filteredItems
            state.materialIndices = materialIndices
            state.nominals = nominals
            state.persons = persons
            state.authenticities = authenticities
            state.minWeight = minWeight
            state.maxWeight = maxWeight
            state.minDate = minDate
            state.maxDate = maxDate
            state.minDiameter = minDiameter
            state.maxDiameter = maxDiameter

            state.date = { min: minDate, max: maxDate }
            state.weight = { min: minWeight, max: maxWeight }
            state.diameter = { min: minDiameter, max: maxDiameter }
            state.mappings = mappings
        },
        setItems(state, items) {
            const collectableItems = items.filter((item) =>
                state.collectableItemPIs.includes(item.goettItem.PI),
            )
            items = uniqBy(
                [
                    ...shuffle(items).splice(0, MAXNUMBEROFITEMS),
                    ...collectableItems,
                ],
                (item) => item.goettItem.PI,
            )

            state.items = items
            state.filteredItems = items
            let minWeight = null
            let maxWeight = null
            let minDiameter = null
            let maxDiameter = null
            let minDate = null
            let maxDate = null
            let materialIndices = []
            let nominals = [...state.nominals]
            let authenticities = [...state.authenticities]
            let persons = []
            items.forEach((item) => {
                const { goettItem, kenomItem } = item
                const { diameter, weight, meanDate } = kenomItem
                nominals.push(kenomItem?.nominal)
                authenticities.push(kenomItem?.authenticity)
                persons.push(goettItem[FIELDS.MD_UNIGOE_PERSONEN][0])

                if (!minDiameter || diameter < minDiameter) {
                    minDiameter = Math.floor(diameter)
                } else if (!maxDiameter || diameter > maxDiameter) {
                    maxDiameter = Math.ceil(diameter)
                }

                if (!minWeight || weight < minWeight) {
                    minWeight = Math.floor(weight)
                } else if (!maxWeight || weight > maxWeight) {
                    maxWeight = Math.ceil(weight)
                }

                if (!minDate || meanDate < minDate) {
                    minDate = meanDate
                } else if (!maxDate || meanDate > maxDate) {
                    maxDate = meanDate
                }

                goettItem.MD_UNIGOE_MATERIAL_INDEX?.forEach((materialIndex) => {
                    materialIndices.push(materialIndex)
                })
            })
            //weight
            state.minWeight = minWeight
            state.maxWeight = maxWeight
            state.weight = {
                min: minWeight,
                max: maxWeight,
            }
            //diameter
            state.minDiameter = minDiameter
            state.maxDiameter = maxDiameter
            state.diameter = {
                min: minDiameter,
                max: maxDiameter,
            }

            //date
            state.minDate = minDate
            state.maxDate = maxDate
            state.date = { min: minDate, max: maxDate }

            //materialIndex
            state.materialIndex = null
            state.materialIndices = materialIndices.filter(uniqueFilter)

            //kenom
            state.nominals = nominals.filter(uniqueFilter).filter(validFilter)
            state.authenticities = authenticities
                .filter(uniqueFilter)
                .filter(validFilter)

            state.persons = uniq(persons).filter(
                (person) => !person.startsWith('Anonym'),
            )
        },

        async setItem(state, item) {
            // if (Object.keys(state.mappings).includes(item.goettItem.PI)) {
            //     // if(item.goettItem.PI)
            //     console.log('map item to target PI')
            // }
            state.item = {
                ...item,
                kenomItem: getKenomItem(await api.kenom.get(item.goettItem.PI)),
            }
            state.lives = 3
            state.step = STEPS.INTRODUCTION
        },
        setFeaturedItem(state, featuredItem) {
            state.featuredItem = featuredItem
        },
        setRelatedItems(state, relatedItems) {
            state.relatedItems = relatedItems
        },
        setMaterialIndices(state, materialIndices) {
            state.materialIndices = materialIndices
        },
        selectItem(state, item) {
            state.selectedItem = item
            if (item.goettItem.PI === state.item.goettItem.PI) {
                state.selectResult = SELECTRESULTS.SUCCESS
                // state.step = STEPS.RESULT
                state.step = STEPS.GAMEOVER
            } else {
                state.lives = state.lives - 1
                state.selectResult = SELECTRESULTS.MISTAKE
                if (state.lives > 0) {
                    state.step = STEPS.RESULT
                    if (resultTimeoutId) {
                        clearTimeout(resultTimeoutId)
                    }
                    resultTimeoutId = setTimeout(() => {
                        if (state.step === STEPS.RESULT) {
                            state.step = STEPS.FIND
                            state.selectResult = SELECTRESULTS.NONE
                        }
                    }, RESULTTIMEOUTDURATION)
                } else {
                    state.step = STEPS.GAMEOVER
                }
            }
        },
        setNextStep(state) {
            switch (state.step) {
                case STEPS.INTRODUCTION: {
                    state.step = STEPS.FIND
                    return
                }
                case STEPS.RESULT: {
                    if (state.lives > 0) {
                        state.step = STEPS.FIND
                    } else {
                        state.step = STEPS.GAMEOVER
                    }
                    return
                }
            }
        },
        setMaterialIndex(state, materialIndex) {
            state.materialIndex = materialIndex
        },
        setDate(state, { min, max }) {
            state.date = { min, max }
        },
        setWeight(state, { min, max }) {
            state.weight = { min, max }
        },
        setDiameter(state, { min, max }) {
            state.diameter = { min, max }
        },
        setNominal(state, nominal) {
            state.nominal = nominal
        },
        setPerson(state, person) {
            state.person = person
        },
        setAuthenticity(state, authenticity) {
            state.authenticity = authenticity
        },
        setFilteredItems(state, filteredItems) {
            state.filteredItems = filteredItems
        },
        resetFilters(state) {
            state.filteredItems = [...state.items]
            state.materialIndex = null
            state.nominal = null
            state.authenticity = null
            state.weight = { min: state.minWeight, max: state.maxWeight }
            state.diameter = { min: state.minDiameter, max: state.maxDiameter }
            state.date = { min: state.minDate, max: state.maxDate }
        },
        setCollectableItemPIs(state, PIs) {
            state.collectableItemPIs = [...PIs]
        },
        setMappings(state, { mappings }) {
            // console.log('set mappings mutation', mappings)
            state.mappings = mappings
        },
    },
    actions: {
        resetState({ commit }) {
            commit('resetState')
        },
        async setItem({ commit, state }, item) {
            const itemPI = item.goettItem.PI
            const sourcePIs = state.mappings ? Object.keys(state.mappings) : []
            // console.log('todo replace item', sourcePIs, itemPI)
            if (sourcePIs.includes(itemPI)) {
                const index = Math.floor(
                    Math.random() * state.mappings[itemPI].targets.length,
                )
                const newItemPI = state.mappings[itemPI].targets[index]
                const newItem = (await api.goettingen.getItem(newItemPI)).data
                    .docs[0]
                item = { goettItem: newItem }
            }
            commit('setItem', item)
        },
        setFeaturedItem({ commit }, featuredItem) {
            commit('setFeaturedItem', featuredItem)
        },
        setRelatedItems({ commit }, relatedItems) {
            commit('setRelatedItems', relatedItems)
        },
        setMaterialIndices({ commit }, materialIndices) {
            commit('setMaterialIndices', materialIndices)
        },
        selectItem({ state, commit, dispatch }, item) {
            commit('selectItem', item)
            if (state.step === STEPS.GAMEOVER) {
                clearTimeout(gameoverTimeoutId)
                gameoverTimeoutId = setTimeout(() => {
                    if (state.step === STEPS.GAMEOVER) {
                        dispatch('cupboards/setGame', GAMES.NONE, {
                            root: true,
                        })
                    }
                }, GAMEOVERTIMEOUTDURATION)
            }
        },
        setNextStep({ commit }, item) {
            commit('setNextStep', item)
        },
        async init({ commit }) {
            const collectableItemPIs = (
                await api.twoavy.getCollectableItems('cupboardsHaystack')
            ).data
            const cupboardItems = (
                await api.twoavy.getContent(
                    stationsData.find((station) => station.key === 'cupboards')
                        .sectionId,
                )
            ).data.data
            const cupboardsHaystackSources = cupboardItems.filter(
                (item) => item.templateId === 35,
            )
            const cupboardsHaystackTargets = cupboardItems.filter(
                (item) => item.templateId === 36,
            )
            const mappings = {}
            cupboardsHaystackSources.forEach((source) => {
                mappings[source?.content?.source_pi?.global] = {
                    id: source?.id,
                    targets: [],
                }
            })
            cupboardsHaystackTargets.forEach((target) => {
                const targetPI = target?.content?.target_pi?.global
                const targetParentId = target?.parentId
                Object.entries(mappings).forEach((entry) => {
                    if (entry[1].id === targetParentId) {
                        mappings[entry[0]].targets.push(targetPI)
                    }
                })
                // nestedSet.childrenById(source, )
            })
            commit('setMappings', { mappings })
            commit('setCollectableItemPIs', collectableItemPIs)

            try {
                localforage.getItem('haystackItems').then((value) => {
                    if (value) {
                        commit('setItems', value)
                    }
                })
            } catch (err) {
                console.log(err)
            }

            api.goettingen.getCoins().then((response) => {
                const items = response?.data?.docs.map((coin) => {
                    return { goettItem: coin }
                })

                Promise.map(
                    items,
                    (item) => {
                        return api.kenom.get(item.goettItem.PI)
                    },
                    { concurrency: 4 },
                )
                    .then((response) => {
                        response.forEach((item) => {
                            const kenomItem = getKenomItem(item)
                            const index = items.findIndex(
                                (item) => item.goettItem.PI === kenomItem.id,
                            )
                            items[index].kenomItem = kenomItem
                        })
                        commit('setItems', items)
                        localforage
                            .setItem('haystackItems', items)
                            .then(function () {
                                return localforage.getItem('haystackItems')
                            })
                            .then(function (value) {
                                console.log(
                                    'successfully updated localstorage haystack items',
                                    value,
                                )
                            })
                            .catch(function (err) {
                                console.error(
                                    'could not update localstorage haystack items',
                                    err,
                                )
                            })
                    })
                    .catch((error) => {
                        console.log('could not get kenom data', error)
                    })

                Promise.map(
                    items,
                    (item) => {
                        return fetchAsBlob(
                            api.goettingen.getIsolatedThumbnailUrl(
                                item.goettItem,
                                100,
                            ),
                        )
                    },
                    { concurrency: 4 },
                )
                    .then((response) => {
                        const blobs = {}
                        response.forEach((blob, index) => {
                            const item = items[index]
                            blobs[item.goettItem.PI] = blob
                        })
                        localforage.setItem('isolatedBlobs', blobs)
                    })
                    .catch((error) => {
                        console.log('could not get isolated blobs', error)
                    })
            })
        },
        setMaterialIndex({ commit, state, rootGetters }, materialIndex) {
            debouncedStayAtUnit(rootGetters)
            commit('setMaterialIndex', materialIndex)
            commit('setFilteredItems', getFilteredItems(state))
        },
        setDate({ commit, state, rootGetters }, { min, max }) {
            debouncedStayAtUnit(rootGetters)
            commit('setDate', { min, max })
            commit('setFilteredItems', getFilteredItems(state))
        },
        setWeight({ commit, state, rootGetters }, { min, max }) {
            debouncedStayAtUnit(rootGetters)
            commit('setWeight', { min, max })
            commit('setFilteredItems', getFilteredItems(state))
        },
        setDiameter({ commit, state, rootGetters }, { min, max }) {
            debouncedStayAtUnit(rootGetters)
            commit('setDiameter', { min, max })
            commit('setFilteredItems', getFilteredItems(state))
        },
        setNominal({ commit, state, rootGetters }, nominal) {
            debouncedStayAtUnit(rootGetters)
            commit('setNominal', nominal)
            commit('setFilteredItems', getFilteredItems(state))
        },
        setPerson({ commit, state, rootGetters }, person) {
            debouncedStayAtUnit(rootGetters)
            commit('setPerson', person)
            commit('setFilteredItems', getFilteredItems(state))
        },
        setAuthenticity({ commit, state, rootGetters }, authenticity) {
            debouncedStayAtUnit(rootGetters)
            commit('setAuthenticity', authenticity)
            commit('setFilteredItems', getFilteredItems(state))
        },
        resetFilters({ commit }) {
            commit('resetFilters')
        },
    },
    getters: {
        getStep: (state) => {
            return state.step
        },
        isStep: (state) => (step) => {
            return state.step === step
        },
        getMaterialIndex: (state) => state.materialIndex,
        getDate: (state) => state.date,
        getDiameter: (state) => state.diameter,
        getWeight: (state) => state.weight,
        getNominal: (state) => state.nominal,
        getPerson: (state) => state.person,
        getAuthenticity: (state) => state.authenticity,
        isItemInFilteredItems: (state) => (item) => {
            return (
                state.filteredItems.find(
                    (i) => i.goettItem?.PI === item?.goettItem?.PI,
                ) != null
            )
        },
        isSelectedItemInFilteredItems: (state) => {
            return (
                state.filteredItems.find(
                    (i) => i.goettItem?.PI === state.item?.goettItem?.PI,
                ) != null
            )
        },
        areFiltersDirty: (state) => {
            return (
                state.materialIndex ||
                state.nominal ||
                state.authenticity ||
                state.minDate !== state.date.min ||
                state.maxDate !== state.date.max ||
                state.minWeight !== state.weight.min ||
                state.maxWeight !== state.weight.max ||
                state.minDiameter !== state.diameter.min ||
                state.maxDiameter !== state.diameter.max
            )
        },
        getRemainingTries: (state) => {
            return state.lives
        },
        didWin: (state) =>
            state.step === STEPS.GAMEOVER &&
            state.selectResult === SELECTRESULTS.SUCCESS,
        getItem: (state) => state.item,
        getFeaturedItem: (state) => state.featuredItem,
    },
}
export { SELECTRESULTS, STEPS, MAXNUMBEROFITEMS, NUMBEROFTRIES }
